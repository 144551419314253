import React, { Component } from 'react';
import GraphAttendance from './GraphAttendance';
import AdminContext from '../context/AdminContext';

export default class GraphAttendanceContainer extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <div className="Reports">
                    
                        <div className="chat-heading-am">
                        Active attendee users
                        </div>
                        <br />
                        <GraphAttendance />
                    </div>
                </div>
           )
    }
}

GraphAttendanceContainer.contextType = AdminContext;
