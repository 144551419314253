import React, { Component, Fragment } from 'react';
import axios from 'axios';
import './chat.css';
import { Row, Col } from 'reactstrap';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import SignalRController from '../SignalRController.js';
import CommonRequestLink from './CommonRequestLink';
import AdminContext from '../context/AdminContext';


export class Requests extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            requestData: [],
            showLinkModal: false,
            selectedContest: 0,
            linkAddress: '',
            linkerror: null,
            lastDataCallTime: new Date().getTime(),
            showCompleted: false
        };
        this.populatedTimeout = null;
    }

    async componentDidMount() {
        SignalRController.addCallbackAdm2Adm_MessageUpdate(this.refreshData);
        this.populateData();
    }

    VmeetingId = () => {
        return parseInt(sessionStorage.getItem('VMId'));
    }

    UserId = () => {
        return parseInt(sessionStorage.getItem('UserId'));
    }

    componentWillUnmount() {
        SignalRController.removeCallbackAdm2Adm_MessageUpdate(this.refreshData);
        clearTimeout(this.populatedTimeout);
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    populateData = async () => {
        let timeStamp = new Date().getTime();
        let request = {
            VMId: this.VmeetingId(),
            UserID: this.UserId()
        }
        await axios.post("api/VMGetMeetingQuestionList", request).then(response => {
            if (response.data.length > 0) {
                this.setState({
                    dataFound: true,
                    requestData: response.data,
                    loading: false,
                    lastDataCallTime: timeStamp
                })
            } else {
                this.setState({
                    dataFound: false,
                    loading: false
                })
            }

        })

    }

    refreshData = () => {
        let timeStamp = new Date().getTime();
        if (timeStamp - this.state.lastDataCallTime > 7500) {
            clearTimeout(this.populatedTimeout);
            this.populateData();
        } else {
            clearTimeout(this.populatedTimeout);
            this.populatedTimeout = setTimeout(() => {
                this.populateData()
            }, 10000)
        }
    }

    updateCheckbox = async (event, currentvalue, id, attendeeId, questionType, checkbox, messageId) => {
        let target = event.target.name;
        this.setState({
            [target]: true
        })
        //universal checkbox update function
        let request = {
            VmeetingId: this.VmeetingId(),
            UserId: this.UserId(),
            RequestId: id,
            MessageId: messageId,
            CheckboxSelector: checkbox,
            Value: !currentvalue
        }
        await axios.post("api/VMUpdateMeetingQuestionCheckbox", request).then(response => {
            if (response.data.length > 0) {
                this.setState({
                    dataFound: true,
                    requestData: response.data
                })
                setTimeout(() => {
                    this.setState({
                        [target]: false
                    })
                }, 1000)
            } else {
                this.setState({
                    dataFound: false
                })
                setTimeout(() => {
                    this.setState({
                        [target]: false
                    })
                }, 1000)
            }

        })
        if (checkbox === 1 && !currentvalue) {
            //send auto message
            this.sendMessage(questionType, attendeeId);
        }

    }

    sendMessage = async (questionType, attendeeId) => {
        this.setState({
            disableSend: true
        })

        //var comment = "Your " + questionType + " has been acknowledged"
        let comment = questionType + ": Your request has been received and is being reviewed. Thank you.";


        try {
            let chatResponse = {
                AdminId: 1,
                VMeetingId: this.VmeetingId(),
                QuestionText: comment,
                SubmittedById: attendeeId,
                SubmittedByName: 'notused',
                messageDirection: 99,
                ReadFlag: true
            };

            await axios.post(`api/SubmitAdminMessage`, chatResponse).then(res => {})
        }

        catch (err) {
            console.log("Error: Saving chat", err.message);
        }
        setTimeout(() => {
            this.setState({
                disableSend: false
            })
        }, 250)
    }


    handleShow = (requestid, linkAddress) => {
        this.setState({
            showLinkModal: true,
            selectedContest: requestid,
            linkAddress: linkAddress,
            linkerror: ''
        });
    }
    handleClose = () => this.setState({ showLinkModal: false });

    handleSave = async (event) => {
        if (!this.props.isAuthorized) {
            return;
        }
        event.preventDefault();
        let target = event.target.name;
        this.setState({
            [target]: true,
            linkerror: null
        })
        let url = new URL(this.state.linkAddress);
        if (url.pathname.split('/').pop().indexOf('.') > 0) {
            this.setState({
                linkerror: "Please enter a page url not a file download"
            })
            return;
        }

        let request = {
            VmeetingId: this.VmeetingId(),
            UserId: this.UserId(),
            RequestId: this.state.selectedContest,
            LinkAddress: this.state.linkAddress ? this.state.linkAddress : ''
        }

        await axios.post("api/VMUpdateMeetingQuestionLinkAddress", request).then(response => {
            if (response.data.length > 0) {
                this.setState({
                    dataFound: true,
                    requestData: response.data
                })
                setTimeout(() => {
                    this.setState({
                        [target]: false,
                        showLinkModal: false
                    })
                }, 1000)
            } else {
                this.setState({
                    dataFound: false
                })
                setTimeout(() => {
                    this.setState({
                        [target]: false,
                        showLinkModal: false
                    })
                }, 1000)
            }

        }).catch(error => {
            if (error.response.status === 406) {
                this.setState({
                    [target]: false,
                    linkerror: "Invalid URL"
                })
            }
        })
    }



    render() {
        if (this.state.loading) {
            return (
                <div className="chatloaderholder"><div className="loader" /></div>
            )
        } else if (!this.state.dataFound) {
            return (
                <Fragment>
                    <div className="chat-heading justify-content-between">
                        <div>REQUESTS</div>
                        <div><CommonRequestLink isAuthorized={this.props.isAuthorized} /></div>
                    </div>
                    <div className="mx-3">
                        <p> No requests found </p>
                    </div>
                </Fragment>
            )
        }
        let requestData;
        if (this.state.showCompleted) {
            requestData = this.state.requestData
        } else {
            requestData = this.state.requestData.filter(request => request.complete !== true)
        }
        return (
            <Fragment>
                <div className="chat-heading justify-content-between">
                    <div>REQUESTS</div>
                    <div><CommonRequestLink isAuthorized={this.props.isAuthorized} /></div>
                </div>
                <div className="SeenAndHeard">
                    <div >
                        <Row className="sticky-top border mb-1 mx-0">
                            <Col className="tableHeading">Request</Col>
                            {!this.context.switches.enableCommonLink &&
                                <Col xs="1" className="tableHeading">Link</Col>}
                            <Col xs="1" className="tableHeading">Received</Col>
                            <Col xs="1" className="tableHeading">Ready</Col>
                            <Col xs="1" className="tableHeading">Complete
                                <button className="btn btn-sm btn-primary" title="toggle show-hide of completed requests" onClick={() => this.setState({ showCompleted: !this.state.showCompleted })}>
                                    {this.state.showCompleted ? "Hide" : "Show"}
                                </button>
                            </Col>
                        </Row>
                        {this.state.requestData && requestData.map((request, index) => {
                            let inbox = this.props.inbox;
                            let chat = { attendeeId: request.attendeeId }
                            let unread = this.props.unreadCount ? this.props.unreadCount.find(row => row.user === request.attendeeId) ? (this.props.unreadCount.find(row => row.user === request.attendeeId)).unread : 0 : 0;
                            return (
                                <Row key={request.id} className="border rounded mb-1 mx-0">
                                    <Col tabIndex="0" className="text-start pointer" onClick={this.props.respond ? () => this.props.respond(chat, inbox, request.messageId, request.linkAddress, true, this.props.type) : null}>
                                        <div className="row pt-1">
                                            <div className="col text-start request-name"><strong>From: {request.userName}</strong></div>
                                            <div className="col-auto request-type">{request.questionType}</div>
                                        </div>
                                        <div className="d-flex align-items-center ">
                                        {request.questionText}
                                        {(request.newMessageFlag) && <span className="unreadcnt">New request</span>}
                                            {(unread && unread > 0) ? <span className="unreadcnt">{unread} unread</span> : ''}
                                        </div>
                                    </Col>
                                    {!this.context.switches.enableCommonLink &&
                                        <Col xs="1" className="d-flex align-items-center justify-content-center">
                                            {request.questionTypeId === 2 &&
                                                <button disabled={!this.props.isAuthorized} className="btn btn-primary btn-block" title={request.linkAddress ? request.linkAddress : 'Set link address'} onClick={() => this.handleShow(request.id, request.linkAddress)}>Link</button>}
                                            {request.questionTypeId === 2 && (request.linkAddress ? <FontAwesomeIcon icon={faCheckCircle} color="green" title={request.linkAddress} /> : <FontAwesomeIcon icon={faTimesCircle} title="Link not set" color="red" />)}
                                        </Col>
                                    }
                                    <Col xs="1" className="d-flex align-items-center justify-content-center">
                                        {
                                            !this.state["acknowledged" + request.id] ?
                                                <input type="checkbox" style={{ height: '20px', width: '20px' }}
                                                    name={"acknowledged" + request.id} checked={request.acknowledged}
                                                    disabled={!this.props.isAuthorized || request.acknowledged} onChange={(e) => this.updateCheckbox(e, request.acknowledged, request.id, request.attendeeId, request.questionType, 1, request.messageId)} />
                                                : <div className="loaderholder"><div className="loader" /></div>} </Col>
                                    <Col xs="1" className="d-flex align-items-center justify-content-center">
                                        {
                                            !this.state["ready" + request.id] ?
                                                <input type="checkbox" style={{ height: '20px', width: '20px' }} name={"ready" + request.id}
                                                    checked={request.ready}
                                                    disabled={!this.props.isAuthorized}
                                                    onChange={(e) => this.updateCheckbox(e, request.ready, request.id, request.attendeeId, request.questionTypeId, 2, request.messageId)} />
                                                : <div className="loaderholder"><div className="loader" /></div>} </Col>
                                    <Col xs="1" className="d-flex align-items-center justify-content-center">
                                        {
                                            !this.state["complete" + request.id] ?
                                                <input type="checkbox" style={{ height: '20px', width: '20px' }}
                                                    name={"complete" + request.id} checked={request.complete}
                                                    disabled={!this.props.isAuthorized}
                                                    onChange={(e) => this.updateCheckbox(e, request.complete, request.id, request.attendeeId, request.questionTypeId, 3, request.messageId)} />
                                                : <div className="loaderholder"><div className="loader" /></div>} </Col>
                                </Row>
                            )
                        })}
                    </div>
                </div>


                <Modal show={this.state.showLinkModal} onHide={this.handleClose}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <form onSubmit={(e) => this.handleSave(e)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Update Link</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="m-1">
                                <div className="mx-1 mb-1">Include "https://" in the url</div>
                                <div className="form-group">
                                    <label className="sr-only" htmlFor="linkvalue">Link</label>
                                    <input type="url"
                                        pattern="https://.*"
                                        id="linkvalue"
                                        name="linkvalue"
                                        className="linkvalue form-control"
                                        value={this.state.linkAddress}
                                        onChange={(e) => this.setState({ linkAddress: e.target.value })}
                                        placeholder="Enter speak live link here"
                                        disabled={this.state.savelink}
                                        required />
                                    {this.state.linkerror && <div className="alert alert-danger my-1" role="alert">{this.state.linkerror}</div>}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            {!this.state.savelink ?
                                <button disabled={!this.props.isAuthorized}  className="btn btn-primary" name="savelink" type="submit">Update</button> : <div className="loaderholder"><div className="loader" /></div>}
                        </Modal.Footer>
                    </form>
                </Modal>
            </Fragment>
        );
    }
}

Requests.contextType = AdminContext;