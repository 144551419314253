import React, { useState, useEffect, useContext, Fragment } from 'react';
import { sortableHandle } from 'react-sortable-hoc';
import { faGripLines } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FloatingLabel, Row, Col } from 'react-bootstrap';
import ContestContext from '../Context/ContestContext';

const DragHandle = sortableHandle(() => <Col md="1"><span className="dragIcon h-100 d-flex align-items-center"><FontAwesomeIcon icon={faGripLines} /></span></Col>);

export const CandidateCard = (props) => {
    const context = useContext(ContestContext);
    const [candidate, setCandidate] = useState();

    const [candidateImageData, setCandidateImageData] = useState(0);
    const [candidateImageError, setCandidateImageError] = useState();

    let disableField = (props.disabled || props.sortable);

    var timeOutId = null;

    useEffect(() => {
        //componentdidmount
        let getCandidate = context.voteOptions[props.Index];
        setCandidate(getCandidate);
    }, [])

    function handleForenameChange(e) {
        if (e.target.value.length <= 100) {
            let localCandidate = context.voteOptions[props.Index];
            localCandidate.candidateForename = e.target.value;
            //Update context
            context.UpdateCandidate(props.Index, localCandidate);
        }
    }

    function handleSurnameChange(e) {
        if (e.target.value.length <= 100) {
            let localCandidate = context.voteOptions[props.Index];
            localCandidate.candidateSurname = e.target.value;
            //Update context
            context.UpdateCandidate(props.Index, localCandidate);
        }
    }

    function handleStatementChange(e) {
        if (e.target.value.length <= 100) {
            let localCandidate = context.voteOptions[props.Index];
            localCandidate.candidateStatement = e.target.value;
            //Update context
            context.UpdateCandidate(props.Index, localCandidate);
        }
    }

    function handlePhotoChange(data) {
        let localCandidate = context.voteOptions[props.Index];
        //updated values
        localCandidate.candidatePhoto = data;
        //Update context
        context.UpdateCandidate(props.Index, localCandidate);
    }

    function handleRemoveButton(e) {
        e.preventDefault();
        context.DeleteCandidate(props.Index, candidate.voteOptionId)
    }


    function deleteCandidatePhoto(e) {
        e.preventDefault();
        setCandidateImageData({ fileTarget: null, index: null, props: null, gotBase64: null, complete: 2 });
        let localCandidate = context.voteOptions[props.Index];
        localCandidate.candidatePhoto = '';
        //Update context
        context.UpdateCandidate(props.Index, localCandidate);
    }

    function selectCandidateImage(id) {
        document.getElementById(id).click();
    }

    useEffect(() => {
        async function getBase64(file) {
            async function convertBase64(file) {
                return new Promise((resolve, reject) => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file)
                    fileReader.onload = () => {
                        resolve(fileReader.result);
                    }
                    fileReader.onerror = (error) => {
                        reject(error);
                    }
                })
            }
            let gotBase64 = await convertBase64(candidateImageData.fileTarget.files[0]);

            //handleCandidateImageData({ ...candidateImageData, gotBase64: gotBase64, complete: 1 })

            if (gotBase64.length > 350000) {
                setCandidateImageData({ fileTarget: null, index: null, props: null, gotBase64: null, complete: null });
                setCandidateImageError("File too large");
            } else {
                setCandidateImageData({ ...candidateImageData, gotBase64: gotBase64, complete: 1 })
            }

        }

        if (!!candidateImageData.fileTarget && candidateImageData.complete === 0) {
            setCandidateImageError(null);
            if (!!candidateImageData.fileTarget.files[0]) {
                var filePath = candidateImageData.fileTarget.files[0].name;
                // Allowing file type 
                var allowedExtensions =
                    /(\.jpg|\.jpeg|\.png|\.gif)$/i;

                //getBase64(candidateImageData.fileTarget.files[0]);

                if (!allowedExtensions.exec(filePath)) {
                    //alert('Invalid file type');
                    setCandidateImageData({ fileTarget: null, index: null, props: null, gotBase64: null, complete: null });
                    setCandidateImageError("File type not permitted");
                }
                else {
                    getBase64(candidateImageData.fileTarget.files[0]);
                }
            }
        }
        //complete status -> await above
        if (!!candidateImageData.gotBase64 && candidateImageData.gotBase64 !== 0 && candidateImageData.complete === 1) {
            handlePhotoChange(candidateImageData.gotBase64);
            setCandidateImageData({ fileTarget: null, index: null, props: null, gotBase64: null, complete: 2 });
        }
        //complete status -> await above
        if (candidateImageData.complete === 2) {
            setCandidateImageData({ fileTarget: null, index: null, props: null, gotBase64: null, complete: null });
        }
    });

    let contextCandidate = context.voteOptions[props.Index]

    return (
        <Row key={'cc' + props.Index} className="candidateSetup" >
            {props.sortable && <DragHandle />}
            <Col>
                <Row>
                    <Col>
                        <FloatingLabel label="Forename">
                            <input disabled={disableField} type="text" className="form-control" name={'candidateForename' + props.Index} value={contextCandidate.candidateForename} onChange={(e) => handleForenameChange(e)} placeholder="Forename" autoComplete="off" />
                        </FloatingLabel>
                    </Col>
                    <Col>
                        <FloatingLabel label="Surname">
                            <input disabled={disableField} type="text" className="form-control" name={'candidateSurname' + props.Index} value={contextCandidate.candidateSurname} onChange={(e) => handleSurnameChange(e)} placeholder="Surname" autoComplete="off" />
                        </FloatingLabel>
                    </Col>
                </Row>
                <Row>
                    {!props.disabled &&
                        <Col>
                            {props.ShowPhoto && <Fragment><input
                                disabled={disableField}
                                type="file"
                                className="form-control"
                                name={'candidatePhoto'}
                                onChange={(e) => setCandidateImageData({ fileTarget: e.target, gotBase64: 0, complete: 0 })}
                                id={'upload' + props.Index}
                                accept=".jpeg, .png, .jpg, .gif"
                                style={{ display: "none" }} />
                                <input type="button" value="Select Image" onClick={() => selectCandidateImage('upload' + props.Index)} />
                            </Fragment>
                            }
                            {candidateImageError && <span className="text-danger">{candidateImageError}</span>}
                        </Col>
                    }
                    {props.ShowPhoto && contextCandidate.candidatePhoto && <Col><div className="candImagePreview"><img className="candidatePhotoPreview" alt="Preview" src={contextCandidate.candidatePhoto} /></div><button className="deleteCandImage btn btn-danger mr-2" onClick={(e) => deleteCandidatePhoto(e)} value="Delete Image" >Remove Image</button></Col>}
                </Row>
                <Row>
                    <Col>
                        {props.ShowStatement && <FloatingLabel label="Statement">
                            <textarea disabled={disableField} className="form-control" name={'candidateStatement' + props.Index} value={contextCandidate.candidateStatement} onChange={(e) => handleStatementChange(e)} placeholder="Statement" />
                        </FloatingLabel>}
                    </Col>
                </Row>
            </Col>
            <Col md="auto">
                {context.voteOptions.length > 1 && props.Index > 0 && !props.disabled &&
                    <button className="btn btn-danger mr-2" onClick={(e) => handleRemoveButton(e)}>Delete</button>
                }
            </Col>

        </Row >
    )
}


