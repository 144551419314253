import React, { Component } from 'react';
import { LinkContainer } from "react-router-bootstrap";
import { Nav, Navbar, Collapse } from "react-bootstrap";
import { NavbarToggler } from "reactstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChartBar, faVoteYea, faFileAlt, faCogs, faUserTie, faDoorOpen, faDesktop } from '@fortawesome/free-solid-svg-icons'
import AdminContext from './context/AdminContext';

export class NavSide extends Component {
    static displayName = NavSide.name;

    constructor(props) {
        super(props);

        this.state = {
            collapsed: false
        };
    }
    componentDidMount() {
        //Populate state removed
    }

    componentWillUnmount() {
        this.setState = (state, callback) => {
            return;
        };
    }


    toggleNavbar = () => {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        let voteNotification = this.context.voteNotification;
        const { isUserHasPageAccess, isAuthorized, isGlobalAuthorized } = this.context;
        return (
            this.props.isAuthenticated &&
            <div className="sidebar overflow-auto">
                <header className="sideBar">
                    <div className="top-row navbar navbar-dark">
                        <div className="px-1"><h5 className="adminTitle">CES<span className="pinkIcon">joinIN</span> ADMIN</h5></div>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                    </div >
                    <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white mb-3">
                        <Collapse className="" in={!this.state.collapsed}>


                            <ul className="nav flex-column w-100">

                                {isUserHasPageAccess('Setup', 'Read') &&
                                    <LinkContainer to="/eventsetup">
                                        <Nav.Item>
                                            <NavLink exact className="nav-link" activeClassName="nav-link isactive" to="/eventsetup">
                                                <div className="icon greenText"><FontAwesomeIcon icon={faCogs} /></div>
                                                <div className="text">Setup</div>
                                            </NavLink>
                                        </Nav.Item>
                                    </LinkContainer>
                                    }
                                {isUserHasPageAccess('SurveyAdmin', 'Read') &&
                                    <LinkContainer to="/survey">
                                        <Nav.Item>
                                            <NavLink className="nav-link" activeClassName="nav-link isactive" to="/survey">
                                                <div className="icon greenText"><FontAwesomeIcon icon={faCogs} /></div>
                                                <div className="text">Survey Setup</div>
                                            </NavLink>
                                        </Nav.Item>
                                    </LinkContainer>
                                }

                                {
                                    isUserHasPageAccess('EventAdmin', 'Read') &&
                                    <LinkContainer to="/eventadministration">
                                        <Nav.Item>
                                            <NavLink exact className="nav-link" activeClassName="nav-link isactive" to="/eventadministration">
                                                <div className="icon greenText"><FontAwesomeIcon icon={faCogs} /></div>
                                                <div className="text">Event Administration</div>
                                            </NavLink>
                                        </Nav.Item>
                                    </LinkContainer>
                                }

                                {isUserHasPageAccess('VoteAdmin', 'Read') &&
                                    <LinkContainer to="/voteadministration">
                                        <Nav.Item>
                                            <NavLink className={voteNotification ? "nav-link highlightlink" : "nav-link"} to="/voteadministration" activeClassName="nav-link isactive">
                                                <div className="icon greenText"><FontAwesomeIcon icon={faVoteYea} /></div>
                                                <div className="text">Vote Administration
                                                </div>
                                            </NavLink>
                                        </Nav.Item>
                                    </LinkContainer>
                                }

                                {isUserHasPageAccess('Moderators', 'Read') &&
                                    <LinkContainer to="/chat">
                                        <Nav.Item>
                                            <NavLink className="nav-link" activeClassName="nav-link isactive" to="/chat">
                                                <div className="icon greenText"><FontAwesomeIcon icon={faUserTie} /></div>
                                                <div className="text">Moderators</div>
                                            </NavLink>
                                        </Nav.Item>
                                    </LinkContainer>
                                }
                                {
                                    isUserHasPageAccess('Report', 'Read') &&
                                    <LinkContainer to="/report">
                                        <Nav.Item>
                                            <NavLink className="nav-link" activeClassName="nav-link isactive" to="/report">
                                                <div className="icon greenText"><FontAwesomeIcon icon={faChartBar} /></div>
                                                <div className="text">Reports</div>
                                            </NavLink>
                                        </Nav.Item>
                                    </LinkContainer>
                                }
                                {isUserHasPageAccess('PresSummary', 'Read') &&
                                    <LinkContainer to="/summary">
                                        <Nav.Item>
                                            <NavLink className="nav-link" activeClassName="nav-link isactive" to="/summary">
                                                <div className="icon greenText"><FontAwesomeIcon icon={faFileAlt} /></div>
                                                <div className="text">Presenter Summary</div>
                                            </NavLink>
                                        </Nav.Item>
                                    </LinkContainer>
                                }
                                {isUserHasPageAccess('ComfortMonitor', 'Read') && this.context.switches.enableComfortMonitor &&
                                    <LinkContainer to="/comfort">
                                        <Nav.Item>
                                            <NavLink className="nav-link" activeClassName="nav-link isactive" to="/comfort">
                                                <div className="icon greenText"><FontAwesomeIcon icon={faDesktop} /></div>
                                                <div className="text">Comfort Monitor</div>
                                            </NavLink>
                                        </Nav.Item>
                                    </LinkContainer>
                                }
                                {isAuthorized('Registration', 'Read') &&
                                    <LinkContainer to="/registration">
                                        <Nav.Item>
                                            <NavLink className="nav-link" activeClassName="nav-link isactive" to="/registration">
                                                <div className="icon greenText"><FontAwesomeIcon icon={faDoorOpen} /></div>
                                                <div className="text">Attendee Registration</div>
                                            </NavLink>
                                        </Nav.Item>
                                    </LinkContainer>
                                }
                                {isAuthorized('SelfRegistration', 'Read') &&
                                    <LinkContainer to="/selfregistration">
                                        <Nav.Item>
                                            <NavLink className="nav-link" activeClassName="nav-link isactive" to="/selfregistration">
                                                <div className="icon greenText"><FontAwesomeIcon icon={faDoorOpen} /></div>
                                                <div className="text">Attendee Self Registration</div>
                                            </NavLink>
                                        </Nav.Item>
                                    </LinkContainer>
                                }

                                {isUserHasPageAccess('UserAdmin', 'Read') &&
                                    <LinkContainer to="/UserAdministration" >
                                        <Nav.Item >
                                            <NavLink className="nav-link" activeClassName="nav-link isactive" to="/UserAdministration">
                                                <div className="icon greenText"><FontAwesomeIcon icon={faUserTie} /></div>
                                                <div className="text">User Administration </div>
                                            </NavLink>
                                        </Nav.Item>
                                    </LinkContainer>
                                }
                                {isGlobalAuthorized('AzureCapacity', 'Read') &&
                                    <LinkContainer to="/AzureScale" >
                                        <Nav.Item >
                                            <NavLink className="nav-link" activeClassName="nav-link isactive" to="/azurescale">
                                                <div className="icon greenText"><FontAwesomeIcon icon={faUserTie} /></div>
                                                <div className="text">System Information</div>
                                            </NavLink>
                                        </Nav.Item>
                                    </LinkContainer>
                                }
                            </ul>
                        </Collapse>
                    </Navbar>
                    <div className="bottom-row navbar">

                    </div >
                </header >

            </div>
        );
    }
}

NavSide.contextType = AdminContext;