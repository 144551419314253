import React, { Component } from 'react';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import PostMeetingReport from './PostMeetingReport';
import AdminAttendanceReport from './AdminAttendanceReport';
import GraphAttendanceContainer from './graphs/GraphAttendanceContainer';
import { MeetingAttendance } from './MeetingAttendance';
import authService from './api-authorization/AuthorizeService';
import AdminContext from './context/AdminContext';
import { MembersList } from './MembersList';
import RVReport from './RVReport';

export default class Report extends Component {
    constructor(props) {
        super(props);
        this.loggedInCurrent = true;
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.state = {
            memberdata: []
            , loginsummary: []
            , totalAttendee: 0
            , totalVotingMember: 0
            , totalNonVotingMember: 0
            , totalInRoom: 0
            , totalRemote: 0
            , loading: true
            , intervalTimer: []
            , reportFinished: true
        };



        this.config = {
            page_size: 10,
            length_menu: [10, 20, 50],
            show_pagination: true,
            show_length_menu: false,
            show_filter: true,
            no_data_text: 'No records found',
            filename: "Members",
            //button: {
            //    csv: true
            //}

        }
        this.tabChange = this.tabChange.bind(this);
        this.getReport = this.getReport.bind(this);

    }

    componentDidMount() {
        this.getReport();
        this.setState({ intervalTimer: this.state.intervalTimer.concat(setInterval(() => this.getReport(), 5000)) });
    }


    componentWillUnmount() {
        this.state.intervalTimer.forEach((itimer) => clearInterval(itimer));

        this.setState = (state, callback) => {
            return;
        };
    }

    getColumns = () => {
        var columns = [
            {
                key: "memberNo",
                text: "Member No.",
                align: "Left",
                sortable: true
            },
            {
                key: "fullName",
                text: "Name",
                align: "Left",
                sortable: true
            },
            {
                key: "memberType",
                text: "Member Type",
                align: "Left",
                sortable: true
            },
            (this.context.switches.enableWeightedCount ? {
                key: "voteWeight",
                text: "Vote Weight",
                align: "center",
                sortable: true
            } : {}),
            {
                key: "attendeeSource",
                text: "Attendee Source",
                align: "Left",
                sortable: true
            },
            {
                key: "memberActivity",
                text: "Member Activity",
                align: "Left",
                sortable: true
            }]
        var returnColumns = columns.filter(value => Object.keys(value).length !== 0);
        return returnColumns;
    }


    getReport() {
        // If getting the report is slow make sure we don't call it too many times from the timer 
        if (this.state.reportFinished === true) {
            this.setState({ reportFinished: false });
            axios.get('VMGetMeetingReportBreakdown', { params: { VMId: this.VmeetingId, loggedInCurrent: true } })
                .then(response => {
                    this.setState({
                        //memberdata: response.data.memberdata.filter((mb) => mb.attendeeSource !== ""),
                        loginsummary: response.data.loginSummary,
                        totalAttendee: response.data.totalAttendee,
                        totalVotingMember: response.data.totalVotingMember,
                        totalNonVotingMember: response.data.totalNonVotingMember,
                        loading: false,
                        reportFinished: true,
                    });
                }).catch(function (error) {
                    this.setState({ reportFinished: true });
                    console.log(error);
                }
                )
        }
    }

    tabChange(e) {
        if (e === 1) {
            this.getReport();
        }
    }

    render() {
        const { loginsummary = [] } = this.state;  
        const { isAuthorized } = this.context;
        return (
            <div>
                <Tabs defaultIndex={0} onSelect={this.tabChange} >
                    <TabList>
                        {isAuthorized('AttendLive', 'Read') && <Tab>Live Attendance</Tab>}

                        {isAuthorized('AttendAll', 'Read') && <Tab>All Attendance</Tab>}

                        {isAuthorized('AttendAdmin', 'Read') && <Tab>Admin Attendance</Tab>}

                        {isAuthorized('AttendGraph', 'Read') && <Tab>Attendance Graph</Tab>}

                        {isAuthorized('VoteReport', 'Read') &&  <Tab>Report of voting</Tab>}
                       
                    </TabList>

                    { //Live attendance
                        isAuthorized('AttendLive', 'Read') &&
                        <TabPanel>
                            {!this.state.loading ?
                                <div className="Reports">

                                    {this.state.loginsummary && loginsummary.length > 0 ?

                                        <div>
                                            <div className="chat-heading-am">LIVE ATTENDANCE</div>
                                            <Row>
                                                <Col md>
                                                    <MeetingAttendance postMeeting={false} showGuests={true} showTitle={false} />
                                                </Col>
                                            </Row>

                                            <hr />
                                            <div className="chat-heading-am">LIST ATTENDEES</div>
                                            <Row>
                                                <Col md>
                                                        <MembersList authorized={isAuthorized('AttendLive', 'Write')} isloggedincurrent={true} vmid={this.VmeetingId} />
                                                </Col>
                                            </Row>


                                        </div>
                                        :
                                        <div>No data found for this meeting.</div>
                                    }

                                </div>
                                :
                                <div className="dashHeading">Loading....</div>
                            }
                        </TabPanel>
                    }

                    { //All attendance
                        isAuthorized('AttendAll', 'Read') &&
                        <TabPanel>
                            <PostMeetingReport authorized={isAuthorized('AttendAll', 'Write')} />
                        </TabPanel>
                    }

                    { //Admin attendance
                        isAuthorized('AttendAdmin', 'Read') &&
                        <TabPanel><AdminAttendanceReport /></TabPanel>
                    }

                    { //Attendance graph
                        isAuthorized('AttendGraph', 'Read') &&
                        <TabPanel><GraphAttendanceContainer /></TabPanel>
                    }

                    { //Report of voting
                        isAuthorized('VoteReport', 'Read') &&
                        <TabPanel>
                                <RVReport authorized={isAuthorized('VoteReport', 'Write')} />
                        </TabPanel>
                    }
                </Tabs>
            </div>

        )

    }
}

Report.contextType = AdminContext;
