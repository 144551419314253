import React, { Component, Fragment, Suspense, lazy } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faCheckCircle, faEdit, faRetweet, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import QRCode from "react-qr-code";
import { LayoutRegistration } from './LayoutRegistration';
import AdminContext from '../context/AdminContext';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';

const QrReader = lazy(() => import('react-qr-reader'));
const LazyFallBack = <div className="loaderholder"> < div className="loader" /></div>

const phases = [
    "Start",
    "Scanner",
    "Search",
    "SingleResult",
    "ListResult",
    "AssignNewCode",
    "UpdateAttendee",
    "ProxyAppointment",
    "OwnDevice",
    "AssignPollCard",
    "Complete"
]

export class Registration extends Component {
    static displayName = Registration.name;

    constructor(props) {
        super(props);
        this.UserId = parseInt(sessionStorage.getItem('UserId'));
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.state = {
            loading: false,
            phase: 1,
            name: null,
            role: null,
            VmId: null,
            userGuid: null,
            message: null,

            data: null,
            attendeeName: '',
            attendeeEmail: '',
            attendeeNumber: '',
            attendeeRole: '',
            attendeeCref: '',
            attendeeGuid: '',
            attendeeSc1: '',
            attendeeSc2: '',

            selectedAttendee: {},
            hasProxy: null,
            isProxy: null,
            showIsProxy: false,
            ownDevice: null,
            pollCardNumber: '',
            constituencies: [],
            emailSent: null,

            buttonLoader: false,
            mounted: true,
            modalShow: false,
            updateEmailConf: false,
            cameraFacing: "environment",
            useScanner: true //true = external scanner, false =camera
        }

    }

    componentDidMount() {
        this.resetPage();
        this.getConstituencies();
        this.getVmTitle();
        this.startbutton();
    }

    async componentWillUnmount() {
        //unmount camera
        await this.setState({
            mounted: false
        })

        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    getVmTitle = async () => {
        var VMId = parseInt(sessionStorage.getItem('VMId'));
        if (VMId > 0) {
            try {
                await axios.get("api/GetTitleAndClient").then(response => {
                    if (response.status === 200) {
                        this.setState({ meetingTitle: response.data.meetingTitle });
                    }
                });
            } catch {
                console.log('Error getting meeting title');
            }
        }
    }

    resetPage = () => {
        this.setState({
            name: null,
            role: null,
            message: null,
            VmId: null,
            userGuid: null,
            loading: false,
            data: null,
            attendeeName: '',
            attendeeEmail: '',
            attendeeNumber: '',
            attendeeRole: '',
            attendeeCref: '',
            attendeeGuid: '',
            attendeeSc1: '',
            attendeeSc2: '',

            attendeeAddress1: '',
            attendeePostcode: '',
            selectedAttendee: {},
            hasProxy: null,
            isProxy: null,
            showIsProxy: false,
            ownDevice: null,
            pollCardNumber: '',
            emailSent: null,

            buttonLoader: false,
            mounted: true,
            modalShow: false,
            updateEmailConf: false
        })
        this.startbutton();
    }

    returnToSearch = () => {
        this.setState({
            phase: 3,
            name: null,
            role: null,
            message: null,
            VmId: null,
            userGuid: null,
            loading: false,
            data: null,
            attendeeName: '',
            attendeeEmail: '',
            attendeeNumber: '',
            attendeePostcode: '',
            attendeeAddress1: '',
            attendeeRole: '',
            attendeeCref: '',
            attendeeGuid: '',
            attendeeSc1: '',
            attendeeSc2: '',

            selectedAttendee: {},
            hasProxy: null,
            isProxy: null,
            showIsProxy: false,
            ownDevice: null,
            pollCardNumber: '',
            emailSent: null
        })
    }

    startbutton = () => {
        if (this.context.switches.enableQrCode) {
            this.setState({
                phase: 2
            })
        } else {
            this.setState({
                phase: 3
            })
        }
    }

    updateCameraFacing = () => {
        this.setState({
            cameraFacing: this.state.cameraFacing === "user" ? "environment" : "user"
        })
    }

    getConstituencies = async () => {
        const VMId = parseInt(this.VmeetingId)
        const options = {
            url: 'VMGetMeetingConstituencyList',
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            data: VMId
        };
        await axios(options)
            .then(response => {
                this.setState({
                    constituencies: response.data
                })
            })
    }


    handleScan = async (data) => {
        if (data) {
            var query = data.split("?")
            if (query[1]) {
                this.setState({
                    loading: true,
                    message: null
                })
                const values = queryString.parse(query[1]);
                if (values.vm) {
                    if (parseInt(values.vm) === this.VmeetingId) {
                        const user = { VmeetingId: parseInt(values.vm), Guid: values.dl };
                        this.setState({
                            VmId: values.vm,
                            userGuid: values.dl
                        })
                        await axios.post("VMGetUserFromGuid", user).then(async response => {
                            if (response.data.pinId > 0 && response.data.cref > 0) {
                                var list = [];
                                list.push(response.data);
                                await this.setState({
                                    selectedAttendee: response.data,
                                    data: list,
                                    phase: 5,
                                    pinId: response.data.pinId,
                                    attendeeName: response.data.name,
                                    name: response.data.name,
                                    attendeeEmail: response.data.emailAddress,
                                    attendeeNumber: response.data.memberNo,
                                    attendeeRole: response.data.attendeeRole,
                                    role: response.data.attendeeRole ? response.data.attendeeRole.replace("Member", " Member") : response.data.attendeeRole,
                                    attendeeCref: response.data.cref,
                                    attendeeGuid: response.data.qrUrl,
                                    attendeeSc1: response.data.sc1,
                                    attendeeSc2: response.data.sc2
                                })

                            } else {
                                this.setState({
                                    message: "Unable to find a matching record"
                                })
                            }
                        })
                    } else {
                        this.setState({
                            message: "Incorrect meeting requested"
                        })
                    }
                }
                this.setState({
                    loading: false
                })
            }
        }
    }

    confirmAttendeeSelection = async () => {
        await this.setState({
            buttonLoader: true,
            message: null,
            attendeeName: this.state.selectedAttendee.name,
            pinId: this.state.selectedAttendee.pinId,
            attendeeRole: this.state.selectedAttendee.attendeeRole,
            attendeeEmail: this.state.selectedAttendee.emailAddress,
            attendeeCref: this.state.selectedAttendee.cref,
            attendeeGuid: this.state.selectedAttendee.qrUrl,
            attendeeSc1: this.state.selectedAttendee.sc1,
            attendeeSc2: this.state.selectedAttendee.sc2,
        })
        if (this.state.selectedAttendee.attendeeRole.toLowerCase() === "votingmember") {
            var request = {
                vmid: this.VmeetingId,
                cesVotersId: this.state.selectedAttendee.pinId
            }
            await axios.post("VMGetAttendeeHasProxy", request).then(response => {
                //console.log(JSON.parse(response.data.statusDescription)[0])
                if (response.data) {
                    let responsevalues = JSON.parse(response.data.statusDescription)[0];
                    //if hasProxy is true and proxy rules set in switches, apply switch rules.
                    if (this.context.switches.enableProxyVoting) {
                        this.setState({
                            buttonLoader: false,
                            //hasProxy: response.data.statusID === 1 ? true : false,
                            hasProxy: responsevalues.HasProxy === 1 ? true : false,
                            isProxy: responsevalues.IsProxy === 1 ? true : false
                        })
                    } else {
                        //set the states other than phase
                        this.setState({
                            buttonLoader: false,
                            //hasProxy: response.data.statusID === 1 ? true : false,
                            hasProxy: responsevalues.HasProxy === 1 ? true : false,
                            isProxy: responsevalues.IsProxy === 1 ? true : false,
                            rescindProxy: this.context.switches.proxySettingId === 2
                        })
                    }
                    this.phaseOrFinal();
                }
            }).catch(error => {
                this.setState({
                    buttonLoader: false,
                    message: "An error occured, please try again"
                })
            })
        } else {
            this.setState({
                buttonLoader: false
            })
            this.registerAttendee();
        }
    }

    phaseOrFinal = () => {
        //phase logic after search and update screens

        var currentPhase = this.state.phase;
        var switches = this.context.switches;
        var newPhase;
        if (
            ((this.state.selectedAttendee.attendeeRole && this.state.selectedAttendee.attendeeRole.toLowerCase() === "votingmember")
                || (this.state.attendeeRole && this.state.attendeeRole.toLowerCase() === "votingmember"))
            && !(this.state.hasProxy && switches.proxySettingId === 3)
        ) {
            if (currentPhase < 7 && this.state.hasProxy === true && switches.proxySettingId === 1) {
                //if proxy and can choose proxy outcome and not yet done so
                newPhase = 7;
            } else if (this.state.hasProxy === false || switches.proxySettingId === 2 || this.state.rescindProxy === true) {
                //These paths will be able to cast a vote in the meeting, method depending on own device rules.
                if (switches.attendeesOwnDevice) {
                    newPhase = 8;
                } else {
                    newPhase = 9;
                }
            }
        } else {
            //these paths skip straight to the end
            this.registerAttendee();
        }

        if (newPhase && newPhase > 0) {
            this.setState({
                phase: newPhase
            })
        }
        return;
    }

    rescindProxy = async () => {
        await this.setState({
            rescindProxy: true
        })
        this.phaseOrFinal();
    }


    registerAttendee = async (e) => {
        if (e) {
            e.preventDefault();
        }
        var attendee = {};
        if (this.state.pinId) {
            attendee = {
                UserId: this.UserId,
                VmeetingId: this.VmeetingId,
                PinId: parseInt(this.state.pinId),
                PollCardNumber: !this.state.ownDevice && this.state.pollCardNumber ? this.state.pollCardNumber : null,
                RescindProxy: this.state.rescindProxy
            }
        } else if (this.state.selectedAttendee) {
            attendee = {
                UserId: this.UserId,
                VmeetingId: this.VmeetingId,
                PinId: parseInt(this.state.selectedAttendee.pinId),
                PollCardNumber: !this.state.ownDevice && this.state.pollCardNumber ? this.state.pollCardNumber : null,
                RescindProxy: this.state.rescindProxy
            }
        }

        if (attendee.PinId && attendee.VmeetingId) {

            await axios.post("VMAddValidatedAttendee", attendee).then(response => {
                if (response.data.statusID === 1) { //1 success
                    this.setState({
                        phase: 10,
                        showIsProxy: this.state.isProxy
                    })
                }

                else if (response.data.statusID === 2) {
                    this.setState({
                        message: 'Attendee already registered',
                        phase: 50
                    })
                }
                else if (response.data.statusID === 3) {
                    this.setState({
                        message: 'Duplicate poll card or device number exists',
                        phase: 9
                    })
                } else {
                    this.setState({
                        message: 'Return code out of range',
                        phase: 50
                    })
                }
            })
        } else {
            this.setState({
                message: 'Required data missing, consider refreshing your browser',
                phase: 50
            })
        }
    }

    //update state for text fields
    onChange(event) {
        const target = event.target;
        const name = target.name;
        const value = target.value;

        this.setState({
            [name]: value,
        });
    }

    onSearch = async () => {
        this.setState({
            searchValidationMessage: null,
            message: null
        })
        if (this.state.attendeeName.trim().length > 2 || this.state.attendeeEmail.trim().length > 2 || this.state.attendeeNumber.trim().length > 2 || this.state.attendeeAddress1.trim().length > 2 || this.state.attendeePostcode.trim().length > 2) {
            var getUserBySearch = {
                VmeetingId: parseInt(this.VmeetingId),
                Name: this.state.attendeeName.trim(),
                Email: this.state.attendeeEmail.trim(),
                MemberNo: this.state.attendeeNumber ? this.state.attendeeNumber.trim() : '',
                Address1: this.state.attendeeAddress1 ? this.state.attendeeAddress1.trim() : '',
                Postcode: this.state.attendeePostcode ? this.state.attendeePostcode.trim() : ''
            }

            await axios.post("VMGetUserFromSearch", getUserBySearch).then(response => {

                if (response.data) {
                    this.setState({
                        data: response.data,
                        phase: 5
                    })
                }
                if (response.data.length === 1) {
                    this.setState({
                        selectedAttendee: response.data[0]
                    })
                }
            }).catch((error) => {
                this.setState({
                    message: "Search error, check data",
                    phase: 50
                })
            }

            )
        } else {
            this.setState({
                searchValidationMessage: 'Please enter at least three characters into at least one field to search'
            })
        }


    }

    onSelectRow = (attendee) => {
        this.setState({
            selectedAttendee: attendee
        })
    }

    onRowKeyPress = (event, attendee) => {
        if (event.charCode === 13) {
            this.onSelectRow(attendee);
        }
    }

    addNewAttendee = async (event) => {
        event.preventDefault();
        this.setState({
            message: null,
            buttonLoader: true
        })
        var user = {
            UserId: this.UserId,
            VmeetingId: this.VmeetingId,
            Name: this.state.attendeeName,
            EmailAddress: this.state.attendeeEmail,
            MemberNo: this.state.attendeeNumber,
            AttendeeRole: this.state.attendeeRole,
            Cref: this.state.constituencies.length === 1 ? 1 : parseInt(this.state.attendeeCref)
        }
        if (user.VmeetingId > 0 && (user.Name.length > 1 && user.Name.length < 51) && user.EmailAddress.length < 257 && user.MemberNo.length < 51 && user.AttendeeRole.length > 4 && user.Cref > 0) {
            await axios.post("VMAddNewAttendee", user).then(response => {
                if (response.data.statusID > 0) {
                    this.setState({
                        pinId: response.data.statusID,
                        attendeeGuid: response.data.statusDescription,
                        attendeeSc1: response.data.sc1,
                        attendeeSc2: response.data.sc2,
                        message: null,
                        hasProxy: false,
                        buttonLoader: false
                    })
                    this.phaseOrFinal();
                }

                else if (response.data.statusID === -1) {
                    this.setState({
                        message: 'Email address already exists',
                        buttonLoader: false
                    })
                }
                else if (response.data.statusID === -2) {
                    this.setState({
                        message: 'There are no codes available to assign',
                        buttonLoader: false
                    })
                }
                else if (response.data.statusID === -3) {
                    this.setState({
                        message: 'Member number already exists',
                        buttonLoader: false
                    })
                }
                else {
                    this.setState({
                        message: 'An error occurred',
                        buttonLoader: false
                    })
                }
            })
        } else {
            this.setState({
                message: 'Data missing or invalid',
                buttonLoader: false
            })
        }
    }

    updateAttendee = async (event) => {
        event.preventDefault();
        var user = {
            UserId: this.UserId,
            VmeetingId: this.VmeetingId,
            PinId: this.state.pinId,
            Name: this.state.attendeeName,
            EmailAddress: this.state.attendeeEmail ? this.state.attendeeEmail : '',
            MemberNo: this.state.attendeeNumber ? this.state.attendeeNumber : '',
            AttendeeRole: this.state.attendeeRole,
            Cref: this.state.constituencies.length === 1 ? 1 : parseInt(this.state.attendeeCref)
        }
        if (user.PinId > 0 && user.VmeetingId > 0 && user.Name.length > 1 && user.AttendeeRole.length > 4 && user.Cref > 0) {
            await axios.post("VMUpdateAttendee", user).then(response => {
                if (response.data.statusID || response.data.statusID === 0) { //1 success has proxy
                    this.setState({
                        hasProxy: response.data.statusID === 1 ? true : false
                    })
                    this.phaseOrFinal();
                }

                else {
                    this.setState({
                        message: null,
                        phase: 50
                    })
                }
            })
        } else {
            this.setState({
                message: 'Data missing'
            })
        }
    }

    unregisterAttendee = async () => {
        this.setState({
            message: null
        })
        if (this.state.selectedAttendee) {
            var attendee = {
                UserId: this.UserId,
                VmeetingId: this.VmeetingId,
                PinId: parseInt(this.state.selectedAttendee.pinId),
                PollCardNumber: null
            }
            await axios.post("VMRemoveValidatedAttendee", attendee).then(response => {
                if (response.data.statusID === 1) { //1 success
                    this.onSearch();
                    var attendee = this.state.selectedAttendee;
                    attendee.registered = false;
                    this.setState({
                        selectedAttendee: attendee
                    })
                } else {
                    this.setState({
                        message: response.data.StatusDescription
                    })
                }
            })
        }
    }

    setOwnDevice = async (ownDevice) => {
        this.setState({
            ownDevice: ownDevice
        }, () => {
            if (!ownDevice) {
                this.setState({
                    phase: 9
                })
            } else {
                //this.setState({
                //    phase: 9
                //})
                this.registerAttendee();
            }
        })
    }

    sendCodes = async () => {
        this.setState({
            loading: true,
            updateEmailConf: false
        })
        if (this.VmeetingId > 0 && this.state.attendeeEmail.length > 0) {
            var VMRegistrationEmail = {
                VMId: this.VmeetingId,
                Name: this.state.attendeeName ? this.state.attendeeName : this.state.selectedAttendee.name,
                EmailAddress: this.state.attendeeEmail,
                FullUrlDirectLinkGUID: this.state.attendeeGuid
            }
            await axios.post("VMRegistrationSendCodes", VMRegistrationEmail).then(response => {
                this.setState({
                    emailSent: response.data
                })
            });
        } else {
            this.setState({
                emailSent: false
            })
        }
        this.setState({
            loading: false,
            modalShow: false
        })
    }


    printElement = () => {
        var domClone = document.getElementById("printThis").cloneNode(true);

        var $printSection = document.getElementById("printSection");

        if (!$printSection) {
            var $printSection = document.createElement("div");
            $printSection.id = "printSection";
            document.body.appendChild($printSection);
        }

        $printSection.innerHTML = "";
        $printSection.appendChild(domClone);
        window.print();
    }


    handleError = err => {
        console.error(err)
    }


    toggleModal = () => {
        this.setState({
            modalShow: !this.state.modalShow
        })
    }


    //Start of stages functions
    //phase 1
    startPage = (isAuthorized) => {
        return (<Fragment>
            <div className="registrationText">
                <h1>{this.state.meetingTitle}</h1>
                <p>Please press start to begin registration</p>
            </div>
            <div className="registrationButton d-flex justify-content-center">
                <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.startbutton()}>Start</button>
            </div>
        </Fragment>)
    }

    //phase 2
    barcodeScanner = (isAuthorized) => {
        return (
            <Fragment>
                <div className="d-flex justify-content-center ">
                    <button className="btn btn-secondary" onClick={() => this.setState({ useScanner: !this.state.useScanner })}>Use {this.state.useScanner ? "device camera" : "handheld scanner"}</button>
                </div>

                {!this.state.useScanner && <Fragment>
                    <div className="registrationText">
                        <p>Please place your QR code in front of the {this.state.useScanner ? "scanner" : "camera"} to scan</p>
                    </div>
                    <div className="d-flex justify-content-center ">
                        <button disabled={!isAuthorized('Registration', 'Write')} onClick={() => this.updateCameraFacing()} title="Change camera facing" className="btn btn-secondary d-lg-none"><FontAwesomeIcon icon={faRetweet} /></button>
                    </div>
                </Fragment>}
                <div className="d-flex justify-content-center" id="qrcontainer">
                    {this.state.useScanner ? <BarCodeScanner handleScan={this.handleScan} /> :
                        this.state.loading ? <div className="loaderholder"> < div className="loader" /></div> :
                            <Fragment>
                                <Suspense fallback={LazyFallBack}>
                                    {this.state.mounted && <QrReader
                                        className="QrReader"
                                        delay={300}
                                        onError={this.handleError}
                                        onScan={this.handleScan}
                                        facingMode={this.state.cameraFacing}
                                    />}
                                </Suspense>
                            </Fragment>
                    }
                </div>
                {
                    this.state.message &&
                    <div className="d-flex justify-content-center warning">
                        {this.state.message}
                    </div>
                }
                <div className="d-flex justify-content-center">
                    <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.setState({ phase: 3 })}>Attendee does not have an invitation letter</button>
                </div>
            </Fragment >
        )
    }

    //phase 3
    searchForAttendee = (isAuthorized) => {
        return (<Fragment>
            <div className="registrationText">
                <p>Search for the attendee using at least one field below</p>
            </div>
            <div className="registrationSearchBox">
                <div className="row">
                    <div className="col-md-6">
                        <label htmlFor="attendeeName">Attendee Name</label>
                        <input id="attendeeName" className="form-control" type="text" name="attendeeName" style={{ width: '100%' }} onChange={(e) => this.onChange(e)} value={this.state.attendeeName} maxLength="50" placeholder="Attendee name" autoFocus />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="attendeeNumber">Attendee ID</label>
                        <input id="attendeeNumber" className="form-control" type="text" name="attendeeNumber" style={{ width: '100%' }} onChange={(e) => this.onChange(e)} value={this.state.attendeeNumber} maxLength="50" placeholder="Attendee ID" />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="attendeePostcode">Post code</label>
                        <input id="attendeePostcode" className="form-control" type="text" name="attendeePostcode" style={{ width: '100%' }} onChange={(e) => this.onChange(e)} value={this.state.attendeePostcode} maxLength="20" placeholder="Post code" />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="attendeeEmail">Email address</label>
                        <input id="attendeeEmail" className="form-control" type="text" name="attendeeEmail" style={{ width: '100%' }} onChange={(e) => this.onChange(e)} value={this.state.attendeeEmail} maxLength="256" placeholder="Email address" />
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="attendeeAddress1">Address 1</label>
                        <input id="attendeeAddress1" className="form-control" type="text" name="attendeeAddress1" style={{ width: '100%' }} onChange={(e) => this.onChange(e)} value={this.state.attendeeAddress1} maxLength="256" placeholder="Address 1" />
                    </div>

                </div>
            </div>
            {this.state.searchValidationMessage &&
                <div className="d-flex justify-content-center registrationValidationMessage">
                    <p>{this.state.searchValidationMessage}</p>
                </div>
            }
            <div className="registrationButtonSmall d-flex justify-content-center">
                <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.onSearch()}>Search</button>
            </div>
        </Fragment>
        )
    }

    //phase 4
    singleSearchResponse = (isAuthorized) => {
        return (<Fragment>
            <div className="registrationText">
                <p>Please confirm the details below are correct to proceed with registration</p>
            </div>

            <div className="confirmationText">
                <div className="registrationKey">Name:</div><div className="registrationValue">&nbsp;{this.state.name}</div>
                <div className="registrationKey">Attendee Type:</div><div className="registrationValue">&nbsp;{this.state.role}</div>
            </div>

            <div className="registrationButton">
                <div className="d-flex justify-content-around">
                    <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.confirmDetails()}>Yes the details<br />are correct</button>
                    <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.setState({ phase: 6.5 })}>No the details<br />are incorrect</button>
                </div>
            </div>
            <div className="d-flex justify-content-center">
                <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.returnToSearch()}>Return to search</button>
            </div>
        </Fragment>
        )
    }

    //phase 5
    multipleSearchResponse = (isAuthorized) => {
        return (<Fragment>

            <div className="registrationText">
                {this.state.data.length > 0 ?
                    <p>Please select correct attendee from the list below</p>
                    :
                    <p>We have not found any matching results, you may try again using "Return to search" or add the user using "Attendee not listed" below.</p>
                }
            </div>
            <div className="registrationSearchBox d-flex justify-content-center">
                <table>
                    <tbody>
                        <tr><th>Attendee ID</th><th>Attendee name</th><th>Email address</th><th>Address 1</th><th>Post code</th><th>Attendee role</th><th></th></tr>
                        {this.state.data.map(attendee =>
                            <tr key={attendee.pinId} title="Click or focus and return to select row" className={this.state.selectedAttendee && this.state.selectedAttendee.pinId === attendee.pinId ? "SelectedAttendee" : ""} tabIndex="0" onClick={() => this.onSelectRow(attendee)} onKeyPress={(e) => this.onRowKeyPress(e, attendee)}>
                                <td>{attendee.memberNo}</td>
                                <td>{attendee.name}</td>
                                <td>{attendee.emailAddress}</td>
                                <td>{attendee.address1}</td>
                                <td>{attendee.postcode}</td>
                                <td>{attendee.attendeeRole ? attendee.attendeeRole.replace("Member", " Member") : null}</td>
                                <td>{attendee.registered && <FontAwesomeIcon icon={faCheckCircle} color="green" title="Already registered" />}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
            {this.state.message &&
                <div className="d-flex justify-content-center warning">
                    {this.state.message}
                </div>
            }
            {!!this.state.selectedAttendee.registered &&
                <div className="d-flex justify-content-center warning">
                    The selected attendee is already registered, you will need to unregister them to take any further action
                </div>
            }
            {this.state.selectedAttendee && Object.keys(this.state.selectedAttendee).length > 0 && !this.state.selectedAttendee.attendeeRole &&
                <div className="d-flex justify-content-center warning">
                    The selected attendee has no role, you will need to update this to continue
                </div>
            }
            <div className="registrationButtonSmall">
                <div className="d-flex justify-content-around">
                    <button className="btn btn-primary btn-lg"
                        onClick={() => this.confirmAttendeeSelection()}
                        disabled={(
                            (!isAuthorized('Registration', 'Write'))
                            || (this.state.selectedAttendee.attendeeRole && this.state.selectedAttendee.pinId > 0 && !this.state.selectedAttendee.registered)
                        ) ? false : true}>
                        Select attendee
                    </button>
                    <button
                        className="btn btn-primary btn-lg"
                        onClick={() => {
                            this.setState({
                                attendeeName: this.state.selectedAttendee.name,
                                pinId: this.state.selectedAttendee.pinId,
                                attendeeRole: this.state.selectedAttendee.attendeeRole,
                                attendeeEmail: this.state.selectedAttendee.emailAddress,
                                attendeeCref: this.state.selectedAttendee.cref,
                                attendeeGuid: this.state.selectedAttendee.qrUrl,
                                attendeeSc1: this.state.selectedAttendee.sc1,
                                attendeeSc2: this.state.selectedAttendee.sc2,
                                attendeeNumber: this.state.selectedAttendee.memberNo,
                                attendeeAddress1: this.state.selectedAttendee.address1,
                                attendeePostcode: this.state.selectedAttendee.postcode,
                                phase: 6.5
                            })
                        }}
                        disabled={
                            (
                                (!isAuthorized('Registration', 'Write'))
                                ||
                                (this.state.selectedAttendee.pinId > 0 && !this.state.selectedAttendee.registered)
                            ) ? false : true}>
                        Edit attendee
                    </button>
                    <button className="btn btn-primary btn-lg"
                        onClick={() => this.unregisterAttendee()}
                        disabled={
                            (
                                (!isAuthorized('Registration', 'Write'))
                                ||
                                (this.state.selectedAttendee.pinId > 0 && !!this.state.selectedAttendee.registered)
                            )
                                ? false : true}>
                        Unregister attendee
                    </button>
                    <button className="btn btn-primary btn-lg"
                        disabled={!isAuthorized('Registration', 'Write')}
                        onClick={() => this.setState({ phase: 3, selectedAttendee: {} })}>
                        Return to search
                    </button>
                    <button className="btn btn-primary btn-lg"
                        disabled={!isAuthorized('Registration', 'Write')}
                        onClick={() => this.setState({ phase: 6, selectedAttendee: {} })}>
                        Attendee not listed
                    </button>
                </div>
            </div>
        </Fragment>
        )
    }

    //phase 6
    addNewAttendeeForm = (isAuthorized) => {
        return (<Fragment>
            <div className="registrationText">
                <p>Assign attendee access codes</p>
                <p>If the attendee is eligible to attend but does not have valid login credentials, complete the form to assign new credentials</p>
            </div>
            <form onSubmit={this.addNewAttendee}>
                <div className="registrationSearchBox container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="newAttendeeName">Attendee Name</label>
                            <input id="newAttendeeName" className="form-control" type="text" name="attendeeName" style={{ width: '100%' }} onChange={(e) => this.onChange(e)} value={this.state.attendeeName} placeholder="Attendee Name" maxLength="50" required />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="newAttendeeNumber">Member ID</label>
                            <input id="newAttendeeNumber" className="form-control" type="text" name="attendeeNumber" style={{ width: '100%' }} onChange={(e) => this.onChange(e)} value={this.state.attendeeNumber} maxLength="50" placeholder="Member ID" />

                        </div>

                        <div className="col-md-6">
                            <label htmlFor="newAttendeeEmail">Email Address</label>
                            <input id="newAttendeeEmail" className="form-control" type="email" name="attendeeEmail" style={{ width: '100%' }} onChange={(e) => this.onChange(e)} value={this.state.attendeeEmail} placeholder="Email Address" maxLength="256" required />

                        </div>

                        <div className="col-md-6">
                            <label htmlFor="attendeeRole">Attendee Role</label>
                            <select
                                style={{ width: '100%' }}
                                id="attendeeRole"
                                name="attendeeRole"
                                value={this.state.attendeeRole}
                                onChange={(e) => this.onChange(e)}
                                required
                            >
                                <option value=''> Select Role </option>
                                <option value='VotingMember'> Voting Member </option>
                                <option value='NonVotingMember'> Non-voting Member </option>
                                <option value='Guest'> Guest </option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="attendeeCref">Constituency</label>
                            <select
                                style={{ width: '100%' }}
                                id="attendeeCref"
                                name="attendeeCref"
                                value={this.state.attendeeCref}
                                onChange={(e) => this.onChange(e)}
                                required
                            >
                                {this.state.constituencies.length > 1 ? <Fragment>
                                    <option value=''> Select Constituency </option>
                                    {this.state.constituencies.map(constituency => (
                                        <option key={constituency.constituencyID} value={constituency.constituencyID}>
                                            {constituency.description}
                                        </option>
                                    ))}
                                </Fragment>
                                    : <option value='1'> Single Constituency </option>}
                            </select>
                        </div>
                    </div>
                </div>
                {this.state.message && <div className="registrationText">{this.state.message}</div>}
                <div className="registrationButton">
                    <div className="d-flex justify-content-around">
                        <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" type="submit" >Assign</button>
                        <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.returnToSearch()}>Return to search</button>
                    </div>
                </div>
            </form>
        </Fragment>
        )
    }

    //phase 6.5
    updateAttedeeForm = (isAuthorized) => {
        return (<Fragment>
            <div className="registrationText">
                <p>Update attendee details</p>
            </div>
            <form onSubmit={this.updateAttendee}>
                <div className="registrationSearchBox container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="newAttendeeName">Attendee Name</label>
                            <input id="newAttendeeName" className="form-control" type="text" name="attendeeName" style={{ width: '100%' }} onChange={(e) => this.onChange(e)} value={this.state.attendeeName} placeholder="Attendee Name" maxLength="50" disabled />

                        </div>
                        <div className="col-md-6">
                            <label htmlFor="newAttendeeNumber">Attendee ID</label>
                            <input id="newAttendeeNumber" className="form-control" type="text" name="attendeeNumber" style={{ width: '100%' }} onChange={(e) => this.onChange(e)} value={this.state.attendeeNumber} placeholder="Attendee ID" maxLength="50" disabled />

                        </div>

                        <div className="col-md-6">
                            <label htmlFor="newAttendeeEmail">Email Address</label>
                            <input id="newAttendeeEmail" className="form-control" type="email" name="attendeeEmail" style={{ width: '100%' }} onChange={(e) => this.onChange(e)} value={this.state.attendeeEmail} placeholder="Email Address" maxLength="256" disabled />

                        </div>

                        <div className="col-md-6">
                            <label htmlFor="attendeeRole">Attendee Role</label>
                            <select
                                style={{ width: '100%' }}
                                id="attendeeRole"
                                name="attendeeRole"
                                value={this.state.attendeeRole}
                                onChange={(e) => this.onChange(e)}
                                required
                            >
                                <option value=''> Select Role </option>
                                <option value='VotingMember'> Voting Member </option>
                                <option value='NonVotingMember'> Non-voting Member </option>
                                <option value='Guest'> Guest </option>
                            </select>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="attendeeCref">Constituency</label>
                            <select
                                style={{ width: '100%' }}
                                id="attendeeCref"
                                name="attendeeCref"
                                value={this.state.attendeeCref}
                                onChange={(e) => this.onChange(e)}
                                required
                            >
                                {this.state.constituencies.length > 1 ? <Fragment>
                                    <option value=''> Select Constituency </option>
                                    {this.state.constituencies.map(constituency => (
                                        <option key={constituency.constituencyID} value={constituency.constituencyID}>
                                            {constituency.description}
                                        </option>
                                    ))}
                                </Fragment>
                                    : <option value='1'> Single Constituency </option>}
                            </select>
                        </div>
                    </div>
                </div>
                {this.state.message && <div className="registrationText">{this.state.message}</div>}
                <div className="registrationButton">
                    <div className="d-flex justify-content-around">
                        <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" type="submit" >Update</button>
                        <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.returnToSearch()}>Return to search</button>
                    </div>
                </div>
            </form>
        </Fragment>
        )
    }

    //phase 7
    rescindProxyDialogue = (isAuthorized) => {
        return (<Fragment>
            <div className="registrationText">
                <p>{this.state.name ? this.state.name : this.state.attendeeName} has already submitted a pre-meeting or proxy vote, what would they like to do?</p>
            </div>
            <div className="registrationButton">
                <div className="d-flex justify-content-around">
                    <button disabled={!isAuthorized('Registration', 'Write') || this.state.buttonLoader} className="btn btn-primary btn-lg" onClick={() => this.registerAttendee()} >Keep existing<br />pre-meeting/<br />proxy vote</button>
                    <button disabled={!isAuthorized('Registration', 'Write') || this.state.buttonLoader} className="btn btn-primary btn-lg" onClick={() => this.rescindProxy()} >{this.state.buttonLoader ? <div className="loaderholder"><div className="loader" /></div> : <span>Rescind existing<br />pre-meeting/<br />proxy vote</span>}</button>
                </div>
            </div>
        </Fragment>
        )
    }

    //phase 8
    usingOwnDeviceDialogue = (isAuthorized) => {
        return (<Fragment>
            <div className="registrationText">
                {this.state.message && <p>{this.state.message}</p>}
                <p>Will {this.state.name ? this.state.name : this.state.attendeeName} be using their own device during the meeting?</p>
            </div>
            <div className="registrationButton">
                <div className="d-flex justify-content-around">
                    <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.setOwnDevice(true)}>Yes</button>
                    <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.setOwnDevice(false)}>No</button>
                </div>
            </div>
        </Fragment>)
    }

    //phase 9
    issuePollCardDialogue = (isAuthorized) => {
        return (<Fragment>
            <form onSubmit={this.registerAttendee}>
                <div className="registrationText">
                    <p>Please enter the poll card/smart device number that has been issued to the attendee below</p>
                </div>
                <div className="registrationSearchBox">
                    <label htmlFor="pollCardNumber">Poll card or device number</label>
                    <input id="pollCardNumber" className="form-control" type="text" name="pollCardNumber" style={{ width: '100%' }} onChange={(e) => this.onChange(e)} value={this.state.pollCardNumber} placeholder="Poll card or device number" autoFocus required />

                </div>
                {this.state.message &&
                    <div className="d-flex justify-content-center warning">
                        {this.state.message}
                    </div>
                }
                <div className="registrationButton">
                    <div className="d-flex justify-content-around">
                        <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" type="submit">Submit</button>
                        <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" type="button" onClick={() => this.setOwnDevice(true)}>Poll card/device <br /> not required</button>
                    </div>
                </div>
            </form>
        </Fragment>
        )
    }

    //phase 10
    confirmationAndTicket = (isAuthorized) => {
        return (<Fragment>
            <div className="d-flex justify-content-center"> <FontAwesomeIcon icon={faCheck} size="7x" color="green" title="Success!" /></div>
            <div className="registrationText">
                <p>{this.state.name ? this.state.name : this.state.attendeeName} has been successfully registered and their ticket will be available when they login to the attendee application. You can email the attendee their login if they have provided an email address, or print a copy below if they are not using their own device</p>
            </div>
            <div className="registrationButton">
                <div className="d-flex justify-content-around">
                    <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.printElement()}>Print ticket</button>
                    <button className="btn btn-primary btn-lg"
                        disabled={!isAuthorized('Registration', 'Write') || !this.state.pollCardNumber.length > 0 ? false : true}
                        title={this.state.pollCardNumber.length > 0 ? 'A poll card has been issued' : 'Send login details'}
                        onClick={() => this.toggleModal()}>
                        {this.state.loading ?
                            <div className="chatloaderholder"> < div className="loader" /></div>
                            : "Email login "}
                        {this.state.emailSent !== null && (this.state.emailSent ? <FontAwesomeIcon icon={faCheck} color="green" title="Success!" />
                            : <FontAwesomeIcon icon={faTimes} color="red" title="Error!" />)}
                    </button>
                    <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.resetPage()}>Next attendee</button>
                </div>
            </div>
            <Modal show={this.state.modalShow} onHide={() => this.toggleModal()} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Send Login Codes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="registrationText">
                        <p>
                            Login codes will be sent to:<br />
                            {this.state.attendeeEmail ? <span>{this.state.attendeeEmail}</span> : <span>No email address for this attendee.<br /> Select edit below to set an email address to send login details to.</span>}
                        </p>
                    </div>
                    {this.state.updateEmailConf ? <div className="mx-2"><label htmlFor="newAttendeeEmail">Update email address</label>
                        <input id="newAttendeeEmail" className="form-control" type="email" name="attendeeEmail" style={{ width: '100%' }} onChange={(e) => this.onChange(e)} value={this.state.attendeeEmail} placeholder="Email Address" maxLength="256" required />
                    </div>
                        : <div className="d-flex justify-content-center">
                            <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary" title="Edit email address" onClick={() => this.setState({ updateEmailConf: true })}><FontAwesomeIcon title="Edit email address" icon={faEdit} /></button>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button disabled={(!isAuthorized('Registration', 'Write') || this.state.attendeeEmail) ? false : true} className="btn btn-primary" onClick={() => this.sendCodes()}>
                        {this.state.loading ?
                            <div className="chatloaderholder"> < div className="loader" /></div>
                            : "Send Email"}
                    </button>
                    <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-secondary" onClick={() => this.toggleModal()}> Cancel </button>
                </Modal.Footer>
            </Modal >


            <div id="printThis">
                <div className="RegistrationLayout">
                    <div className="registrationLogo"><h3 className="adminTitle">CES<span className="pinkIcon">Registration</span></h3></div>
                    <h2>{this.state.attendeeName ? this.state.attendeeName : this.state.name ? this.state.name : ""}</h2>
                    <h3>{this.state.attendeeRole ? this.state.attendeeRole.replace("Member", " Member") : this.state.role ? this.state.role.replace("Member", " Member") : "Role error"}</h3>
                    <p>Meeting ID: {this.VmeetingId}</p>
                    <p>Security code part 1: {this.state.attendeeSc1}</p>
                    <p>Security code part 2: {this.state.attendeeSc2}</p>
                    <QRCode value={this.state.attendeeGuid} level="M" size={100} />
                </div>
            </div>
        </Fragment >
        )
    }

    //default error message
    defaultErrorMessage = (isAuthorized) => {
        return (<Fragment>
            <div className="d-flex justify-content-center"> <FontAwesomeIcon icon={faTimes} size="7x" color="red" title="Error!" /></div>
            <div className="registrationText">
                {this.state.message ? <p>{this.state.message}</p> : <p>An error has occurred, please try again.</p>}
            </div>
            <div className="registrationButton d-flex justify-content-center">
                <button disabled={!isAuthorized('Registration', 'Write')} className="btn btn-primary btn-lg" onClick={() => this.resetPage()}>Reset page</button>
            </div>
        </Fragment>
        )
    }

    //phase display selector
    displaySelector = (phase) => {
        const { isAuthorized } = this.context;

        switch (phase) {
            case 1:
                //Start
                return this.startPage(isAuthorized);
            case 2:
                //Barcode scanner
                return this.barcodeScanner(isAuthorized);
            case 3:
                //Search
                return this.searchForAttendee(isAuthorized);
            case 4:
                //Single search response
                return this.singleSearchResponse(isAuthorized);
            case 5:
                //List of search responses
                return this.multipleSearchResponse(isAuthorized);
            case 6:
                //Add new attendee
                return this.addNewAttendeeForm(isAuthorized);
            case 6.5:
                //Update attendee
                return this.updateAttedeeForm(isAuthorized);
            case 7:
                //Proxy appointment
                return this.rescindProxyDialogue(isAuthorized);
            case 8:
                //Using own device
                return this.usingOwnDeviceDialogue(isAuthorized);
            case 9:
                //Issue poll card
                return this.issuePollCardDialogue(isAuthorized);
            case 10:
                //Final confirmation and printed ticket
                return this.confirmationAndTicket(isAuthorized);
            default:
                //Error
                return this.defaultErrorMessage(isAuthorized);
        }
    }

    render() {
        const { isAuthorized } = this.context;
        var content = this.displaySelector(this.state.phase);
        return (
            <Fragment>
                <LayoutRegistration isAuthorized={isAuthorized('Registration', 'Write')} resetPage={this.resetPage} phase={this.state.phase} meetingTitle={this.state.meetingTitle} history={this.props.history}>
                    {content}
                </LayoutRegistration>
                <IsProxyModal showModal={this.state.showIsProxy} handleClose={() => this.setState({ showIsProxy: false })} />
            </Fragment>
        );
    }
}

Registration.contextType = AdminContext;

const BarCodeScanner = (props) => {
    const [value, setValue] = useState('');
    let scan = props.handleScan;
    const handleScan = (e) => {
        setValue(e.target.value)
    }
    const submitForm = (e) => {
        e.preventDefault();
        props.handleScan(value);
    }


    return (
        <Fragment>
            <form onSubmit={(e) => submitForm(e)}>
                <div className="registrationText m-3">
                    <p>Ensure focus is on textbox below then scan QR code to continue.</p>
                    <label htmlFor="barCode">QR code value</label>
                    <input className="form-control" id="barCode" type="text" value={value} onChange={(e) => handleScan(e)} autoFocus />
                </div>
            </form>
        </Fragment>
    )
}

const IsProxyModal = (props) => {
    return (
        <Modal show={props.showModal} onHide={props.handleClose} size="lg" backdrop="static" keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title>Named proxy alert</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="m-4 text-center"><FontAwesomeIcon icon={faExclamationTriangle} size="7x" color="orange" /></div>
                <div className="m-2 text-center fs-3">This attendee is a named proxy</div>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={props.handleClose}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}