import React, { Component, useContext } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Home } from './components/Home';
import { EventAdministration } from './components/MeetingSetup/EventAdministration';
import { EventSetup } from './components/MeetingSetup/EventSetup';
import { VoteAdministration } from './components/VoteAdministration';
import { Chat } from './components/chat/ChatV2';
import { ComfortMonitor } from './components/chat/ComfortMonitor/ComfortMonitor';
import { NotificationContainer } from 'react-notifications';
import AzureScale from './components/AzureScale';
import Report from './components/Report';
import { NavSide } from './components/NavSidebar';
import { NavMenu } from './components/NavMenu';
import { Container } from 'react-bootstrap';

import Summary from './components/Summary';
import { ChatContextProvider } from './components/context/ChatContextProvider';
import { Registration } from './components/Registration/Registration';
import { SelfRegistration } from './components/Registration/SelfRegistration';

import { Meeting } from './components/meeting/Meeting';
import { Logo } from './components/CivicaBrand';
import { GroupChat } from './components/chat/GroupChat';
import AdminContext from './components/context/AdminContext';
import UserAdministration from './components/UserAdministration';

import { createBrowserHistory } from 'history';
import { VoteLoader } from './components/SecondaryComponents';
import SurveyAdminParent from './components/Survey/SurveyAdminParent';

const history = createBrowserHistory();

const NotFound = () => <div> <h2>Not Found!</h2><p>The page you have requested has not been found, please check the address and try again.</p> </div>;
function NotAuthorised (props) {
    const { userData } = useContext(AdminContext);
    return(
        <Container><div> <h2>Not Authorised!</h2><p>You are not authorised to view this page.</p> {!!userData?.LockoutEnabled && <p>Your account has been disabled, please speak to your client advisor for assistance.</p>}</div></Container>
    )
};

export default class App extends Component {
    static displayName = App.name;
    static contextType = AdminContext;
    constructor(props) {
        super(props);
        this.state = {
            ready: false
        }
        console.log("Version 3.9.1");
    }

    componentDidMount() {
        //Authentication subscription set up
        this.getSettings();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    getSettings = () => {
        this.context.populateswitchdata(false);
    }

    

    render() {
        const { isAuthorized, isAuthenticated } = this.context;
        const { ready, userId, meetingId } = this.context;
        let link = document.createElement("a");
        link.href = this.props.path;
       
        if (isAuthenticated && ready && userId && meetingId) return (
            <div className="appParent overflow-hidden" >
                <Router>
                    <Switch>
                        <Route path='/selfregistration' component={isAuthorized('SelfRegistration', 'Read') ? SelfRegistration : NotAuthorised} />
                        <Route path='/registration' component={isAuthorized('Registration', 'Read') ? Registration : NotAuthorised} />

                        <Route path='/:path?'>
                            <NotificationContainer />
                            {this.context.meetingId > 0 && this.context.GetUserAccessiblePages()?.length > 1 && <NavSide isAuthenticated={isAuthenticated} />}

                            <div className="main vh-100 overflow-auto">
                                <NavMenu isAuthenticated={isAuthenticated} />
                                <RouterBlock />
                            </div>
                        </Route>
                    </Switch>
                </Router>
            </div>
        )
        //If meeting not set, any path should be userAdmin or meeting select
        else if (isAuthenticated && ready && userId && !meetingId) return (
            <MeetingSelectBlock />
        )
        else if (isAuthenticated) {
            return (
                <VoteLoaderBlock />
            )
        } else {
            return (
                <ElseBlock />
            )
        }
    }
}
App.contextType = AdminContext;

function RouterBlock(props) {
    const { isUserHasPageAccess, isGlobalAuthorized, GetUserAccessiblePages, meetingId, CheckUserMeetingPermissions } = useContext(AdminContext);
    let pages = GetUserAccessiblePages();
    return (
        <Container fluid>
            <div className="row fillheight">
                <div className="col-md-12 mainArea">
                    <ChatContextProvider>
                        { /* All children of the switch must be a route */}
                        <Switch>
                            <Route exact path='/' component={extGetHomePage(pages)} />
                            <Route path='/home' component={extGetHomePage(pages)} />
                            <Route path='/meeting' component={Meeting} />
                            <Route path='/eventadministration' component={isUserHasPageAccess('EventAdmin', 'Read') ? EventAdministration : NotAuthorised} />
                            <Route path='/eventsetup' component={isUserHasPageAccess('Setup', 'Read') ? EventSetup : NotAuthorised} />
                            <Route path='/voteadministration' component={isUserHasPageAccess('VoteAdmin', 'Read') ? VoteAdministration : NotAuthorised} />
                            <Route path='/report' component={isUserHasPageAccess('Report', 'Read') ? Report : NotAuthorised} />
                            <Route path='/summary' component={isUserHasPageAccess('PresSummary', 'Read') ? Summary : NotAuthorised} />
                            <Route path='/comfort' component={isUserHasPageAccess('ComfortMonitor', 'Read') ? ComfortMonitor : NotAuthorised} />
                            <Route path='/chat' component={isUserHasPageAccess('Moderators', 'Read') ? Chat : NotAuthorised} />
                            <Route path='/useradministration' component={isUserHasPageAccess('UserAdmin', 'Read') ? UserAdministration : NotAuthorised} />
                            <Route path='/azurescale' component={isGlobalAuthorized('AzureCapacity', 'Read') ? AzureScale : NotAuthorised} />
                            <Route path='/survey' component={isUserHasPageAccess('SurveyAdmin', 'Read') ? SurveyAdminParent : NotAuthorised} />

                            <Route component={NotFound} />
                        </Switch>
                        {meetingId &&
                            CheckUserMeetingPermissions('AdminChat', 'Read') &&
                            < GroupChat authorize={CheckUserMeetingPermissions('AdminChat', 'Write')} />
                        }
                    </ChatContextProvider>
                </div>
            </div>
        </Container>
    )
}

function VoteLoaderBlock(props) {
    const { isAuthenticated } = useContext(AdminContext);
    return ( <Router>
        <div className="main">
            <NavMenu isAuthenticated={isAuthenticated} />
            <VoteLoader />
        </div>
    </Router>
    )
}

function ElseBlock(props) {
    const { isAuthenticated } = useContext(AdminContext);
    return (<Router>
        <div className="main">
            <NavMenu isAuthenticated={isAuthenticated} />
            <Switch>
                <Route component={Logo} />
            </Switch>
        </div>
    </Router>)
}

function MeetingSelectBlock(props) {
    const { isAuthenticated, isGlobalAuthorized } = useContext(AdminContext);
    return (<Router>
        <div className="main">
            <NotificationContainer />
            <NavMenu isAuthenticated={isAuthenticated} />
            <Switch>
                <Route path='/useradministration'
                    component={isGlobalAuthorized('MetaUserAdmin', 'Read') ?
                        UserAdministration :
                        NotAuthorised} />
                <Route
                    render={() => <Meeting history={history} />}
                />
            </Switch>
        </div>
    </Router>)
}

function extGetHomePage(pages) {
    let response;
    if (pages?.length > 1) {
        response = Home;
    } else if (pages?.length === 1) {
        switch (pages[0]) {
            case "Moderators":
                response = Chat;
                break;
            case "PresSummary":
                response = Summary;
                break;
            case "ComfortMonitor":
                response = ComfortMonitor;
                break;
            case "UserAdmin":
                response = UserAdministration;
                break;
            case "EventAdmin":
                response = EventAdministration;
                break;
            case "Setup":
                response = EventSetup;
                break;
            case "AttendReg":
                response = Registration;
                history.push('/registration')
                break;
            case "AttendSelfReg":
                response = SelfRegistration;
                history.push('/selfregistration')
                break;
            case "Report":
                response = Report;
                break;
            case "VoteAdmin":
                response = VoteAdministration;
                break;
            case "SystemInfo":
                response = AzureScale;
                break;
            default:
                response = Home;
        }
    }
    return response;
}
