import { useEffect, useState} from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import SignalRController from '../SignalRController';

var DeleteOptions = [
    { "Description": "Reset all Contests, Delete all voting data and reset all pre-meeting data" },
    { "Description": "Reset all Attendee Logins (leaving votes cast in the Results table)" },
    { "Description": "Reset all Messages including Requests and Open Chat" },
    { "Description": "Reset all QuickPoll results" }];

export default function ClearMeetingData(props) {
    const [StatusID, setStatusID] = useState(false);
    const [busy, setBusy] = useState(false);
    const [notSelected, setNotSelected] = useState(true);
    const [deleteResponse, setDeleteResponse] = useState([]);
    const [checkedState, setCheckedState] = useState(
        new Array(DeleteOptions.length).fill(false)
    );
    var UserID = parseInt(sessionStorage.getItem('UserId'));
    var VmeetingId = parseInt(sessionStorage.getItem('VMId'));
    var totalBatchSize = 0;
    
    const handleOnChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );

        setCheckedState(updatedCheckedState);
        setNotSelected(updatedCheckedState.filter((x) => x === true).length<1);
    }

    async function clearMeetingData(Step, SubStep) {
        // Check if the user has selected this step
        if (Step <= checkedState.length && checkedState[Step - 1] === true) {
            // Check we know who we are
            if (VmeetingId && UserID) {
                const idata = {
                    VMId: VmeetingId,
                    UserID: UserID,
                    Step: Step,
                    SubStep: SubStep
                }
                //SignalRController.InvokeAdm2Att_ForceLogout("Meeting reset initiated");
                axios.post("VMDeleteMeetingData", idata).then(response => {
                    if (response.data) {
                        var resdata = response.data;
                        // Check deletion of that step was successful
                        if (typeof (resdata.statusID) !== 'undefined' && resdata.statusID === 1) {
                            var StatusInfo = typeof (resdata.statusDescription) === 'string' ? JSON.parse(resdata.statusDescription) : ['undefined'];
                            // Check if there is a SubStep we need to go down
                            if (typeof (StatusInfo.Finished) !== 'undefined' && StatusInfo.Finished === 0) {
                                // This step hasn't finished (i.e. it's doing batches) so run with the same parameters again until Finished!==0
                                totalBatchSize = totalBatchSize + StatusInfo.Batchsize;
                                addToLog(StatusInfo.Stage + " - processed " + totalBatchSize + " records", 1);
                                clearMeetingData(Step, SubStep);
                                return;
                            } else if (typeof (StatusInfo.NextSubStep) != 'undefined' && StatusInfo.NextSubStep > 0) {
                                if (totalBatchSize === 0) totalBatchSize = StatusInfo.Batchsize;
                                else totalBatchSize = totalBatchSize + StatusInfo.Batchsize;
                                addToLog(StatusInfo.Stage + " - processed " + totalBatchSize + " records");
                                totalBatchSize = 0;
                                clearMeetingData(Step, StatusInfo.NextSubStep);
                                return;
                            } else {
                                // No next Sub Step so increment Step instead if there is another one to check
                                if(totalBatchSize === 0) totalBatchSize=StatusInfo.Batchsize;
                                addToLog(StatusInfo.Stage + " - processed " + totalBatchSize + " records");
                                totalBatchSize = 0;
                                if (Step < checkedState.length) {
                                    clearMeetingData(Step + 1, 1);
                                    return;
                                }
                                // Ok finished .
                                addToLog('Reset Meeting Data process finished successfully!');
                                setStatusID(1);
                                setBusy(false);
                                //we want to move the SR call out of here into the controller somehow
                                SignalRController.InvokeAdm2All_VoteReset(0);
                                return;
                            }
                        } else {
                            console.error("Couldn't get StatusID");
                            return;
                        }
                    } else {
                        addToLog('Reset Meeting Data FAILED!');
                        setStatusID(0);
                        setBusy(false);
                        console.error("No response from Delete controller");
                        return;
                    };
                }).catch(error => {
                    setStatusID(0);
                    console.error("Error ", error);
                    return;
                });
                return;
            }
        } else {
            if (Step >= checkedState.length) {
                // Ok All finished so allow it to be run again now
                setBusy(false);
                return;
            }
            // Move to next step
            clearMeetingData(Step+1, 1);
            return
        }
        console.error("Unknown problem with the delete meeting data option");
        return 0;
    };

    function addToLog(logtxt,replaceLast) {
        const tmpList = replaceLast ? deleteResponse.map((x, index) => { if (index !== deleteResponse.length) return x; }) : deleteResponse;
        tmpList.push(logtxt);
        setDeleteResponse([...tmpList]);
    }

    async function clearLog() {
        await setDeleteResponse(deleteResponse.filter((x) => { return false }));
        setStatusID(-1);
    }

    function deleteCheckedItems() {
        clearLog();
        addToLog('Starting delete process');
        setBusy(true);
        totalBatchSize = 0;
        clearMeetingData(1, 1);
    };

    return (
        <div className="container-fluid">
            <div className="chat-heading-am">Reset meeting data</div> 
            <h2>WARNING! </h2>
            <p>Depending on the options selected below this will reset the meeting back to the beginning and clear all login, chat, contest results (except for pre-meeting vote data) & Quick Poll results. <br/>
            Configurations such as Contests, Content and Styling, Quick Polls and Documents will be retained including Pre-Meeting uploads</p>
            <p>All Attendees <strong>should be logged out</strong> before attempting this action</p>
            <hr />
            <h3> You will NOT be able to recover this data after taking this action</h3>
            <div className='deleteOptions'>
             <strong> Please select from the list below the items you wish to clear </strong>
            {DeleteOptions.map((deloption, index) =>
                <div key={index} className="deleteCheckbox">
                    <input type="checkbox"
                        id={`custom-checkbox-${index}`}
                        name={deloption.Step}
                        value={checkedState[index]}
                        onChange={() => handleOnChange(index)}
                        className="deleteCheckbox"
                        disabled={!props.isAuthorized}
                       /> 
                    <label>&nbsp;{deloption.Description}</label>
                </div>)}
            </div>

            <div className="row">
                <div className="col-8">
                    <div className="deleteDataAll">

                        <span className="clearData">CLEAR MEETING DATA</span>
                        {deleteResponse.length > 0 && <button style={{ marginLeft: "auto" }} disabled={busy || notSelected} className="btn btn-primary"
                            onClick={() => clearLog()}>
                            Clear Log</button>}
                        <button style={{ marginLeft: "auto" }} disabled={!props.isAuthorized || busy || notSelected} className="btn btn-primary"
                            onClick={() => swal({ title: "Are you sure?", text: "Once deleted, you will not be able to recover this data ", icon: "warning", buttons: true, dangerMode: true }).then((value) => { if (value) deleteCheckedItems() })}>
                            Wipe Data</button>

                    </div>
                    {busy && <span>Working......<div className="inlineloader"></div></span>}
                    {StatusID && StatusID == 1 ? <div> Meeting data cleared successfully </div> : null}
                    {StatusID && StatusID == 0 ? <div> Some problem in clearing meeting data  </div> : null}
                </div>
            </div>
            
            {deleteResponse.length > 0 && <div className='deleteOptions'>
                <strong>Progress log</strong>
                {deleteResponse.map((listItem, index) =>
                <div key={"li" + index} className="row">
                        {index + 1}.&nbsp;{listItem} {index === deleteResponse.length - 1 && busy === true ? <div className="inlineloader"></div> : ''}< br />
                </div>)}
                </div>
                }
        </div>
    );
}
