import React, { Component } from 'react';
import { Fragment } from 'react';
import { Bar } from 'react-chartjs-2';
import abstain from './abstain';
import AdminContext from './context/AdminContext';
import axios from 'axios';

export const getAggregateDataAPI = async (vmeetingId, contestId) => {
    var meetingContest = {
        VmeetingId: vmeetingId,
        ContestId: contestId
    }
    let responseData;
    await axios.post("VMGetAggregateData", meetingContest).then(response => {
        responseData = response.data;
    }).catch(() => console.log('Error getting aggregate data'))
    return responseData;
}

export function getByValue(arr, value) {
    for (var i = 0, iLen = arr?.length ?? 0; i < iLen; i++) {
        if (arr[i].voteValue === value) return arr[i];
    }
}

export class SingleResult extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chartinfo: this.props,
            chartData: null,
            chartOptions: null,
            aggregateData: '',
            loadingAggregateData: true
        }

        this.getOfficialVoteCount = this.getOfficialVoteCount.bind(this);
        this.getVoteCount = this.getVoteCount.bind(this);
        this.returnOfficialPercentage = this.returnOfficialPercentage.bind(this);
        this.createChartData = this.createChartData.bind(this);

    }

    componentDidMount() {
        this.createChartData(this.props.resultBlock);
        if (this.context.switches.hybrid && this.props.voteAdmin) {
            this.getAggregateData();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.carouselTrigger !== this.props.carouselTrigger || prevProps.resultBlock !== this.props.resultBlock)
            this.createChartData(this.props.resultBlock);
    }

    getAggregateData = async () => {
        let aggregateData = await getAggregateDataAPI(this.context.meetingId, this.props.contestId);
        this.setState({ aggregateData, loadingAggregateData: false })
    }

    getVoteCount(countem) {
        let n = 0
        countem.forEach(function (result) {
            if (!abstain(result.votingOption))
                n = n + result.voteResult;
        });
        return n;
    };

    getAbsoluteVoteWeight(countem) {
        let n = 0
        countem.forEach(function (result) {
            // PW Adding abstain
            if (!abstain(result.votingOption))
                n = n + result.weightedResult;
        });
        return n;
    }

    getOfficialVoteCount(countem) {
        let n = 0
        countem.forEach(function (result) {
            if (!abstain(result.votingOption)) {
                n = n + result.voteResult;
            }
        });
        return n;
    }

    getOfficialVoteWeight(countem) {
        let n = 0
        countem.forEach(function (result) {
            if (!abstain(result.votingOption)) {
                n = n + result.weightedResult;
            }
        });
        return n;
    }

    returnOfficialPercentage(value) {
        let base = this.getOfficialVoteCount(this.props.resultBlock);
        if (base === 0) {
            base = 1;
        }
        let result = (value / base) * 100;
        return result.toFixed(2).toLocaleString();
    }

    returnWeightedPercentage(value) {
        let base = this.getOfficialVoteWeight(this.props.resultBlock);
        if (base === 0) {
            base = 1;
        }
        let result = (value / base) * 100;
        return result.toFixed(2).toLocaleString();
    }

    returnAbsolutePercentage(value) {
        let base = this.getVoteCount(this.props.resultBlock);
        if (base === 0) {
            base = 1;
        }
        let result = (value / base) * 100;
        return result.toFixed(2).toLocaleString();
    }

    returnAbsoluteWeightedPercentage(value) {
        let base = this.getAbsoluteVoteWeight(this.props.resultBlock);
        if (base === 0) {
            base = 1;
        }
        let result = (value / base) * 100;
        return result.toFixed(2).toLocaleString();
    }

    returnLocalNumberString(value) {
        return value.toLocaleString();
    }

    createChartData(results) {
        var vr = [];
        var vw = [];
        var vl = [];
        var chartData;

        // Results with %
        results.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1).map((val) => {
            // Don't display abstain options in the charts as they are confusing - Phil W agreed with Martin Craig 10th March 2021
            if (!abstain(val.votingOption)) {
                vl = vl.concat(val.votingOption);
                vw = vw.concat(this.returnAbsoluteWeightedPercentage(val.weightedResult));
                vr = vr.concat(this.returnAbsolutePercentage(val.voteResult));
            }
        });

        var chartData1 = {
            labels: vl,
            datasets: [
                {
                    label: 'Count %',
                    hoverBorderColor: 'rgba(100,100,100,.5)',
                    borderWidth: 1,
                    scaleOverride: true,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    hoverBackgroundColor: [
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(54, 162, 235, 0.5)',
                        'rgba(255, 206, 86, 0.5)',
                        'rgba(75, 192, 192, 0.5)',
                        'rgba(153, 102, 255, 0.5)',
                        'rgba(255, 159, 64, 0.5)',
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(54, 162, 235, 0.5)',
                        'rgba(255, 206, 86, 0.5)',
                        'rgba(75, 192, 192, 0.5)',
                        'rgba(153, 102, 255, 0.5)',
                        'rgba(255, 159, 64, 0.5)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    data: vr
                }]
        };
        var chartData2 = {
            labels: vl,
            datasets: [
                {
                    label: 'Weighted %',
                    hoverBorderColor: 'rgba(100,100,100,.5)',
                    borderWidth: 1,
                    scaleOverride: true,
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(153, 102, 255, 0.2)',
                        'rgba(255, 159, 64, 0.2)'
                    ],
                    hoverBackgroundColor: [
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(54, 162, 235, 0.5)',
                        'rgba(255, 206, 86, 0.5)',
                        'rgba(75, 192, 192, 0.5)',
                        'rgba(153, 102, 255, 0.5)',
                        'rgba(255, 159, 64, 0.5)',
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(54, 162, 235, 0.5)',
                        'rgba(255, 206, 86, 0.5)',
                        'rgba(75, 192, 192, 0.5)',
                        'rgba(153, 102, 255, 0.5)',
                        'rgba(255, 159, 64, 0.5)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(153, 102, 255, 1)',
                        'rgba(255, 159, 64, 1)'
                    ],
                    data: vw
                }]
        };
        var chartData3 = {
            labels: vl,
            datasets: [
                {
                    label: 'Count %',
                    hoverBorderColor: 'rgba(100,100,100,.5)',
                    borderWidth: 1,
                    scaleOverride: true,
                    backgroundColor: 'rgba(255, 99, 132, 0.2)',
                    hoverBackgroundColor: 'rgba(255, 99, 132, 0.1)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    data: vr
                },
                {
                    label: 'Weighted %',
                    hoverBorderColor: 'rgba(100,100,100,.5)',
                    borderWidth: 1,
                    scaleOverride: true,
                    backgroundColor: 'rgba(54, 162, 235, 0.5)',
                    hoverBackgroundColor: 'rgba(54, 162, 235, 1)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    data: vw
                }]
        };
        switch (this.props.publishTypeId) {
            case 1:
                chartData = chartData1;
                break;
            case 2:
                chartData = chartData2;
                break;
            case 3:
                chartData = chartData3;
        }

        var chartOptions = {
            maintainAspectRatio: false,
            fill: false,
            layout: {
                padding: {
                    left: 0, right: 0, top: -5, bottom: 0
                }
            },
            Title: {
                display: false
            },
            legend: {
                display: this.props.publishTypeId === 3 ? true : false,
                position: 'right'
            },
            legendCallback: chart => {
                let html = '<ul>';
                chart.data.datasets.forEach((ds, i) => {
                    html += '<li>' +
                        '<span style="width: 36px; height: 14px; background-color:' + ds.backgroundColor + '; border:' + ds.borderWidth + 'px solid ' + ds.borderColor + '" onclick="onLegendClicked(event, \'' + i + '\')">&nbsp;</span>' +
                        '<span id="legend-label-' + i + '" onclick="onLegendClicked(event, \'' + i + '\')">' +
                        (Array.isArray(ds.label) ? ds.label.join('<br/>') : ds.label) + '</span>' +
                        '</li>';
                });
                return html + '</ul>';
            },
            scales: {
                yAxes: [{
                    gridLines: {
                        display: false,
                        drawBorder: false
                    },
                    ticks: {
                        beginAtZero: true,
                        display: true,
                        min: 0,
                        max: 100,
                        stepSize: 20,
                        callback: function (value, index, values) {
                            if (Math.floor(value) === value) {
                                return value + '%';
                            }
                        }
                    }
                }],
                xAxes: [{
                    ticks: {
                        beginAtZero: true,
                        display: true,
                    },
                    gridLines: {
                        display: true
                    }
                }]

            }
        };


        this.setState({
            chartData: chartData,
            chartOptions: chartOptions,
            resultBlock: this.props.resultBlock,
            carouselTrigger: this.props.carouselTrigger
        })
    }



    render() {
        //const barChartRef = useRef(null);
        let isHybrid = this.context.switches.hybrid && this.props.voteAdmin;
        let results = this.state.resultBlock ;

        return (
            this.state.resultBlock ?
                <Fragment>
                    <div id="openResDashboardb" key={this.props.carouselTrigger}>

                        <div className="row">
                            <div className="col">
                                {/*<div className="cloudGrid">*/}
                                <table className="table table-fit table-bordered">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Option</th>
                                            {(this.props.publishTypeId === 1 || this.props.publishTypeId === 3) &&
                                                <Fragment>
                                                    {isHybrid && <th scope="col">joinIN</th>}
                                                    {isHybrid && <th scope="col">Aggregate</th>}
                                                    <th scope="col">Total</th>
                                                    <th scope="col">Total %</th>
                                                </Fragment>
                                            }
                                            {(this.props.publishTypeId === 2 || this.props.publishTypeId === 3) &&
                                                <Fragment>
                                                    <th scope="col">Weight</th>
                                                    <th scope="col">Weight %</th>
                                                </Fragment>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody >
                                        {results.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1).map((result) => {
                                            let aggValue;
                                            if (isHybrid) {
                                                aggValue = getByValue(this.state.aggregateData, result.votingValue);
                                            }
                                            return (
                                                <tr key={result.votingValue}>
                                                    <td>{abstain(result.votingOption) ?
                                                        <Fragment>({result.votingOption})</Fragment>
                                                        : <Fragment>{result.votingOption}</Fragment>
                                                    }</td>
                                                    {(this.props.publishTypeId === 1 || this.props.publishTypeId === 3) &&
                                                        <Fragment>
                                                            {isHybrid && <Fragment><td>{this.state.loadingAggregateData ? '--': result.voteResult - (aggValue?.voteResult ?? 0)}</td>
                                                                <td>{aggValue?.voteResult ?? '--'}</td></Fragment>}  
                                                            <td>{abstain(result.votingOption) ?
                                                                <Fragment>({this.returnLocalNumberString(result.voteResult)})</Fragment>
                                                                : <Fragment> {this.returnLocalNumberString(result.voteResult)}</Fragment>
                                                            }</td>                                                                                                                      
                                                            <td>{abstain(result.votingOption) ? '' :
                                                                <Fragment>
                                                                    {this.returnOfficialPercentage(result.voteResult)}%
                                                                </Fragment>
                                                            }</td>
                                                        </Fragment>
                                                    }
                                                    {(this.props.publishTypeId === 2 || this.props.publishTypeId === 3) &&
                                                        <Fragment>
                                                            <td>{abstain(result.votingOption) ?
                                                                <Fragment>({this.returnLocalNumberString(result.weightedResult)})</Fragment>
                                                                : <Fragment> {this.returnLocalNumberString(result.weightedResult)}</Fragment>
                                                            }</td>
                                                            <td>{!abstain(result.votingOption) &&
                                                                <Fragment>
                                                                    {this.returnWeightedPercentage(result.weightedResult)}%
                                                                </Fragment>
                                                            }</td>
                                                        </Fragment>
                                                    }
                                                </tr>
                                            )
                                        }
                                        )}
                                    </tbody>
                                </table>
                                {/*</div>*/}
                            </div>
                            <div className="col" >
                                <div className="barChartRef">
                                    {this.state.chartData ? <Bar
                                        width={300}
                                        height={170}
                                        data={this.state.chartData}
                                        options={this.state.chartOptions}
                                    /> : <span>Loading..</span>}
                                </div>
                            </div>
                        </div>

                    </div>
                </Fragment> : null
        )
    }
};

export default SingleResult;
SingleResult.contextType = AdminContext;
