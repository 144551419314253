import React, { Component } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import Moment from 'react-moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import AdminContext from './context/AdminContext';

export class AdminAttendance extends Component {
    constructor(props) {
        super(props);
        this.config = {
            page_size: 20,
            length_menu: [20, 10, 50],
            no_data_text: 'No users found',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Search users...",
                info: "Showing _START_ to _END_ of _TOTAL_ users",
                pagination: {
                    first: "First",
                    previous: <span>&#9668;</span>,
                    next: <span>&#9658;</span>,
                    last: "Last"
                },
                loading_text: "Please be patient while data loads.."
            }
        };
    }

    getColumns = () => {
        var columns = [
            {
                key: "name",
                text: "Name",
                align: "center",
                sortable: true
            },
            {
                key: "role",
                text: "Permission Level",
                align: "center",
                sortable: true
            },
            {
                key: "lastLogin",
                text: "Last Seen",
                align: "center",
                sortable: true,
                cell: row => {
                    return (
                        row.lastLogin ? <Moment format="D MMM HH:mm:ss" date={row.lastLogin} /> : "Never"
                    )
                }
            },
            {
                key: "online",
                text: "Online",
                align: "center",
                sortable: true,
                cell: row => {
                    return (
                        row.online === 1 ? <span style={{ color: "lightgreen" }}> <FontAwesomeIcon icon={faCircle} /> </span> :
                            row.lastLogin ? <span style={{ color: "red" }}> <FontAwesomeIcon icon={faCircle} /> </span> :
                                <span style={{ color: "darkgray" }}> <FontAwesomeIcon icon={faCircle} /> </span>
                    )
                }
            }
        ];
        return columns.filter(value => Object.keys(value).length !== 0);
    }

    render() {
            return (
                <div data-testid="reactdatatable" className="attendee-lists hMargin">
                    <ReactDatatable className="table small table-hover sortable"
                            config={this.config}
                            columns={this.getColumns()}
                            records={this.props.AdminList}
                            loading={this.props.AdminList}
                            />
                </div>
                )
    }
}

AdminAttendance.contextType = AdminContext;