import { Fragment } from "react";
import { useHistory } from "react-router-dom";

export const BackButton = (props) => {
    let history = useHistory();
    return (
        <Fragment>
            <button className="btn btn-secondary btn-sm me-1" onClick={() => history.goBack()}>Back</button>
        </Fragment>
    );
};