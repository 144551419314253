import React, { useState, useEffect, useContext, Fragment } from 'react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import arrayMove from 'array-move';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { ResCard } from './ResCard'
import AdminContext from '../../context/AdminContext';

const SortableContainer = sortableContainer(({ children }) => {
    return (
        <ul className="VoteOptions nodots">
            {children}
        </ul>
    )
});

const ResOptions = props => {
    const AdmnContext = useContext(AdminContext);
    const [inputList, setInputList] = useState((props.options).sort(function (a, b) { return a.voteDisplayOrder - b.voteDisplayOrder }));
    const [sortable, setSortable] = useState(false);
    const { isAuthorized } = AdmnContext



    useEffect(() => {
        let list = [];
        if (inputList.length > 0) {
            setInputList(inputList.sort(function (a, b) { return a.voteDisplayOrder - b.voteDisplayOrder }));
            inputList.map((i) => {
                if (i !== undefined) {
                    list.push({ id: i.voteOptionId, voteOption: i.voteOption, voteDisplayOrder: i.voteDisplayOrder, voteValue: i.voteValue, voteResult: i.voteResult })
                }
            });
        }
    }, []);


    const handleAddButton = (e) => {
        e.preventDefault();
        let newVoteDisplayOrder = 0;
        let newVoteValue = 0;
        if (inputList && inputList.length > 0) {
            let maxVoteDisplayOrder = inputList.reduce((p, c) => p.voteDisplayOrder > c.voteDisplayOrder ? p : c);
            newVoteDisplayOrder = parseInt(maxVoteDisplayOrder.voteDisplayOrder) + 1;
            let maxVoteValue = inputList.reduce((p, c) => p.voteValue > c.voteValue ? p : c);
            newVoteValue = parseInt(maxVoteValue.voteValue) + 1;
        }


        const newValue = [{ voteOptionId: -1, voteDisplayOrder: newVoteDisplayOrder, voteValue: newVoteValue, voteOption: '', voteResult: 0 }];
        setInputList(inputList.concat(newValue))
        let list = inputList.concat(newValue);
        props.Changed(list);


    }

    const handleRemoveButton = (e, index, voteOptionId) => {
        if (voteOptionId === -1) {
            let list = [...inputList];
            list.splice(index, 1);
            setInputList(list);
            props.Changed(list);
        }
        else {
            let list = [...inputList];
            //api call
            const deleteContestOption = {
                ContestResolutionVotingOptionId: list[index].voteOptionId,
                VMeetingId: AdmnContext.meetingId,
                UserId: AdmnContext.userId
            };
            const options = {
                url: 'VMDeleteMeetingContestOption',
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                data: deleteContestOption
            };
            axios(options)
                .then(response => {
                    if (response.data.statusDescription === 'Success') {
                        NotificationManager.success("Contest option deleted", "Delete contest option", 2000);
                    } else {
                        NotificationManager.error("Contest option delete failed", "Delete contest option", 2000);
                    }
                })

            list.splice(index, 1);
            setInputList(list);
        }
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        var newList = arrayMove(inputList, oldIndex, newIndex);
        newList.map((item, i) => {
            let itemToEdit = item;
            itemToEdit['voteDisplayOrder'] = i + 1;
            return itemToEdit;
        });
        setInputList(newList)
        props.Changed(newList);
    };

    function updateFromChild(index, newData) {
        var list = inputList.map((cc, i) => i === index ? newData : cc);
        setInputList(list);
        props.Changed(list);
    }

    if (sortable) {
        return (
            <Fragment>
                <div className="d-flex justify-content-between">
                    <div>Item options</div>
                    <button className="btn btn-sm btn-secondary" type="button" onClick={() => setSortable(!sortable)}>Enable edit</button>
                </div>
                <ul className="VoteOptions">
                    {inputList.length > 0 &&
                        <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                            {
                                inputList.map((x, i) => {
                                    const SortableItem = sortableElement((x) =>
                                        <li key={i} className="candidateList" >
                                            <ResCard Index={i}
                                                Data={x.value}
                                                Update={updateFromChild}
                                                DeleteResOption={handleRemoveButton}
                                                OptionCount={props.OptionCount}
                                                Sortable={sortable}
                                            />
                                        </li>
                                    )
                                    return (<SortableItem key={i} index={i} value={x} />)
                                })
                            }
                        </SortableContainer>
                    }
                    <button disabled={!isAuthorized('VoteItems','Write')} className="btn btn-dark" onClick={(e) => handleAddButton(e)}>Add Additional Item Option</button>
                </ul>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                <div className="d-flex justify-content-between">
                    <div>Item options</div>
                    {!props.disabled &&
                        <button className="btn btn-sm btn-secondary" type="button" onClick={() => setSortable(!sortable)}>Enable sort</button>
                    }
                </div>
                <ul className="VoteOptions">
                    {inputList.length > 0 &&
                        inputList.map((x,i) => {
                            return <li key={i} className="candidateList" >
                                <ResCard Index={i}
                                    Data={x}
                                    Update={updateFromChild}
                                    DeleteResOption={handleRemoveButton}
                                    OptionCount={props.OptionCount}
                                    Sortable={sortable}
                                    disabled={props.disabled}
                                />
                            </li>
                        })
                    }
                    {!props.disabled &&
                        <button disabled={!isAuthorized('VoteItems', 'Write')} className="btn btn-dark" onClick={(e) => handleAddButton(e)}>Add Additional Item Option</button>
                    }
                </ul>
            </Fragment>
        );
    }

}



export default ResOptions;
