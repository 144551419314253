import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { SketchPicker } from 'react-color';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import Files from 'react-files'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons'
import TextEditor from '../Common/TextEditor';
import '../Picker.less';
import AdminContext from '../context/AdminContext';
import { HelpButton } from './HelpButton';
import { NotificationManager } from 'react-notifications';


export class ContentAndStyling extends Component {

    constructor(props) {
        super(props);
        this.userId = parseInt(sessionStorage.getItem('UserId'));
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.state = {
            meetingData: [],
            loading: true,
            isSubmitting: false,
            isError: false,
            message: '',
            background: '#3b8896',
            meetingTitle: 'CESjoinIN Meeting',
            welText: '<p>Welcome to your generic holding text</p>',
            welTextInRoom: '<p>Welcome to your generic in room holding text</p>',
            file: null,
            image: "",
            LogoName: null,
            files: [],
            errors: {},
            successMsg: '',
            doc: [],
            items: [],
            tabOrder: 0,
            roles: [],
            todos: [],
            EnableSecondaryFeed: false,
            PrimaryFeed: '',
            SecondaryFeed: '',
            startDateTime: new Date(),
            closeDateTime: new Date(),
            fileName: '',
            fileType: '',
            contentType: '',
            errorMessage: '',
            fileSize: 0,
            showLogoHelp: false,
            inRoomMessage: false,
            backgroundImageFile: null,
            backgroudnImageError: "",
            backgroundImages: [],
            backgroundImagePreview: ""
        };

    }

    componentDidMount() {
        this.populateMeetingData();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    handleChangeComplete = (color) => {
        this.setState({ background: color.hex });
    };


    ValidateVMDetails = () => {
        let errors = {};
        let formIsValid = true;

        if (!this.state.meetingTitle) {
            formIsValid = false;
            errors["Title"] = "*Please enter meeting title.";
        }
        if (this.state.meetingTitle.trim().length === 0) {
            formIsValid = false;
            errors["Title"] = "*Please enter meeting title.";
        }
        if (this.state.welText === "") {
            formIsValid = false;
            if (this.context.switches.inRoomDifferentWelcome) {
                errors["welText"] = "*Please enter remote attendee welcome text.";
            } else {
                errors["welText"] = "*Please enter welcome text.";
            }
        }
        if (this.context.switches.inRoomDifferentWelcome && this.state.welTextInRoom === "") {
            formIsValid = false;
            errors["welTextInRoom"] = "*Please enter in room welcome text.";
        }
        if (this.background.state.hex === "" || this.background.state.hex === "#000000") {
            formIsValid = false;
            errors["background"] = "*Please choose background colour.";
        }
        if (this.state.file == null && (this.state.LogoName === null || this.state.LogoName === "")) {
            formIsValid = false;
            errors["LogoName"] = "*Please choose logo.";
        }


        this.setState({ errors: errors });
        return formIsValid;
    }

    SaveMeetingDetails = (e) => {
        e.preventDefault();

        if (this.ValidateVMDetails() && this.userId > 0 && this.VmeetingId > 0) {
            const formData = new FormData();
            formData.append("VMId", this.VmeetingId);
            formData.append("UserId", this.userId);
            formData.append("MeetingTitle", this.state.meetingTitle);
            formData.append("WelcomeMessage", this.state.welText);
            formData.append("WelcomeMessageInRoom", this.state.welTextInRoom);
            formData.append("PrimaryBrandColour", this.background.state.hex);

            if (this.state.file != null) {
                formData.append("LogoFileName", this.state.file.name);
                formData.append("LogoFile", this.state.file);
            }
            else {
                formData.append("LogoFileName", this.state.LogoName);
                formData.append("LogoFile", null);
            }
            if (this.state.backgroundImageFile != null) {
                formData.append("BackgroundImage", this.state.backgroundImageFile);
            }
            else {
                formData.append("BackgroundImage", null);
            }
            const headers1 = { "Content-Type": "multipart/form-data" };
            axios.post('VMSetTitlewelcometext', formData, { headers: headers1 }).then(response => {
                const resdata = response;
                if (response.status === 202) {
                    let errors = {};
                    if (response.data === "InvalidFile") {
                        errors[response.data] = "*Please upload a image file with file extensions are jpg, jpeg, gif, png."

                    }
                    this.setState({
                        errors: errors
                    })
                } else if (response.status === 200) {
                    this.setState({
                        meetingData: resdata.data,
                        loading: false,
                        background: resdata.data.primaryBrandColour,
                        welText: resdata.data.welcomeMessage,
                        image: "data:image/png;base64," + resdata.data.logoFile,
                        LogoName: resdata.data.logoFileName,
                        PrimaryFeed: resdata.data.primaryFeed,
                        SecondaryFeed: resdata.data.secondaryFeed,
                        EnableSecondaryFeed: resdata.data.enableSecondaryFeed,
                        startDateTime: new Date(resdata.data.startDateTime),
                        closeDateTime: new Date(resdata.data.closeDateTime),
                        errorMessage: "",
                        backgroudnImageError: ""
                    });
                    NotificationManager.success("Update successful", "Content and Styling", 2000);
                    this.refs.files.removeFiles();
                    this.refs.backgroundImages.removeFiles();
                    this.setState({ file: null, backgroundImageFile: null });
                    this.populateMeetingData();
                }
            });


            this.setState({ isSubmitting: true });
        }

    }

    onFilesChange = (files) => {
        this.setState({
            files,
            file: files[0],
            errors: { LogoName: '' }
        });
    }

    onFilesError = (error, file) => {
        this.setState({ errorMessage: error.message });
    }

    filesRemoveAll = () => {
        this.refs.files.removeFiles();
        this.setState({ file: null });

    }


    onBackgroundFileChange = (files) => {
        this.setState({
            backgroundImages: files,
            backgroundImageFile: files[0]
        })
    }


    onBackgroundImageError = (error, file) => {
        this.setState({ backgroudnImageError: error.message });
    }
    removeBackgroundImage = () => {
        this.setState({ backgroundImageFile: null });
        this.refs.backgroundImages.removeFiles()
    }

    render() {
        const { isAuthorized } = this.context;
        return (
            <Container fluid>
                <div className="row">
                    <div name="Logo and colour" className='col-md-3'>
                        <div className="row">
                            <div className="col-sm-7">
                                <div><h5>Logo: <HelpButton helpText="The logo has a maximum display size of 250px wide, 50px high, and a maximum file size of 30kB" /></h5></div>
                                <div className="files">
                                    <Files
                                        ref='files'
                                        accepts={['image/*']}
                                        className='files-dropzone-list'
                                        style={{ height: '100px', display: 'table-cell', padding: '10px', border: '1px dashed #D3D3D3', cursor: 'pointer' }}
                                        onChange={this.onFilesChange}
                                        onError={this.onFilesError}
                                        multiple maxFiles={1}
                                        maxFileSize={30000}
                                        minFileSize={1}
                                        clickable

                                    >
                                        <span className="uploadIcon"><FontAwesomeIcon icon={faCloudUploadAlt} size="5x" /></span> <br />Drag and drop or click to upload</Files> <div className="errorMsg">{this.state.errors.LogoName}</div>

                                    {
                                        this.state.files.length > 0
                                        && <div className='files-list'>
                                            <ul>{this.state.files.map((file) =>
                                                <li className='files-list-item' key={file.id}>
                                                    <div className='files-list-item-preview'>
                                                        <img className='files-list-item-preview-image' alt="preview" style={{ width: '200px', height: '120px' }} src={file.preview.url} />
                                                        <div className='files-list-item-content-item files-list-item-content-item-1'>{file.name}</div>
                                                        <div className='files-list-item-content-item files-list-item-content-item-2'>{file.sizeReadable}</div>
                                                        <button onClick={this.filesRemoveAll}>Remove </button>

                                                    </div>
                                                </li>
                                            )}</ul>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="col-sm">
                                <div><h5>Preview:</h5></div>
                                <div className="logoPrev">
                                    {this.state.image != null ?
                                        <img src={this.state.image} alt={this.state.LogoName} id="imgLogo" className="img-fluid" style={{ width: '115px' }}></img>
                                        : null}
                                </div>
                            </div>
                            <div className="errorMsg" >{this.state.errorMessage ? this.state.errorMessage : null}</div>
                        </div>
                        <div className="row">
                            <div title="" className="col-sm-7">
                                <label>
                                    <h5>Background Image:<HelpButton helpText="The background image should be 1920px wide, 1080px high, and a maximum file size of 60kB" /></h5>
                                </label>
                                <div className="files">
                                    <Files
                                        ref="backgroundImages"
                                        accepts={['image/*']}
                                        className='files-dropzone-list'
                                        style={{ height: '100px', display: 'table-cell', padding: '10px', border: '1px dashed #D3D3D3', cursor: 'pointer' }}
                                        onChange={this.onBackgroundFileChange}
                                        onError={this.onBackgroundImageError}
                                        maxFiles={1}
                                        maxFileSize={60000}
                                        minFileSize={1}
                                        multiple={false}
                                        clickable
                                    ><span className="uploadIcon">
                                            <FontAwesomeIcon icon={faCloudUploadAlt} size="5x" />
                                        </span> <br />Drag and drop or click to upload
                                    </Files>
                                    {
                                        this.state.backgroundImages.length > 0
                                        &&
                                        <div className='files-list'>
                                            <ul>{this.state.backgroundImages.map((file) =>
                                                <li className='files-list-item' key={file.id}>
                                                    <div className='files-list-item-preview'>
                                                        <img className='files-list-item-preview-image' alt="preview" style={{ width: '200px', height: '80px' }} src={file.preview.url} />
                                                        <button onClick={this.removeBackgroundImage}>Remove </button>

                                                    </div>
                                                </li>
                                            )}</ul>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div className="col-sm">
                                <div><h5>Preview:</h5></div>
                                <div className="logoPrev">
                                    {this.state.backgroundImagePreview != null ?
                                        <img alt="preview background" src={this.state.backgroundImagePreview} id="backgroundImagePreview" className="img-fluid" style={{ width: '115px' }}></img>
                                        : null}
                                </div>
                            </div>
                            <div className="errorMsg" >{this.state.backgroudnImageError ? this.state.backgroudnImageError : null}</div>
                        </div>

                        <div className="errorMsg" >{this.state.errors.background}</div>
                        <h5>Primary brand colour:</h5>
                        <label>
                            <SketchPicker color={this.state.background} onChangeComplete={this.handleChangeComplete} ref={input => this.background = input} />
                        </label>
                    </div>


                    <div name="WelMsg" className='WelMsg col-md-9'>
                        <label>
                            <h5>Meeting Title:</h5>
                            <input type="text" className="form-control" name="MeetingTitle" id="txtMeetintTitle" value={this.state.meetingTitle} onChange={(e) => this.setState({ meetingTitle: e.target.value })} />
                        </label>
                        <div className="errorMsg">{this.state.errors.Title}</div>


                        <Tabs defaultIndex={this.state.inRoomMessage ? 1 : 0} onSelect={(index, lastindex, event) => this.setState({ inRoomMessage: index === 1 })} >
                            <TabList>
                                {this.context.switches.hybrid && this.context.switches.inRoomDifferentWelcome && <Tab>In-Room Message</Tab>}
                                <Tab>{this.context.switches.hybrid && this.context.switches.inRoomDifferentWelcome ? 'Remote' : ''} Message</Tab>
                            </TabList>
                            {this.context.switches.hybrid && this.context.switches.inRoomDifferentWelcome && <TabPanel>
                                <div key={1}>
                                    <label htmlFor="welcomeTextInRoom">
                                        <h5>In Room Welcome Message:</h5>
                                    </label>
                                    {!this.state.loading &&
                                        <TextEditor
                                            name="welcomeTextInRoom"
                                            id="welcomeTextInRoom"
                                            value={this.state.welTextInRoom}
                                            onChange={(e) => {
                                                this.setState({
                                                    welTextInRoom: e.html
                                                });
                                            }}
                                            className="form-control input"
                                            maxLength={25000}
                                        />
                                    }
                                </div>
                            </TabPanel>}
                            <TabPanel>
                                <div key={2}>
                                    <label htmlFor="welcomeText">
                                        <h5>{this.context.switches.hybrid && this.context.switches.inRoomDifferentWelcome ? 'Remote' : ''} Welcome Message:</h5>
                                    </label>
                                    {!this.state.loading &&
                                        <TextEditor
                                            name="welcomeText"
                                            id="welcomeText"
                                            value={this.state.welText}
                                            onChange={(e) => {
                                                this.setState({
                                                    welText: e.html
                                                });
                                            }}
                                            className="form-control input"
                                            maxLength={25000}
                                        />
                                    }
                                </div>

                            </TabPanel>
                        </Tabs>

                        <div className="errorMsg">{this.state.errors.welText}</div>


                    </div>


                    <div className="d-flex justify-content-end mb-3">
                        <div>
                            <button disabled={!isAuthorized('Styling', 'Write')} className="btn btn-primary" onClick={this.SaveMeetingDetails}>Publish</button>
                        </div>
                    </div>
                </div>

            </Container>
        );
    }

    populateMeetingData = async () => {
        if (parseInt(sessionStorage.getItem('VMId')) > 0) {
            await axios.get("VMGetTitleWelcomeText", { params: { VMId: sessionStorage.getItem('VMId') } }).then(response => {
                const data = response.data;
                if (data !== null && data !== "") {
                    this.setState({
                        meetingData: data,
                        meetingTitle: data.meetingTitle,
                        loading: false,
                        background: data.primaryBrandColour ?? '#3b8896',
                        welText: data.welcomeMessage,
                        welTextInRoom: data.welcomeMessageInRoom,
                        image: data.logoFile != null ? "data:image/jpg;base64," + data.logoFile : null,
                        LogoName: data.logoFileName,
                        PrimaryFeed: data.primaryFeed,
                        SecondaryFeed: data.secondaryFeed,
                        EnableSecondaryFeed: data.enableSecondaryFeed,
                        startDateTime: new Date(data.startDateTime),
                        closeDateTime: new Date(data.closeDateTime),
                        backgroundImagePreview: data.backgroundImage != null ? "data:image/png;base64," + data.backgroundImage : null,
                    });
                }
            });
        }
    }
}
ContentAndStyling.contextType = AdminContext;