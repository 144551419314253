import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faPause, faSync, faChartPie, faTrash } from '@fortawesome/free-solid-svg-icons'
import { Row, Col } from "react-bootstrap"
import SignalRController from '../SignalRController';
import { Bar, Doughnut, HorizontalBar, Line } from 'react-chartjs-2';
import axios from 'axios';
import './QuickPollAdmin.css';
import swal from 'sweetalert'

export default function QuickPollCard(props) {
    const [trendChartVisible, setTrendChartVisible] = useState(false);
    const [trendData, setTrendData] = useState(null);
    const [chartData, setChartData] = useState([]);
    const [chartOptions, setChartOptions] = useState([]);
    const [smallChartData, setSmallChartData] = useState([]);
    const [smallChartOptions, setSmallChartOptions] = useState([]);
    const [pollResults, setPollResults] = useState([]);
    const [pollLabels, setPollLabels] = useState([]);
    const [pollPercentages, setPollPercentages] = useState([]);
    const [weHaveData, setWeHaveData] = useState(false);

    const btnPlay = 1;
    const btnPause = 2;
    const btnPublish = 3;
    const btnReset = 4;
    const btnDelete = 5;
    let poll = props.poll;
    const options = [1, 2, 3, 4, 5];

    const backgroundColor = [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
    ];

    const hoverBackgroundColor = [
        'rgba(255, 99, 132, 0.5)',
        'rgba(54, 162, 235, 0.5)',
        'rgba(255, 206, 86, 0.5)',
        'rgba(75, 192, 192, 0.5)',
        'rgba(153, 102, 255, 0.5)',
    ]

    const borderColor = [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)',
    ];

    function controlPollCard(selection) {
        axios.post(`VMControlQuickPoll`, {
            vMeetingId: poll.vMeetingId,
            UserId: 1,
            PollId: poll.pollId,
            Control: selection
        }).then(res => {
            //var data = res.data;
            if (typeof (props.refresh) == 'function') props.refresh();
            SignalRController.InvokeAdm2Att_QuickPollUpdate(props.vMeetingId, selection);
            SignalRController.InvokeAdm2Adm_QuickPollUpdate(props.vMeetingId, selection);
        })
    }

    function setupCharts() {
        var vr = [];
        var vl = [];
        var vp = [];
        var tmpChartData = [];
        var tmpChartOptions = [];

        if (trendChartVisible === false) {

            var total4Percentage = 0;
            options.forEach((i) => poll['count' + i] ? total4Percentage += poll['count' + i] : null);
            if (total4Percentage > 0)
                options.forEach((i) => poll['count' + i] && poll['count' + i] > 0 ? vp.push(Math.round((poll['count' + i] / total4Percentage) * 10000) / 100) : vp.push(0))
            else
                options.forEach((i) => vp.push(0));

            options.forEach((i) => poll['option' + i] ? vl.push(poll['option' + i].trim()) : null);
            options.forEach((i) => poll['count' + i] ? vr.push(poll['count' + i]) : vr.push(0));

            tmpChartData = {
                labels: vl,
                datasets: [
                    {
                        label: 'Quick Poll',
                        hoverBorderColor: 'rgba(100,100,100,.5)',
                        borderWidth: 1,
                        scaleOverride: true,
                        backgroundColor: backgroundColor,
                        hoverBackgroundColor: hoverBackgroundColor,
                        borderColor: borderColor,
                        data: vr
                    }]
            };
            tmpChartOptions = {
                maintainAspectRatio: false,
                fill: false,
                layout: {
                    padding: {
                        left: 0, right: 0, top: 0, bottom: 0
                    }
                },
                legend: {
                    display: poll.defaultChart === 1 ? true : false,
                    position: 'left'
                }, scales: {
                    yAxes: [{
                        ticks: {
                            display: poll.defaultChart === 1 ? false : true,
                            min: 0,
                            callback: function (value, index, values) {
                                if (Math.floor(value) === value || typeof (value) !== "number") {
                                    return value;
                                }
                            },
                            precision: 0,
                            suggestedMin: 0,
                            beginAtZero: true
                        },
                        gridLines: {
                            display: false
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            display: poll.defaultChart === 1 ? false : true,
                            beginAtZero: true,
                            min: 0,
                            callback: function (value, index, values) {
                                if (Math.floor(value) === value || typeof (value) !== "number") {
                                    return value;
                                }
                            }
                        },
                        gridLines: {
                            display: poll.defaultChart === 1 ? false : true
                        }
                    }
                    ]
                }
            };


            setPollResults(vr);
            setPollLabels(vl);
            setPollPercentages(vp);

            setSmallChartData(tmpChartData);
            setSmallChartOptions(tmpChartOptions);
        }
        else {
            var datasets = [];

            trendData && trendData.options.map((option, i) => {
                if (option.label != null && option.label.trim() !== '')
                    datasets = datasets.concat({
                        label: option.label,
                        fill: true,
                        hoverBorderColor: 'rgba(100,100,100,.5)',
                        borderWidth: 1,
                        scaleOverride: false,
                        backgroundColor: backgroundColor[i],
                        hoverBackgroundColor: hoverBackgroundColor[i],
                        borderColor: borderColor[i],
                        data: option.count.map((c, ii) => { return ({ t: trendData.publishedTime[ii], y: c }) })
                    })
            })

            tmpChartData = {
                labels: trendData.publishedTime,
                datasets: datasets
            };

            tmpChartOptions = {
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 0, right: 0, top: 0, bottom: 0
                    }
                },
                legend: {
                    display: true
                }, scales: {
                    yAxes: [{
                        ticks: {
                            display: true,
                            min: 0,
                            steps: 1,
                            precision: 0,
                            suggestedMin: 0,
                            beginAtZero: false
                        },
                        gridLines: {
                            display: false
                        },
                        plugins: {
                            datalabels: {
                                anchor: 'end',
                                align: 'top',
                                formatter: Math.round,
                                font: {
                                    weight: 'bold'
                                }
                            }
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            display: true,
                            beginAtZero: true,
                            min: 0,
                        },
                        type: 'time'
                    }]
                }
            };
            setChartData(tmpChartData);
            setChartOptions(tmpChartOptions);
        }

        if (poll.count1 > 0 || poll.count2 > 0 || poll.count3 > 0 || poll.count4 > 0 || poll.count5 > 0)
            setWeHaveData(true);
    }

    useEffect(() => {
        setupCharts();
    }, [])

    useEffect(() => {
        setupCharts();
        return (() => {
            // Empty return as this is just to load data and configure the data
        })
    }, [props, trendData, trendChartVisible]);

    function toggleTrend() {
        if (!trendChartVisible) {
            axios.post(`VMGetQuickPollChartData`, {
                vMeetingId: poll.vMeetingId,
                UserId: 1,
                PollId: poll.pollId,
            }).then(res => {
                setTrendData(res.data);
                setTrendChartVisible(true);
            })
        } else {
            setTrendData(null);
            setTrendChartVisible(false);
        }
    }
    var totalPollCounts = poll.count1 + poll.count2 + poll.count3 + poll.count4 + poll.count5;

    return (
        <Col className={trendChartVisible ? poll.pollActive === 1 ? "quickActivePollCard expanded" : "quickPollCard expanded" : poll.pollActive === 1 ? "quickActivePollCard" : "quickPollCard"} >
            <Row key={poll.pollId} className={props.presenter ? "limitPollPresenter" : "limitPollAdmin"}>
                <Col sm={12} lg={12} md={12} >
                    <div className={poll.pollActive === 1 ? "QPresText open" : poll.published === 1 ? "QPresText published" : "QPresText closed"} title={poll.pollDescription}>
                        {poll.pollDescription}
                    </div>
                    {props.presenter || <div className="quickPollButtons">
                        {poll.pollActive === 1 || <button className="quickPollPlayButton" onClick={() => controlPollCard(btnPlay)}><FontAwesomeIcon icon={faPlay} /></button>}
                        {poll.pollActive === 0 || <button className="quickPollPauseButton" onClick={() => controlPollCard(btnPause)}><FontAwesomeIcon icon={faPause} /></button>}
                        <button className="quickPollPublishButton" disabled={!(totalPollCounts > 0 || poll.finalResults === 1)} onClick={() => controlPollCard(btnPublish)}><FontAwesomeIcon icon={faChartPie} /></button>
                        <button disabled={poll.pollActive === 1 ? true : false} className="quickPollResetButton" onClick={() => swal({ title: "Are you sure?", text: "Once reset, you will not be able to recover this poll's results", icon: "warning", buttons: true, dangerMode: true }).then((value) => { if (value) controlPollCard(btnReset) })}>
                            <FontAwesomeIcon icon={faSync} /></button>

                        <button disabled={poll.pollActive === 1 ? true : false} className="quickPollDeleteButton" onClick={() => swal({ title: "Are you sure?", text: "Once deleted, you will not be able to recover this poll", icon: "warning", buttons: true, dangerMode: true }).then((value) => { if (value) controlPollCard(btnDelete) })}>
                            <FontAwesomeIcon icon={faTrash} /></button>
                    </div>}
                </Col>
                {trendChartVisible || <Col sm={4} lg={4} md={4}>
                    <div className="quickPollCardResults" >
                        <table className="compactTable" key={'header' + poll.pollId}>
                            <thead>
                                <tr><th>Option</th><th>Result</th></tr>
                            </thead>
                            <tbody>
                                {options.map((i) => poll['option' + i] !== null ? <tr key={poll.pollId + '_' + i}><td>{poll['option' + i]}</td><td className="bar">{pollResults[i - 1] + ' (' + pollPercentages[i - 1] + '%)'}</td></tr> : null)}
                            </tbody>
                        </table>
                    </div>
                </Col>}
                <Col>
                    {trendChartVisible ?
                        <div className="quickPollCardCharts">
                            {chartData.datasets && chartData.datasets.length > 0 ? <div className={"quickPollCardChartWide"}>
                                <Line
                                    width={600}
                                    height={340}
                                    data={chartData}
                                    options={chartOptions}
                                />
                            </div> : null}
                            <button className="pollTrendToggle" onClick={() => toggleTrend()}>
                                S I M P L E
                            </button>
                        </div>
                        :
                        <div className="quickPollCardGraphs">
                            {weHaveData ? <div className={poll.defaultChart === 1 ? "quickPollCardChart" :
                                !props.presenter ? "quickPollCardChart quickPollCardGraphsMinWidth" : "quickPollCardChart quickPollCardGraphsPresenterMinWidth"}>
                                {poll.defaultChart == 1 ? <Doughnut

                                    height={225}
                                    data={smallChartData}
                                    options={smallChartOptions}
                                /> : null}

                                {poll.defaultChart == 2 ? <HorizontalBar

                                    height={200}
                                    data={smallChartData}
                                    options={smallChartOptions}
                                /> : null}

                                {poll.defaultChart == 3 ? <Bar
                                    height={200}
                                    data={smallChartData}
                                    options={smallChartOptions}
                                /> : null}
                            </div> : null}
                            <button className="pollTrendToggle" onClick={() => toggleTrend()}>
                                T R E N D
                            </button>
                        </div>
                    }

                </Col>
            </Row>
        </Col>
    )
}
