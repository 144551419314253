import React, { useContext, useState, useEffect } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import AddNewUser from './AddNewUser';
import AddPermissions from './AddPermissions';
import UserPermissionContext from './UserPermissionContext';
import AdminContext from '../../components/context/AdminContext';
import { NotificationManager } from 'react-notifications';
import { UserTypes } from './UserDetails/UserAdminTypes';


const UserAdministration = () => {

    //Component state//
    const [userClients, setUserClients] = useState([]);
    const [selectedClient, setClient] = useState({name:'',value:0});
    const [userMeetings, setUserMeetings] = useState([]);
    const [addPermission, setAddPermission] = useState(false);
    const [addUser, setAddNewUser] = useState(false);
    const [userList, setUserList] = useState([]);
    const [selectedMeeting, setMeeting] = useState({ name: '', value: 0 });
    const [isLoading, setIsloading] = useState(false)
    const [restrictedMeeting, setRestrictedMeeting] = useState(false);

    //Contexts//
    const Context = useContext(UserPermissionContext);
    const adminContext = useContext(AdminContext);
    const { userAdminPermissions, clients, userList :users} = Context;

    useEffect(() => {
        if (isNaN(adminContext.meetingId)) {
            meetingUsers();
        }
        getUserMeeting();
        Context.getSelfPermissions(0);
        Context.getUserAccessibleMeetings();
    }, []);

    //Component functions//

    /**
     * Get logged in user's meeting based on level at they have access.
     * */
    const getUserMeeting = async () => {
        const meetings = await Context.getUserClients();
        //Check if meeting selected while login is accessible to user//
        const { meetingId } = adminContext;
        if (meetings) {
            //console.log(meetings)
            let sortedList = meetings.sort((a, b) => { return a.ClientName > b.ClientName ? 1 : -1 })
            setUserClients(sortedList);
            let client = meetings.find((client) => {
                return client.Meetings.some((meeting) => {
                    return meeting.VMeetingID === meetingId
                });

            });
            if (client?.ClientID) {
                setClient({ value : client.ClientID, name:client.ClientName });
                let defaultClient = meetings.find(c => c.ClientID == client.ClientID);
                //If rank is global then no need to make an api call because app already knows
                //all the clients and client meetings
                //meetingUsers(null, e.target.value == 0 ? null : e.target.value)
                if (defaultClient?.Rank == UserTypes.GLOBAL_USER) {
                    const { Meetings } = defaultClient || [];
                    setUserMeetings(Meetings);
                    Context.setMeetings(Meetings);
                    const defaultMeeting = Meetings?.find(x => x.VMeetingID == meetingId);
                    if (defaultMeeting) {
                        setMeeting({
                            value: defaultMeeting.VMeetingID,
                            name: defaultMeeting.MeetingName
                        });
                    }
                    meetingUsers(defaultMeeting.VMeetingID, null);
                }
                else {
                    handleClientChange({ target: { value: client.ClientID } }, client.ClientName);
                }
                
            }
        }
    }


    /**
     * handle modal close event
     * */
    const handleModalClose = () => {
        setAddPermission(false);
    }

    /**
     * handle user modal 
     * */
    const handleUserModal = () => {
        setAddNewUser(false);
    }

    /**
     * Add permission modal
     * */
    const handleAddPermissionClick = async (selectedUser) => {
        setAddPermission(!addPermission);
        //Get all the templates for permissions//
        Context.getTemplates();
        Context.setUser(selectedUser);

        let userParams = {
            Id: selectedUser.Id,
            PermissionLevel: '',
            ClientId: null,
            MeetingId: null
        };

        if (selectedMeeting.value == 0 && selectedClient.value == 0) {
            userParams.PermissionLevel = UserTypes.GLOBAL_USER;
        }
        if (selectedClient.value != 0) {
            userParams.PermissionLevel = UserTypes.CLIENT_USER;
            userParams.ClientId = selectedClient.value
        }
        if (selectedMeeting.value != 0) {
            userParams.PermissionLevel = UserTypes.MEETING_USER;
            userParams.MeetingId = selectedMeeting.value
        }
        await Context.GetReqestedPermissions(userParams)
        Context.setUserMeeting({ value: selectedMeeting.value == 0 ? null : selectedMeeting.value, name: selectedMeeting.name });
        Context.setSelectedClient({ value: selectedClient.value == 0 ? null : selectedClient.value, name: selectedClient.name });
        
    }

    const handleAddUserClick = async () => {
        Context.setUser("");
        setAddPermission(true);
        Context.getTemplates();
        Context.setUserClients([]);
    }


    /**
     * event handler for client selection change
     * @param {any} e
     */
    const handleClientChange = async (e, clientName = null) => {
        //Clear user list and meeting list when changing client//
        setUserList([]);
        setMeeting({ name: '', value: 0 });

        setClient({
            value: e.target.value,
            name: clientName === null ? e.target?.options[e.target?.selectedIndex].text : clientName
        });

        //Get client users//
       await  meetingUsers(null, e.target.value == 0 ? null : e.target.value);

        let client = clients.find(client => client.ClientID == e.target.value);
        //If rank is global then no need to make an api call because app already knows
        //all the clients and client meetings
        if (client?.Rank == UserTypes.GLOBAL_USER) {
            const { Meetings } = client || [];
            setUserMeetings(Meetings);
            Context.setMeetings(Meetings);
        }
        else {
            //Set Meetings and meeting users//
            const meetingsResponse = await Context.getUserMeetings(e.target.value);
            setUserMeetings(meetingsResponse);
            const { meetingId } = adminContext
            const defaultMeeting = meetingsResponse?.find(x => x.VMeetingID == meetingId);
            if (defaultMeeting) {
                setMeeting({
                    value: defaultMeeting.VMeetingID,
                    name: defaultMeeting.MeetingName
                });
                //Get meeting users//
                meetingUsers(defaultMeeting.VMeetingID, null);
                const userSelectedClient =
                {
                    value: e.target.value,
                    name: clientName === null ? e.target?.options[e.target?.selectedIndex].text : clientName
                };
                Context.setSelectedClient(userSelectedClient);
            }
        }
    }

    /**
     * event handler for meeting selection change
     * @param {any} e
     */
    const handleMeetingChange = async (e) => {
        setUserList([]);
        const selectedMeeting = {value: e.target.value,name: e.target[e.target.selectedIndex].text}
        setMeeting(selectedMeeting);
        //Get Meeting users if  valid meeting is selected.
        //If no meeting is selected, fetch client users 
        if (e.target.value != 0) {
            meetingUsers(e.target.value);
            Context.setUserMeeting(selectedMeeting);
        }
        else {
            meetingUsers(null, selectedClient.value)
        }

        let _restricted = userMeetings?.find(x => x.VMeetingID == e.target.value)?.RestrictedMeeting;
        setRestrictedMeeting(_restricted);
    }

    /**
     * Get meeting users 
     * @param {any} vMeetingId
     */
    const meetingUsers = async (vMeetingId = null, clientId = null) => {
        setIsloading(true);
        const userResponse = await Context.GetUsersByMeeting(vMeetingId, clientId);
        setUserList(userResponse);
        setIsloading(false);
        if (vMeetingId == null) {
            vMeetingId = 0;
        }
        await Context.getSelfPermissions(vMeetingId, clientId);
    }

    /**
     * Event handler for removing user's custom permissions
     * @param {any} selectedUser
     */
    const handlePermissionRemoveClick = async (selectedUser) => {
        const userParams = {
            Id: selectedUser.Id,
            meetingId: selectedMeeting.value != '0' ? selectedMeeting.value : null,
            clientId: selectedMeeting.value == '0' && selectedClient.value != '0'  ? selectedClient.value : null,
        }
        let response = await Context.DeleteMeetingPermissions(userParams);
        if (response) {
            NotificationManager.success("User permissions deleted", "Permissions", 2000);
            meetingUsers(userParams.meetingId, userParams.clientId);
        }
        else {
            NotificationManager.error("Sorry, there was a problem while deleting user permissions", "Permissions", 4000);
        }

    }

    /**
     * Event handler to adding user in the selected meeting
     * */
    const handleAddMeClick = async () => {
        const meeting = {
            VMeetingId: selectedMeeting.value
        }
        let response = await Context.AddUserMeetingPermissions(meeting);
        if (response) {
            NotificationManager.success("Meeting permissions are added.", "Permissions", 2000);
            meetingUsers(selectedMeeting.value);
        }
        else {
            NotificationManager.error("Sorry, there was a problem adding user in the meeting", "Permissions", 4000);
        }
    }

    const isAddMeDisabled = () => {
        return ((selectedMeeting.value == 0) || (users.some(user => user.Id == adminContext.userId)) || (!(userAdminPermissions.ReadAccess && userAdminPermissions.WriteAccess)) || restrictedMeeting)
    }


    //render functions//
    /**
     * Based on permission level, it will show badge for the users.
     * @param {any} permissionLevel
     */
    const renderPermissionCell = permissionLevel => {
        let badgeClass = '';
        switch (permissionLevel) {
            case 'global':
                badgeClass = 'btn-primary';
                break;
            case 'client':
                badgeClass = 'bg-primary';
                break;
            case 'meeting':
                badgeClass = 'bg-warning text-dark';
                break;
            default:
        }
        return <span className={`badge ${badgeClass}`}>{permissionLevel}</span>
    }

    const getColumns = () => {
        let columns = [
            {
                key: "ForeName",
                text: "Display name",
                align: "center",
            },
            {
                key: "EmailAddress",
                text: "Email",
                align: "center"
            },
            {
                key: "Id",
                text: "Action",
                align: "center",
                sortable: false,
                cell: row => {
                    return (
                        <div className="justify-content-center" key={row.Id + Date.now()}>
                            <button
                                type="button"
                                className="btn btn-primary me-4"
                                onClick={() => handleAddPermissionClick(row)}
                                disabled={!(userAdminPermissions.ReadAccess && userAdminPermissions.WriteAccess)}
                                title='Add Permission'
                                data-testid="AddPermission"
                            >Permission</button>
                            <button
                                type="button"
                                className="btn btn-primary d-none"
                                onClick={() => handlePermissionRemoveClick(row)}
                                disabled={!(userAdminPermissions.ReadAccess && userAdminPermissions.WriteAccess)}
                            >Remove</button>
                        </div>

                    )
                }
            },
        ];
        let returnColumns = columns.filter(value => Object.keys(value).length !== 0);
        return returnColumns;
    }

    const tableConfig = () => {
        return {
            page_size: 10,
            length_menu: [10, 20, 50],
            no_data_text: 'No users found',
            language: {
                length_menu: "Show _MENU_ result per page",
                filter: "Search users...",
                info: "Showing _START_ to _END_ of _TOTAL_ users",
                pagination: {
                    first: "First",
                    previous: <span>&#9668;</span>,
                    next: <span>&#9658;</span>,
                    last: "Last"
                }
            }
        }
    }

    /**
     * Render user accessible clients
     * */
    const renderUserClients = () => {
        if (userClients.length > 0) {
            return userClients.map((client, index) => {
                return (
                    <option key={client.ClientID} value={client.ClientID}>{client.ClientName} </option>
                )
            })
        }
        return null;
    }

    /**
     * Render user accessible meetings from selected client
     * */
    const renderUserMeetings = () => {
        if (userMeetings != undefined && userMeetings.length > 0) {
            return userMeetings.map((meeting, index) => {
                return (
                    <option key={meeting.VMeetingID} value={meeting.VMeetingID}>{meeting.MeetingName} </option>
                )
            })
        }
        return null;
    }

    //const setNewUser = (selectedUser) => {
    //    setUser(selectedUser);
    //}

    return (
        <div className="p-2">
            <h2 className="chat-heading-am">User Administration</h2> 
            <div className="container">
                <div className={`d-flex flex-row bd-highlight justify-content-between pt-2 pb-2 ${(Context.isSingleClientUser && Context.isSingleMeetingUser) ? 'd-none' : ''}`}>
                    <div className={`col-3`}>
                        <select
                            className="form-select"
                            onChange={(e) => {
                                e.persist()
                                handleClientChange(e)
                            }}
                            value={selectedClient.value}
                            data-testid='select-client'
                        >
                            <option value="0" >All Clients</option>
                            {renderUserClients()}
                        </select>
                    </div>
                    <div className={`col-3 ${(Context.isSingleMeetingUser) ? 'd-none' : ''}`}>
                        <select
                            className="form-select"
                            aria-label="Default select example"
                            value={selectedMeeting.value}
                            onChange={handleMeetingChange}
                            disabled={selectedClient.value == '0'}
                            data-testid='select-meeting'
                        >
                            <option value="0">All Meetings</option>
                            {renderUserMeetings()}
                        </select>
                    </div>
                </div>
                <div className="container-fluid border p-2 shadow-sm attendee-lists">
                    <ReactDatatable
                        className="table small table-hover"
                        records={users}
                        columns={getColumns()}
                        config={tableConfig()}
                        loading={isLoading}
                    />
                    <div className="d-flex flex-row justify-content-end">
                        <button
                            className="btn btn-sm btn-primary"
                            onClick={handleAddMeClick}
                            disabled={isAddMeDisabled()}
                            title={isAddMeDisabled() ? 'You are already in the meeting' : 'Add user in the meeting'}
                            data-testid='AddMe'
                        >
                            Add me
                        </button>
                        <div className="ms-2"></div>
                        <button
                            disabled={!(userAdminPermissions.ReadAccess && userAdminPermissions.WriteAccess)}
                            className="btn btn-sm btn-primary"
                            onClick={() => handleAddUserClick()}
                            data-testid='AddUser'
                        >
                            Add user
                        </button>
                    </div>
                    <AddPermissions
                        showModal={addPermission}
                        handleModalClose={handleModalClose}
                        selectedMeeting={selectedMeeting}
                        selectedClient={selectedClient}
                        
                    />
                    <AddNewUser
                        showUserModal={addUser}
                        handleUserModal={handleUserModal}
                        selectedMeeting={selectedMeeting}

                    />
                </div>
            </div>
        </div>

    )
}

export default UserAdministration