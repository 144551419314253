import React, { useEffect, useState, useRef } from 'react';
import { ProgressBar, Button } from "react-bootstrap";
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { Prompt } from 'react-router';
import swal from 'sweetalert';

const fileChunkSize = 10000;

export const CSVUpload = (props) => {
    const [showProgress, setShowProgress] = useState(false);
    const [counter, setCounter] = useState(1);
    const [fileToBeUpload, setFileToBeUpload] = useState({});
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0);
    const [endOfTheChunk, setEndOfTheChunk] = useState(fileChunkSize);
    const [progress, setProgress] = useState(0);
    const [chunkCount, setChunkCount] = useState(0);
    const [csvContent, setCSVContest] = useState([]);
    const inputFileRef = useRef(null);


    useEffect(() => {
        if (csvContent.length > 0 ) {
            setShowProgress(true);
            pushChunksOnServer(csvContent);
        }

        //Cleanup//
        return () => {
        };

    }, [fileToBeUpload, progress, csvContent]);


    /**
     * 
     * Select file triggger on change of file upload control*/
    const selectFile = () => {
        inputFileRef.current.click();
    }


    /**
     * File on change trigger
     * @param {any} e
     */
    const handleFileChange = async (e) => {
        resetChunkProperties();
        e.preventDefault();
        if (!e.target.files) return;
        if (e.target.files.length <= 0) return;
        const file = e.target.files[0];
        let reader = new FileReader();
        reader.onload = async function () {
            let csvData = reader.result.split("\n");
            const _totalCount =
                csvData.length % fileChunkSize == 0
                    ? csvData.length / fileChunkSize
                    : Math.floor(csvData.length / fileChunkSize) + 1
            setChunkCount(_totalCount);
            setCSVContest(csvData);

        };
        reader.readAsText(e.target.files[0]);
    }

    /**
     * Push each chunk from csv string to the server.
     * @param {any} csvContent
     */
    const pushChunksOnServer = async (csvContent) => {
        setCounter(counter + 1);
        let csvChunk = csvContent.slice(beginingOfTheChunk, endOfTheChunk);
        if (beginingOfTheChunk != 0) {
            csvChunk = [csvContent[0], ...csvChunk]
        }
        csvChunk = csvChunk.join('\n');
        console.log(`Chunks uploaded ${counter}`);
        let csvChunkBlob = new Blob([csvChunk]);
        let base64Data = await blobToBase64(csvChunkBlob);
        try {
            let VmeetingId = parseInt(sessionStorage.getItem('VMId'));
            const idata = {
                VMId: VmeetingId,
                fileName: "TestFile",
                fileDataUrl: base64Data,
                fileType: "",
                contentType: "",
                fileSize: 1,
                isLastChunk: counter === chunkCount
            };
            axios.post("VMUploadPreMeeting", idata).then(response => {
                const resdata = response;
                if (resdata.status === 200) {
                    if (counter <= chunkCount) {
                        setBeginingOfTheChunk(endOfTheChunk);
                        setEndOfTheChunk(endOfTheChunk + fileChunkSize);
                        var percentage = (counter / chunkCount) * 100;
                        setProgress(percentage);
                        if (counter === chunkCount) {
                            NotificationManager.success("", "File uploaded", 2000);
                            props.onUpload(resdata);
                        }
                    }
                    else if (counter === chunkCount) {
                       
                    }
                }
                else {

                }
            }).catch((err) => {
                console.log(err.response);
                resetChunkProperties();
                inputFileRef.current.value = ""
                props.onUpload(err.response);
            });
        } catch (err) {

        }
    }

    function blobToBase64(blob) {
        return new Promise((resolve, _) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    }

    const cancelUpload = () => {
        swal({
            title: "Are you sure you want cancel upload?",
            text: "",
            icon: "warning",
            buttons: true,
            dangerMode: true
        }).then((value) => {
            if (value) {
                console.log("Cancel confirmed")
                const response = {
                    status: 500,
                    data: {
                        message: ""
                    }
                }
                inputFileRef.current.value = "";
                setCSVContest([]);
                setFileToBeUpload({});
                setProgress(0);
                setCounter(1);
                setTimeout(() => {
                    props.onUpload(response);
                }, 200)
            }
        });

    }


    /***
     * Reset chunk state.
     * */
    const resetChunkProperties = () => {
        setFileToBeUpload({});
        setShowProgress(true)
        setProgress(0)
        setCounter(1)
        setBeginingOfTheChunk(0)
        setEndOfTheChunk(fileChunkSize)
        setCSVContest([])
    }

    const { id, isDisabled, accept } = props;
    return (
        <>
            <Prompt
                when={csvContent.length>0}
                message={(location, action) => {
                    if (action === 'POP') {
                        console.log("Backing up...")
                    }
                    return "File upload is in process. Are you sure you want to leave the page?";
                }}
                
            />
            <input id={id}
                type="file"
                ref={inputFileRef}
                onChange={(e) => handleFileChange(e)}
                accept={accept}
                disabled={isDisabled}
                style={{ display: "none" }} />

            <div className="d-flex flex-row justify-content-between">
                <Button disabled={(!props.isAuthorized) || (csvContent.length > 0)} className="btn btn-primary" onClick={() => selectFile()} >Select File</Button>
                {csvContent.length > 0 && <Button className="btn btn-primary" onClick={cancelUpload} >Cancel Upload</Button>}
            </div>

            <div className="pt-2">
                {csvContent.length > 0 && <ProgressBar animated now={progress} label={`${progress.toFixed(0)} %`} />}
            </div>

        </>
    )
}
CSVUpload.defaultProps = {
    id: "CSVUpload",
    text: "...",
    isDisabled: false,
    accept: ".pdf"
};

export default CSVUpload;