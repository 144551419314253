import React, { Component } from 'react';

export class LayoutRegistration extends Component {
    static displayName = LayoutRegistration.name;

    constructor(props) {
        super(props);
        this.state = {
            mounted: false
        };
    }

    componentDidMount() {
        this.setState({ mounted: true });
    }
    componentWillUnmount() {
        this.setState({ mounted: false });
    }

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="RegistrationLayout col-md-12">
                        <div className="d-flex justify-content-between">
                            <button className="btn btn-primary btn-sm" onClick={() => this.props.history.push('/')}>Home</button>
                            <button disabled={!this.props.isAuthorized} className="btn btn-primary btn-sm" onClick={() => this.props.resetPage()}>Reset page</button>
                        </div>
                        <div className="registrationLogo">
                            <h1 className="adminTitle">CES<span className="pinkIcon">Registration</span></h1>
                            {this.props.phase > 1 && <div className="registrationSubTitle">{this.props.meetingTitle}</div>}
                        </div>
                        {this.props.children}

                    </div>
                </div>
            </div>
        );
    }
}
