import React, { Component, Fragment, useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import swal from 'sweetalert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faFile, faLink, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import FileUpload from './../Common/FileUpload';
import './../Picker.less';
import arrayMove from 'array-move';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { Modal, Dropdown } from 'react-bootstrap';
import AdminContext from '../context/AdminContext';

const DragHandle = sortableHandle(() => <span className="dragHandle">||</span>);

const SortableContainer = sortableContainer(({ children }) => {
    return (
        <ul className="nobullets">
            {children}
        </ul>
    )
});

export default class Documents extends Component {
    static displayName = Documents.name;

    constructor(props) {
        super(props);
        this.userId = this.props.UserID;
        this.VmeetingId = this.props.VmeetingId;
        this.state = {
            loading: true,
            errors: [],
            forceUpdate: false,
            fileName: '',
            fileType: '',
            documentId: 0,
            displayOrder: 0,
            contentType: '',
            errorMessage: '',
            fileSize: 0,
            documentList: [],
            documentTitle: '',
            documentData: null,
            webLink: '',
            showModal: false,
            modalVersion: ''
        };
    }

    componentDidMount() {
        this.populateDocList();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    populateDocList = () => {
        if (parseInt(sessionStorage.getItem('VMId')) > 0) {
            axios.get("VMGetListofDocsNames", { params: { VMID: this.VmeetingId } }).then(response => {
                const resdata = response;
                if (resdata.data.length > 0) {
                    this.setState({ documentList: resdata.data });
                }
            });
        }
    }


    fileUploadOnClick = (e) => {
        this.setState({ errors: {} });
        let errors = {};
        var fileType = e.file.name.split('.');

        try {

            const idata = { VMId: this.VmeetingId, fileName: fileType[0], fileDataUrl: e.fileDataUrl, fileType: fileType[fileType.length - 1], contentType: e.file.type, fileSize: Number(e.file.size) };
            this.setState({
                documentData: idata,
                fileName: e.file.name,
                fileType: fileType[fileType.length - 1]
            })

            if (fileType.length > 2) {
                errors["VMDocumentPdferr"] = "Please remove additional full stops from filename before upload";
                this.setState({ errors: errors });
            }

        } catch (err) {
            errors["VMDocumentPdferr"] = err.response.data.message;
            this.setState({ errors: errors });
        }
    }


    ValidateDocDetails = () => {
        let errors = {};
        let formIsValid = true;
        let supportedFileType = ['pdf'];    

        if (this.state.documentTitle.trim() === "" || this.state.documentTitle === null) {
            formIsValid = false;
            errors["DocTitlerr"] = "Please enter a display name";
        }

        var dupetitle = this.state.documentList.find(a => a.docFriendlyName === this.state.documentTitle.trim() && a.documentId !== this.state.documentId)
        if (dupetitle) {
            formIsValid = false;
            errors["DocTitlerr"] = "An item with that title already exists";
        }

        var regexp = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
        if (this.state.webLink === "" && this.state.modalVersion === "link") {
            formIsValid = false;
            errors["VMDocumenterr"] = "Please enter a  URL";
        }

        if (this.state.modalVersion === "link" && this.state.webLink.length > 0 && !regexp.test(this.state.webLink)) {
            formIsValid = false;
            errors["VMDocuLinkerr"] = "Please enter a valid URL";
        }

        var dupeLink = this.state.documentList.find(a => a.websiteLink === this.state.webLink && a.documentId !== this.state.documentId)
        if (this.state.modalVersion === "link" && dupeLink && this.state.webLink.length > 0) {
            formIsValid = false;
            errors["VMDocuLinkerr"] = "An item with that URL already exists";
        }

        if (this.state.modalVersion === "link" && this.state.webLink.length > 0) {
            let url = new URL(this.state.webLink);
            if (url.pathname.split('/').pop().indexOf('.') > 0) {
                let lastTerm = url.pathname.split('/').pop();
                if (lastTerm.indexOf('.') > 0) {
                    let fileType = lastTerm.split('.').pop();
                    if (!supportedFileType.includes(fileType)) {
                        formIsValid = false;
                        errors["VMDocumenterr"] = "Please only link to web pages or pdf documents";
                    }
                    
                }
            }
            
        }


        if (this.state.modalVersion === "document") {
            var filetype = null;
            if (this.state.fileName.length > 0) {
                fileType = this.state.fileName.split(".");
                fileType = fileType[fileType.length - 1]
            }

            if (!fileType || fileType == "" || fileType === null || fileType.toLowerCase() !== "pdf") {
                formIsValid = false;
                errors["VMDocumentPdferr"] = "Please choose a pdf file";
            }
        }

        if (this.state.modalVersion === "document") {
            var fileType = this.state.fileName.split('.');
            if (fileType.length > 2) {
                formIsValid = false;
                errors["VMDocumentPdferr"] = "Please remove additional full stops from filename before upload";
            }
        }

        if (this.state.fileName.length > 150 && this.state.modalVersion === "document") {
            formIsValid = false;
            errors["VMDocumentPdferr"] = "Please shorten the file name, max 150 characters";
        }

        var dupeDoc = this.state.documentList.find(a => a.documentFileName === this.state.fileName && a.documentId !== this.state.documentId)
        if (this.state.modalVersion === "document" && dupeDoc && this.state.fileName.length > 0) {
            formIsValid = false;
            errors["VMDocumentPdferr"] = "A document with that name already exists";
        }

        this.setState({ errors: errors });
        return formIsValid;
    }

    UploadDocument = (e) => {
        //add to list
        e.preventDefault();
        this.setState({
            successMsg: '',
            documentTitle: this.state.documentTitle.trim()
        });

        if (this.ValidateDocDetails() && this.VmeetingId > 0 && this.userId > 0) {
            const formData = {
                DocumentId: this.state.documentId,
                VMId: this.VmeetingId,
                UserId: this.userId,
                DocFriendlyName: this.state.documentTitle.trim(),
                VMDocument: this.state.documentData,
                DocumentType: this.state.fileType,
                DisplayOrder: this.state.displayOrder,
                WebsiteLink: this.state.webLink,
                DocumentFileName: this.state.fileName
            }

            axios.post('VMUploadDocument', formData).then(response => {

                if (response.data.length > 0 && response.data[0].displayOrder === 1000) {
                    this.setState({ errorMessage: 'Duplicate title or please upload file name max 50 characters' });
                }
                else {
                    this.setState({ successMsg: 'Item was successfully uploaded' });
                    if (response.data && response.data.length > 0) {
                        this.setState({
                            documentList: response.data.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1),
                            fileName: '',
                            fileType: '',
                            contentType: '',
                            errorMessage: ''
                        });
                    }
                    this.closeModal();
                }
            });
        }
    }

    ModifyDocs = () => {
        //Reorder documents
        this.setState({ successMsg: '', documentAlreadyExists: '' });
        if (this.state.documentList.length > 1 && this.context.meetingId > 0 && this.context.userId > 0) {
            const idata = [];
            this.state.documentList.forEach((item, i) => {
                idata.push({
                    documentId: item.documentId,
                    DisplayOrder: (i + 1),
                    VMId: this.context.meetingId,
                    UserId: this.context.userId,
                })
            });


            axios.post("VMModifyDocuments", idata).then(response => {
                const resdata = response;
                if (resdata.data.length > 0) {
                    this.setState({ successMsg: 'Updated successfully' });
                    this.setState({
                        //documentList: response.data.sort((a,b) => a.displayOrder > b.displayOrder ? 1: -1),
                        successMsg: 'Updated successfully'
                    });
                }
            });
        }
    }

    DeleteDocument = (item) => {
        this.setState({
            successMsg: ''
        });
        //this.setState({ documentList: this.state.documentList.filter((x) => x.documentId !== documentId) })
        var deleteDocument = {
            VmeetingId: this.context.meetingId,
            UserId: this.context.userId,
            DocumentId: item.documentId,
            DocumentFileName: item.documentFileName ? item.documentFileName : ''
        }
        axios.post("VMDeleteDocument", deleteDocument).then(response => {
            this.setState({
                documentList: response.data.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1),
                 successMsg: 'Item was successfully removed' 
            });
        }).catch((error) => {
            console.log('error deleting document');
        })
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        this.setState(({
            documentList: arrayMove(this.state.documentList, oldIndex, newIndex),
        }));
        this.ModifyDocs();
    };

    openNewModal = (version) => {
        this.setState({
            showModal: true,
            modalVersion: version,
            modalStatus: "new"
        })
    }

    openEditModal = (item) => {
        var version
        if (item.websiteLink) {
            version = 'link';
        } else {
            version = 'document';
        }

        this.setState({
            fileName: item.documentFileName,
            webLink: item.websiteLink,
            documentTitle: item.docFriendlyName,
            documentId: item.documentId,
            displayOrder: item.displayOrder,
            showModal: true,
            modalVersion: version,
            modalStatus: "edit"
        })
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            modalVersion: null,
            documentTitle: '',
            webLink: '',
            fileName: '',
            fileType: '',
            documentId: 0,
            displayOrder: 0,
            documentData: null,
            modalStatus: null,
            errors: {}
        })
    }

    setDocumentTitle = (value) => {
        this.setState({
            documentTitle: value
        })
    }

    setWebLink = (value) => {
        this.setState({
            webLink: value
        })
    }


    render() {
        const { isAuthorized } = this.context;
        return (
            <div>
                <div className="row pt-3">
                    <div name="ListItems" className='DocumentList'>
                        <div className="d-flex align-items-center mb-3">
                            <div className="me-3">
                                <h5 className="mb-0">Document List</h5>
                            </div>
                            <div>
                                <Dropdown>
                                    <Dropdown.Toggle variant="primary" id="dropdown-basic">
                                        Add new...
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item role="button" onClick={() => this.openNewModal('document')}>Document</Dropdown.Item>
                                        <Dropdown.Item role="button" onClick={() => this.openNewModal('link')}>Link</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <AddDocumentModal
                            showModal={this.state.showModal}
                            closeModal={this.closeModal}
                            modalVersion={this.state.modalVersion}
                            modalStatus={this.state.modalStatus}
                            errors={this.state.errors}
                            UploadDocument={this.UploadDocument}
                            documentTitle={this.state.documentTitle}
                            setDocumentTitle={this.setDocumentTitle}
                            webLink={this.state.webLink}
                            setWebLink={this.setWebLink}
                            fileUploadOnClick={this.fileUploadOnClick}
                            fileName={this.state.fileName}
                            isAuthorized={isAuthorized('Documents', 'Write')}
                        />
                        <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                            {
                                this.state.documentList && this.state.documentList.map((item, i) => {
                                    const SortableItem = sortableElement((x) =>
                                        <li>
                                            <div key={item.displayOrder + '_' + i} className="d-flex justify-content-between align-items-center mb-2">
                                                <DragHandle />
                                                <div className="flex-grow-1 mx-3">
                                                    {item.websiteLink ?
                                                        <FontAwesomeIcon icon={faLink} />
                                                        :
                                                        <FontAwesomeIcon icon={faFile} />
                                                    }
                                                    &nbsp;{item.docFriendlyName}
                                                </div>
                                                <div className="flex-grow-0 flex-shrink-0">
                                                    <button
                                                        className="btn btn-primary editBtnE" id={"m" + item.vmId}
                                                        disabled={!isAuthorized('Documents', 'Write')}
                                                        onClick={() => this.openEditModal(item)}   >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </button>
                                                    <button
                                                        className="btn btn-primary editBtnD"
                                                        disabled={!isAuthorized('Documents', 'Write')}
                                                        id={item.displayOrder}
                                                        onClick={() => swal({
                                                            title: "Are you sure you want to delete this item?",
                                                            text: "This change will be reflected immediately",
                                                            icon: "warning",
                                                            buttons: true,
                                                            dangerMode: true
                                                        }).then((value) => { if (value) this.DeleteDocument(item) })} >
                                                        <FontAwesomeIcon icon={faTrashAlt} />
                                                    </button>
                                                </div>
                                            </div>
                                        </li>
                                    );

                                    return (<SortableItem key={'item-' + item.displayOrder + '-' + i} index={i} value={item} />)
                                })
                            }
                        </SortableContainer>
                    </div>

                    <div className="d-flex justify-content-between my-3">
                        <div>
                            <div className="sucessMsg hMargin">{this.state.successMsg}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Documents.contextType = AdminContext;

function AddDocumentModal(props) {
    const handleClose = props.closeModal;

    return (
        <Fragment>
            <Modal show={props.showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.modalStatus === "new" ? "Add new " : "Edit "}{props.modalVersion === "link" ? "link" : "document"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div name="upload" className="m-3">
                        <label htmlFor="DocTitle"><h5>{props.modalVersion === "link" ? "Link" : "Document"} display name</h5></label>
                        <input className="form-control" type="text" name="txtDocTitle" id="DocTitle" maxLength="150" value={props.documentTitle} onChange={(e) => props.setDocumentTitle(e.target.value)} />
                        <div className="errorMsg">{props.errors.DocTitlerr}</div>
                        <div className="errorMsg">{props.errorMessage}</div>
                        {props.modalVersion === "document" &&
                            <Fragment>
                                <h5>Upload Document </h5>
                                <div id="divFileUpload" className="fileNameText" >
                                    <FileUpload
                                        id="pdfFileUpload"
                                        className="fileNameText"
                                        style={{ height: '100px', padding: '10px', cursor: 'pointer' }}
                                        text="Upload Document"
                                        accept=".pdf"
                                        onUpload={(event) => { props.fileUploadOnClick(event) }}
                                        isDisabled={false}
                                    />
                                    <span className="ms-1">{props.fileName ? props.fileName : null}</span>
                                </div>
                                <div className="errorMsg">{props.errors.VMDocumentPdferr}</div>
                            </Fragment>
                        }
                        {props.modalVersion === "link" &&
                            <Fragment>
                                <label htmlFor="txtWebsiteLink"><h5>URL (with https://)</h5></label>
                                <input type="text" className="form-control" name="WebsiteLink" id="txtWebsiteLink" value={props.webLink} onChange={(e) => props.setWebLink(e.target.value)} />
                            </Fragment>
                        }
                        <div className="errorMsg">{props.errors.VMDocumenterr}</div>
                        <div className="errorMsg">{props.errors.VMDocuLinkerr}</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-secondary" onClick={handleClose}>
                        Close
                    </button>
                    <button
                        className="btn btn-primary"
                        disabled={!props.isAuthorized}
                        onClick={(e) => props.UploadDocument(e)}>
                        Save Changes
                    </button>
                </Modal.Footer>
            </Modal >
        </Fragment >
    );
}
