import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, GeneralHtmlSupport, Heading, Style, Font, HorizontalLine, Bold, Essentials, Italic, Paragraph, Undo, Strikethrough, Subscript, Superscript, Underline, AutoLink, Link, List, Table, TableToolbar, SourceEditing } from 'ckeditor5';
import { Image, ImageInsert } from 'ckeditor5';
import 'ckeditor5/ckeditor5.css';

class TextEditor extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            html: props.value
        }

        this._handleChange = this._handleChange.bind(this);
    }

    _handleChange(e, editor) {

        const { maxLength, onChange } = this.props;

        let html = editor.getData();

        if (html.length > maxLength) {

            html = html.substring(0, maxLength);
        }

        onChange({ html: html });

        this.setState({
            html: html
        });
    }

    render() {

        const { html } = this.state;
        const { disabled } = this.props;
        return (

            <CKEditor
                data={html}
                editor={ClassicEditor}
                readOnly={disabled}
                onChange={this._handleChange}
                config={{                    
                    plugins: [
                        GeneralHtmlSupport, Heading, Bold, Style, Font, HorizontalLine, Essentials, Italic, Paragraph, Undo, Strikethrough, Subscript, Superscript, Underline, AutoLink, Link, List, Table, TableToolbar, SourceEditing
                        , Image, ImageInsert
                    ],
                    htmlSupport: {
                        allow: [
                            {
                                name: 'div',
                                styles: true
                            },
                            {
                                classes: true,
                                styles: true
                            }
                        ],
                        disallow: ["script, iframe, audio"]
                    },
                    toolbar: {
                        items: ['sourceEditing', '|', 'heading', 'bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', '|', 'fontSize', 'fontFamily', 'fontColor', 'fontBackgroundColor','|',
                            'link', '|', 'horizontalLine', '|', 'insertTable', 'bulletedList', 'numberedList', '|', 'Undo', 'Redo', '|', 'insertImage']
                    },
                    image: {
                        toolbar: [
                            'imageStyle:block',
                            'imageStyle:side',
                            '|',
                            'toggleImageCaption',
                            'imageTextAlternative',
                            '|',
                            'linkImage'
                        ],
                        insert: {
                            // If this setting is omitted, the editor defaults to 'block'.
                            // See explanation below.
                            type: 'auto'
                        }
                    },
                    allowedContent: true,
                    removeButtons: '',
                    forcePasteAsPlainText: true,
   
                    link: {
                        decorators: {
                            addTargetToExternalLinks: {
                                mode: 'automatic',
                                callback: url => /^(https?:)?\/\//.test(url),
                                attributes: {
                                    target: '_blank',
                                    rel: 'noopener noreferrer'
                                }
                            }
                        }
                    },
                    table: {
                        contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
                    }
                }}

            />
        );
    }
}

TextEditor.defaultProps = {
    maxLength: 3500,
    disabled: false
}

export default TextEditor;