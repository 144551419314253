import React, { Component } from 'react';
import { Row, Col, Container, FloatingLabel, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import AdminContext from '../context/AdminContext';
import { BackButton } from '../Common/BackButton';

export class Meeting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectMeetingValue: "",
            selectClientValue: "",
            selectclientname: "",
            listOfMeeting: [],
            listOfClient: [],
            loading: false,
            user: null,
            isAdminUser: false,
            isFinished: false
        };
        this.handleDropdownChange = this.handleDropdownChange.bind(this);
        this.handleClientDropdownChange = this.handleClientDropdownChange.bind(this);
    }
    async componentDidMount() {
        await this.getUserAccessibleMeetings();
        await this.isUserHasAdminAccess();
        await this.context.GetUserGlobalPermissions();
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    setMeeting = async () => {
        if (this.state.selectMeetingValue != "") {
            if (this.state.selectMeetingValue != "-1") {

                sessionStorage.removeItem('VMId'); // clear old values
                sessionStorage.setItem('VMId', this.state.selectMeetingValue); //reset VMID for selected meeting                  

                if (this.state.listOfMeeting.length) {
                    let listOfMeeting = this.state.listOfMeeting;
                    let vmItem = listOfMeeting.filter(i => i.vMeetingID == this.state.selectMeetingValue)
                    sessionStorage.removeItem('JobNo'); // clear old values. I am not sure why we need the JobNo here but we leave in for now in case in breaks stuff. We ask the guys.
                    sessionStorage.setItem('JobNo', vmItem[0].jobNo);
                    sessionStorage.setItem('ClientId', this.state.selectClientValue);
                    sessionStorage.setItem('ClientName', this.state.selectclientname);
                    this.context.populateswitchdata(); //Populate meeting settings
                    this.props.history.push("/");

                }
                await this.context.populateswitchdata(true);
                await this.context.GetUserPermissions(this.state.selectMeetingValue);
                //await this.context.GetUserAccessiblePages(this.state.selectMeetingValue)
            }
        }
    }

    setSingleMeeting = async (userMeetings) => {
        let selectMeetingValue = userMeetings[0]?.meetings[0]?.vMeetingID;
        let selectClientValue = userMeetings[0]?.clientId;
        let selectclientname = userMeetings[0]?.clientName;
        //First make sure there's a meeting on the list and we haven't gone to the page manually
        if (selectMeetingValue && selectMeetingValue > 0 && this.props.location?.pathname !== "/meeting") {

            sessionStorage.removeItem('VMId'); // clear old values
            sessionStorage.setItem('VMId', selectMeetingValue); //reset VMID for selected meeting                  

            if (this.state.listOfMeeting.length) {
                sessionStorage.setItem('ClientId', selectClientValue);
                sessionStorage.setItem('ClientName', selectclientname);
                this.context.populateswitchdata(); //Populate meeting settings
                this.props.history.push("/");

            }
            await this.context.populateswitchdata(true);
            await this.context.GetUserPermissions(this.state.selectMeetingValue);

        }

    }

    handleDropdownChange = (e) => {
        this.setState({ selectMeetingValue: e.target.value });
        e.preventDefault();
    }

    handleClientDropdownChange = (e) => {
        this.setState({ selectClientValue: e.target.value, selectclientname: e.target.options[e.target.selectedIndex].text });
        let clientMeetings = this.state.listOfClient.filter(client => client.clientId == e.target.value);
        if (clientMeetings != undefined && clientMeetings.length > 0) {
            this.setState({
                listOfMeeting: clientMeetings[0]?.meetings
            })
        }
        this.setState({ selectMeetingValue: "" });
        e.preventDefault();
    }


    render() {
        let { listOfClient, listOfMeeting, loading } = this.state;
        const { userData } = this.context;
        const NotAuthorised = () => (<Container><div> <h2>401 - Not Authorised!</h2><p>You are not authorised to view this page.</p> <p>Your account has been disabled, please speak to your client advisor for assistance.</p></div></Container>);
        if (!!userData?.LockoutEnabled) {
            return <NotAuthorised />
        }

        return (
            <div className="meetingPage">
                <Container className="h-100">
                    <div className="row d-flex justify-content-center align-items-center h-75">
                        <Col md="5" className="border border-secondary rounded">
                            <Row>
                                <Col md="12" className="d-flex justify-content-between">
                                    <h1 className="text-center hMargin greenText fs-2">Meeting Selection </h1>
                                    <div className="d-flex align-items-center">
                                        {!!this.context.meetingId && <BackButton />}
                                        {this.context.isGlobalAuthorized('MetaUserAdmin', 'Read') ? <Link className="btn btn-sm btn-warning me-1" to='/useradministration'>User Admin</Link>:null } 
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12">
                                    <div className="form-group hMargin">
                                        {(!loading) ?
                                            <FloatingLabel controlId="floatingSelect" label="Select Client">
                                                <Form.Select aria-label="Select client from list" className="form-control" onChange={this.handleClientDropdownChange}  >
                                                    <option value="-1">Click to select client</option>
                                                    {
                                                        (listOfClient && listOfClient.length) ?
                                                            listOfClient.map(cl =>
                                                                <option key={cl.clientId} value={cl.clientId}>{cl.clientName}</option>)
                                                            : null

                                                    }
                                                </Form.Select>
                                            </FloatingLabel>
                                            :
                                            <div className="dropdownHolder">
                                                <select className="form-control" disabled={loading} >
                                                    <option value="-1">Select Client</option>
                                                </select>
                                                <div className="loader"></div>
                                            </div>
                                        }
                                    </div>
                                </Col>

                                <Col md="12">
                                    <div className="form-group hMargin">
                                        <FloatingLabel controlId="floatingSelect" label="Select meeting">
                                            <Form.Select aria-label="Select meeting from list" className="form-control" onChange={this.handleDropdownChange} disabled={!this.state.selectClientValue || this.state.selectClientValue === "-1"} >
                                                <option value="-1">Click to select meeting</option>
                                                {listOfMeeting !== undefined && listOfMeeting !== null ?
                                                    listOfMeeting.map(ml =>
                                                        <option key={ml.vMeetingID} value={ml.vMeetingID}>{ml.ballotName} - {ml.vMeetingID}</option>)
                                                    : null
                                                };
                                            </Form.Select>
                                        </FloatingLabel>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md="12" className="d-flex justify-content-center my-3">
                                    <button type="button" onClick={()=>this.setMeeting()} className="btn btn-primary" disabled={!this.state.selectMeetingValue || this.state.selectMeetingValue === "-1"}>Continue</button>{' '}
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </Container>
            </div>
        )
    }



    getUserAccessibleMeetings = async () => {
        this.setState({ loading: true });
        try {
            const { data } = await axios.get("GetUserAccessibleMeetings");
            const userMeetings = JSON.parse(data?.userMeetingJson);
            if (userMeetings != undefined && userMeetings.length > 0) {
                let sorted = userMeetings.sort((a, b) => (a.clientName < b.clientName) ? -1 : ((a.clientName > b.clientName) ? 1 : 0))
                this.setState({ listOfClient: sorted });             

                if (userMeetings.length === 1 && userMeetings[0].meetings?.length === 1) {
                    //If there's only a single meeting on the list, bypass this page on load
                    this.setSingleMeeting(userMeetings);
                }
            }
            this.setState({ loading: false });
        } catch {
            this.setState({ loading: false });
        }
    }

    isUserHasAdminAccess = async () => {
        this.setState({ loading: true });
        try {
            const { data } = await axios.get("GetUserAdminAccess");
            const userMeetings = JSON.parse(data?.userMeetingJson);
            if (userMeetings != undefined && userMeetings.length > 0) {
                this.setState({
                    isAdminUser:true
                })
            }
            this.setState({ loading: false });
        } catch {
            this.setState({ loading: false });
        }
    }

}
Meeting.contextType = AdminContext;

