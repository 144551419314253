import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios';
import ReactDatatable from '@ashvin27/react-datatable';
import { Button, Modal } from 'react-bootstrap';
import CreatableSelect from 'react-select/creatable';
import { Container } from 'reactstrap';
import AdminContext from './context/AdminContext';

export default class ProxyPollCard extends Component {
    static displayName = ProxyPollCard.name;

    constructor(props) {
        super(props);
        this.VmeetingId = parseInt(sessionStorage.getItem('VMId'));
        this.userId = parseInt(sessionStorage.getItem('UserId'));
        this.jobNo = sessionStorage.getItem('JobNo');
        this.hidePresentorLabel = true;
        this.state = {
            successMsg: '',
            errors: [],
            proxyPollCard: [],
            ProxyName: '',
            todos: [],
            todosFilter: [],
            orgColumns: [],
            selectedProxy: '',
            proxyloading: false,
            totalCompleted: 0
        };
    }

    componentDidMount = () => {
        if (this.VmeetingId > 0) {
            const idata = { VMId: this.VmeetingId };
            axios.post("VMGetProxyNames", idata).then(response => {
                const list = [];
                if (response.data.length > 0) {
                    response.data.map((i) => {
                        list.push({ CESVotersID: i.cesVotersID, ProxyName: i.proxyName })
                    });
                    //setTimeout(() => this.setState({ todos: list }), 2000);
                    this.setState({ todos: list })
                }
            });
        }
    }

    componentWillUnmount() {
        // fix Warning: Can't perform a React state update on an unmounted component
        this.setState = (state, callback) => {
            return;
        };
    }

    populateResolutionData = () => {
        if (this.VmeetingId && this.state.requestFinished === true) {
            this.setState({
                votingupdate: !this.state.votingupdate,
                requestFinished: false
            });

            var anyResOpen = null;
            const response = axios.get("VMGetAllResolution", { params: { VMId: this.VmeetingId } }).then(response => {
                const data = response.data;
                if (data != null && data != "") {
                    this.setState({ resolutions: data });
                    // Add button options & icons states for each resolution 
                    data.map((resolution, index) => (
                        this.setState({
                            btnDisabled: this.state.btnDisabled.concat(this.updateButtons(resolution.contestID, resolution.status, false))
                        })
                    ));


                    var resl = data.filter(x => (x.status != 1 && x.status != 2))
                    if (resl.length > 0) {
                        this.setState({
                            resText: resl[0].resolutionText,
                            contestID: resl[0].contestID,
                            status: resl[0].status
                        });

                        if (parseInt(resl[0].status) == 4 || parseInt(resl[0].status) == 5) {
                            this.setState({ resultStatus: false });
                            this.GetCloseVoteResult(resl[0].contestID, resl[0].status);
                        }
                    }

                    anyResOpen = data.filter(x => (x.status === 3));

                    // Start timers if there is a resolution open

                    if (anyResOpen.length > 0 && this.state.resultsPolling.length === 0) {
                        this.setState({ resultsPolling: this.state.resultsPolling.concat(setInterval(() => this.GetVoteResult(), 2000)) });
                    }

                    if (anyResOpen.length === 0 && this.state.resultsPolling.length > 0) {
                        this.state.resultsPolling.map((poll) => clearInterval(poll));
                        this.setState({ resultsPolling: [] });
                    }
                    this.setState({ requestFinished: true });
                }
            }).catch(error => {
                console.log("getallresolution failed");
                this.setState({ requestFinished: true });
            });
        }
        return;
    }

    tabChange = (e) => {
        if (e == 1) {
            this.setState({
                successMsg: '',
                tabOrder: 1,
                errors: [],
                proxyPollCard: [],
                ProxyName: '',
                selectedProxy: ''
            })

            if (this.VmeetingId > 0) {
                const idata = { VMId: this.VmeetingId };
                axios.post("VMGetProxyNames", idata).then(response => {
                    const list = [];
                    if (response.data.length > 0) {
                        response.data.map((i) => {
                            list.push({ CESVotersID: i.cesVotersID, ProxyName: i.proxyName })
                        });
                        this.setState({ todos: list });
                    }
                });
            }
        }
    }

    PopulateProxyDetails = (event, CESVotersID, ProxyName) => {
        let errors = {};
        this.clearSelection();
        this.setState({
            proxyloading: true,
            errors: '',
            selectedProxy: 'proxy' + CESVotersID
        });       


        if (parseInt(CESVotersID) > 0 && this.userId > 0 && this.VmeetingId > 0) {
            const idata = { VMID: this.VmeetingId, UserID: this.userId, CESVotersId: parseInt(CESVotersID) };
            const response = axios.post("VMGetProxyVoteDetails", idata).then(response => {
                const resdata = response;
                if (resdata) {
                    var test = JSON.parse(resdata.data.proxyString);
                    if (test.length > 0) {
                        this.setState({ proxyloading: false, proxyPollCard: test });
                    }
                    this.setState({ ProxyName: ProxyName, CESVotersID: CESVotersID });
                    this.clearSelection();
                }
            })

            const responseT = axios.get("VMGetMeetingTitle", { params: { VMId: sessionStorage.getItem('VMId') } }).then(responseT => {
                const resdata = responseT;
                if (resdata) {
                    var test = resdata.data.meetingTitle;
                    if (test) {
                        this.setState({ meetingTitle: test });
                    }
                }
            })

            if (this.VmeetingId) {
                const idata = { VMID: this.VmeetingId };
                const response = axios.post("VMGetResolutionVoteOptions", idata).then(response => {
                    const resdata = response;
                    if (resdata.data.length > 0) {
                        this.setState({ ResOptions: resdata.data });
                    }
                });
            }
        } else {
            errors["proxy"] = 'Please check your login status'
            this.setState({
                errors: errors,
                proxyloading: false
            });
        }
    }

    searchHandler = (event) => {
        if (this.state.todos.length > 0) {
            let searcjQery = event.target.value.toLowerCase();
            let todosFilter = this.state.todos.filter((el) => {
                let searchValue = el.ProxyName.toLowerCase();
                return searchValue.indexOf(searcjQery) !== -1;
            })
            this.setState({
                todosFilter: todosFilter
            })
        }
    }

    getColumns = () => {
        if (this.state.proxyPollCard.length > 0) {
            var aggregate = Object.keys(this.state.proxyPollCard[0]).map(key => {
                var textstring = key;
                if (this.context.switches.zeroAsBlank) {
                    textstring = textstring.replace("Discretionary", "Blank")
                }
                var column = {
                    key: key,
                    text: textstring.replace("Weight", " Weight").replace("ResolutionText", "Contest Text").replace("DisplayOrder", "Display Order"),
                    align: "center",
                    className: "TableCell",
                    cell: record => { return record[key] ? record[key] : 0 },
                    sortable: true
                };
                return column;
            });
            return aggregate;
        }
    }

    selectedRecord = (event, record, index, text) => {
        var contestid = record.ID;
        if (contestid > 0) {
            this.setState({ [contestid]: text });
            var highlight = event.currentTarget.parentElement;
            var x = highlight.parentElement.parentElement.getElementsByClassName("optLabel");
            for (var i = 0, len = x.length; i < len; i++) {
                if (x[i].classList.contains("resSelectOption")) {
                    x[i].classList.remove('resSelectOption');
                }
            }
            highlight.classList.add('resSelectOption');
        }
    }

    ConfirmProxy = async () => {
        let errors = {};
        let formIsValid = true;
        let params = [];
        this.setState({
            errors: '',
            proxyloading: true,
            totalCompleted: 0
        });
        var masterBatchSize = 10000;
        var batchComplete = 0;
        var totalCompleted = 0;
        var resdata = 0;

        if (this.state.proxyPollCard != null && this.state.proxyPollCard.length > 0 && this.VmeetingId > 0 && this.userId > 0) {

            this.state.proxyPollCard.forEach((contest) => {
                var CESVMContestId = contest.ID;
                if (CESVMContestId > 0 && this.state[CESVMContestId] && this.state.CESVotersID > 0) {

                    params.push({ VMId: this.VmeetingId, UserId: this.userId, CESVotersID: this.state.CESVotersID, CESVMContestId: CESVMContestId, JobNo: this.jobNo, VoteValue: this.state[CESVMContestId] })
                }
                else {
                    if (errors["proxy"])
                        errors["proxy"] = errors["proxy"] + " , " + contest.ResolutionText;
                    else
                        errors["proxy"] = "Please select following questions voting options : " + contest.ResolutionText;
                }
            })

            if (errors["proxy"] != null && errors["proxy"].length > 0) {
                formIsValid = false;
                this.setState({
                    errors: errors,
                    proxyloading: false
                });
            }
            else {
                for (let phase = 1; phase < 3; phase++) {
                    do {
                        
                        let VMBatchProxyVoteParams = {
                            BatchSize: masterBatchSize,
                            Phase: phase,
                            VoteData: params
                        }
                        var response = await axios.post("VMConfirmProxyVote", VMBatchProxyVoteParams).then(response => {
                            resdata = response.data.statusID;
                        }).catch(error => {
                            console.log("Confirm proxy failed");
                            errors["proxy"] = "An error occurred, please try again. If this persists contact support";
                            this.setState({
                                errors: errors,
                                proxyloading: false
                            });
                        });
                        batchComplete = resdata;
                        if (resdata > 0) {
                            
                            totalCompleted += batchComplete;
                            this.setState({ totalCompleted: totalCompleted })
                        } else if (resdata === 0) {
                            console.log('nothing to update')
                        } else if (resdata === -1) {
                            console.log('error -1')
                        }
                    } while (batchComplete === masterBatchSize);
                }

                if (resdata >= 0) {
                    this.PopulateProxyDetails(null, this.state.CESVotersID, this.state.ProxyName)
                    errors["proxy"] = "Questions submitted successfully";
                    this.setState({
                        errors: errors
                    });
                } else {
                    errors["proxy"] = "An error occurred, please try again. If this persists contact support";
                    this.setState({
                        errors: errors,
                        proxyloading: false
                    });
                }
            }
        } else {
            errors["proxy"] = 'Please check your login status'
            this.setState({
                errors: errors,
                proxyloading: false
            });
        }
    }

    ResetProxy = async () => {
        let errors = {};
        this.setState({
            errors: '',
            proxyloading: true,
            totalCompleted: 0
        });
        var masterBatchSize = 10000;
        var batchComplete = 0;
        var totalCompleted = 0;
        var resdata;
        if (parseInt(this.state.CESVotersID) > 0 && this.userId > 0 && this.VmeetingId > 0) {
            const idata = { VMID: this.VmeetingId, UserID: this.userId, CESVotersId: parseInt(this.state.CESVotersID), BatchSize: masterBatchSize };
            do {
                const response = await axios.post("VMRESETProxyVote", idata).then(response => {
                    resdata = response.data.statusID;
                }).catch(error => {
                    console.log("Reset proxy failed");
                    errors["proxy"] = "An error occurred, if this persists contact support";
                    this.setState({
                        errors: errors,
                        proxyloading: false
                    });                    
                });
                batchComplete = resdata;
                if (resdata > 0) {                    
                    totalCompleted += batchComplete;
                    this.setState({ totalCompleted: totalCompleted })

                } else if (resdata = -1) {
                    console.log('error -1')                    
                } else if (resdata = 0) {
                    console.log('nothing updated')
                }

            } while (batchComplete === masterBatchSize);

            if (resdata > 0) {
                this.PopulateProxyDetails(null, this.state.CESVotersID, this.state.ProxyName);
                errors["proxy"] = "Questions reset successfully";
                this.setState({
                    errors: errors
                });
            } else {
                console.log("Reset proxy failed");
                errors["proxy"] = "An error occurred, if this persists contact support";
                this.setState({
                    errors: errors,
                    proxyloading: false
                }); 
            }
         


        } else {
            errors["proxy"] = 'Please check your login status'
            this.setState({
                errors: errors,
                proxyloading: false
            });
        }
    }

    clearSelection = () => {
        if (this.state.proxyPollCard.length > 0) {
            this.state.proxyPollCard.forEach((res) => res.ID > 0 && this.state[res.ID] ? this.setState({ [res.ID]: null }) : null
            )
            var hasDiscretionaryToCast = this.state.proxyPollCard.filter(a => a.Discretionary > 0).length;
            var hasCastDiscretionary = this.state.proxyPollCard.filter(a => a.MeetingVoteAction).length;
            hasDiscretionaryToCast > 0 ? this.setState({ proxyConfirm: true }) : this.setState({ proxyConfirm: false })
            hasCastDiscretionary > 0 ? this.setState({ proxyReset: true }) : this.setState({ proxyReset: false })
            hasDiscretionaryToCast === 0 && hasCastDiscretionary === 0 && this.setState({ proxyReset: false, proxyConfirm: true })
            var Count = 0;

            for (const [key, value] of Object.entries(this.state.proxyPollCard[0])) {
                if (this.state.proxyPollCard[0].MeetingVoteAction) {
                    if (key.toLowerCase().trim() != "id" && key.toLowerCase().trim() != "meetingvoteaction" && key.toLowerCase().trim() != "resolutiontext" && key.toLowerCase().trim() != "discretionary" && value != null && Number.isInteger(value) && !key.toLowerCase().trim().includes("weight") && !key.toLowerCase().trim().includes("display")) {
                        Count = Count + value;
                    }

                }
                else {
                    if (key.toLowerCase().trim() != "id" && key.toLowerCase().trim() != "meetingvoteaction" && key.toLowerCase().trim() != "resolutiontext" && value != null && Number.isInteger(value) && !key.toLowerCase().trim().includes("weight") && !key.toLowerCase().trim().includes("display")) {
                        Count = Count + value;
                    }

                }
            }

            this.setState({ oBoVoterCount: Count });

            this.state.proxyPollCard.forEach((proxy) => {
                for (const [key, value] of Object.entries(proxy)) {
                    var contestID;
                    if (key.toLowerCase().trim() != "id" && key.toLowerCase().trim() != "meetingvoteaction" && key.toLowerCase().trim() != "resolutiontext" && value != null && Number.isInteger(value))
                        Count = Count + value;

                    if (!proxy.Discretionary) {
                        if (key.toLowerCase().trim() == "id")
                            contestID = value

                        if (key.toLowerCase().trim() != "id" && key.toLowerCase().trim() != "meetingvoteaction" && key.toLowerCase().trim() != "resolutiontext" && key.toLowerCase().trim() != "discretionary" && !key.toLowerCase().trim().includes('weight') && value != null && Number.isInteger(value))
                            this.setState({ [contestID]: key })
                    }

                    if (this.context.switches.zeroAsBlank) {
                        if (key.toLowerCase().trim() == "id")
                            contestID = value

                        if (key.toLowerCase().trim() === "discretionary" && value != null && Number.isInteger(value))
                            this.setState({ [contestID]: "0" })
                    }
                }
            });
        }
    }

    //modal pop up 
    handleClose = () => {
        this.setState({ Show: false })
    }

    handleShow = (ProxyName, CESVotersID) => {
        this.setState({ errors: '' });
        if (parseInt(CESVotersID) > 0 && this.userId > 0 && this.VmeetingId > 0) {
            const idata = { JobNo: this.jobNo, VMId: this.VmeetingId, CESVotersId: CESVotersID };
            const responseT = axios.post("VMGetSCCodes", idata).then(responseT => {
                const resdata = responseT;
                if (resdata) {
                    if (resdata.data) {
                        this.setState({ SC1: resdata.data.sC1, SC2: resdata.data.sC2 });
                        this.setState({ Show: true, ModalProxyName: ProxyName, CESVotersID: CESVotersID });
                    }
                }
            })

            const idatai = { JobNo: this.jobNo, VMId: this.VmeetingId, CESVotersId: CESVotersID };
            const response = axios.post("VMGetOBOVoters", idatai).then(response => {
                const resdata = response;
                if (resdata) {
                    if (resdata.data) {
                        this.setState({ listOboVoters: resdata.data });
                    }
                }
            })

            const idataall = { JobNo: this.jobNo, VMId: this.VmeetingId, CESVotersId: CESVotersID };
            const responseAll = axios.post("VMGetAllVoters", idataall).then(responseAll => {
                const resdata = responseAll;
                if (resdata) {
                    if (resdata.data) {
                        this.setState({ AllVoters: resdata.data });
                    }

                }
            })

            if (!this.state.meetingTitle) {
                const responseT = axios.get("VMGetMeetingTitle", { params: { VMId: sessionStorage.getItem('VMId') } }).then(responseT => {
                    const resdata = responseT;
                    if (resdata) {
                        var test = resdata.data.meetingTitle;
                        if (test) {
                            this.setState({ meetingTitle: test });
                        }

                    }
                })
            }
        }
    }

    handleChange = (newValue, actionMeta) => {
        this.setState({ errors: '' });
        this.setState({ NewCESVotersId: newValue.value })
    };

    ReassignVoter = () => {
        let errors = {};
        this.setState({ errors: '' });

        if (this.state.NewCESVotersId && parseInt(this.state.NewCESVotersId) > 0) {
            if (this.state.CESVotersID && parseInt(this.state.CESVotersID) > 0 && sessionStorage.getItem('VMId') && this.VmeetingId > 0) {
                const idata = { VMId: this.VmeetingId, CESVotersId: parseInt(this.state.CESVotersID), NewCESVotersId: parseInt(this.state.NewCESVotersId) };
                axios.post("VMUpdateCESVotersId", idata).then(resdata => {
                    if (resdata) {
                        if (resdata.data) {
                            this.setState({ NewCESVotersId: resdata.data.newCESVotersId });
                            errors["proxyAssign"] = "Proxy reassigned successfully";
                            this.setState({ errors: errors });
                            this.UpdateProxyNames();
                        }
                    }
                })
            }
        }
        else {
            errors["proxyAssign"] = "Please select new cesvoter";
            this.setState({ errors: errors });
        }
    }

    UpdateProxyNames = () => {
        if (this.VmeetingId > 0 && this.jobNo) {
            const idata = { VMId: this.VmeetingId, JobNo: this.jobNo };
            const response = axios.post("VMGetProxyNames", idata).then(response => {
                const resdata = response;
                const list = [];
                if (resdata.data.length > 0) {
                    todos: resdata.data.map((i) => {
                        list.push({ CESVotersID: i.cesVotersID, ProxyName: i.proxyName })
                    });
                    this.setState({ todos: list });
                }
            });
        }
    }

    SendSCCodesEmail = () => {

        let errors = {};
        this.setState({ errors: '' });

        if (this.SCsEmail.value.trim().length > 0) {
            var pattern = new RegExp(/^((("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?)$)/i);
            if (!pattern.test(this.SCsEmail.value.trim()))
                errors["proxyAssign"] = "Please enter valid email address.";
            else {
                if (this.state.CESVotersID && parseInt(this.state.CESVotersID) > 0 && this.userId && this.userId > 0 && sessionStorage.getItem('VMId') && this.VmeetingId > 0 && this.state.SC1 && this.state.SC2) {
                    const idata = {
                        VMId: this.VmeetingId, CESVotersId: parseInt(this.state.CESVotersID), UserId: this.userId, EmailAddress: this.SCsEmail.value.trim(),
                        MeetingTitle: this.state.meetingTitle, SC1: this.state.SC1, SC2: this.state.SC2
                    };
                    const responseT = axios.post("VMAddSCsEmailAudit", idata).then(responseT => {
                        const resdata = responseT;

                        if (resdata) {
                            if (resdata.data) {
                                this.setState({ NewCESVotersId: resdata.data.newCESVotersId });
                                errors["proxyAssign"] = "Email sent successfully.";
                                this.setState({ errors: errors });
                            }
                        }
                    }).catch(error => {
                        errors["proxyAssign"] = "Some problem in email sending.";
                        this.setState({ errors: errors });
                    });
                }
            }
        }
        else {
            errors["proxyAssign"] = "Please enter email address.";
        }
        this.setState({ errors: errors });
    }

    render() {
        let proxyNames = this.state.todosFilter.length > 0 ? this.state.todosFilter : this.state.todos;

        var columns = this.getColumns();

        if (columns) {
            var actionCount = this.state.proxyPollCard ? this.state.proxyPollCard.filter(x => x.MeetingVoteAction != null && x.MeetingVoteAction != 0 && x.MeetingVoteAction.trim().length > 0) : 0;
            if (actionCount.length > 0)
                columns = columns.filter(x => x.key.toLowerCase() != 'id' && x.key.toLowerCase() != 'meetingvoteaction' && x.key.toLowerCase() != 'discretionary' && x.key.toLowerCase() != 'discretionaryweight');
            else
                columns = columns.filter(x => x.key.toLowerCase() != 'id' && x.key.toLowerCase() != 'meetingvoteaction');
            var orgColumns = columns.filter(x => x.key.toLowerCase() != 'id' && x.key.toLowerCase() != 'discretionary' && x.key.toLowerCase() != 'discretionaryweight' && x.key.toLowerCase() != 'resolutiontext' && x.key.toLowerCase() != 'meetingvoteaction');

            columns.push({
                key: "action",
                text: "",
                cell: (record, index) => {
                    var CESVMContestId = record.ID;
                    orgColumns = this.state.ResOptions ? this.state.ResOptions.filter((item) => item.contestID === record.ID) : null;
                    var option = this.state.ResOptions ? this.state.ResOptions.filter((item) => item.contestID === record.ID && item.votingValue === parseInt(record.MeetingVoteAction)).map(x => x.votingOption) : null;
                    if (record.Discretionary) {
                        var selectedValue = record.MeetingVoteAction && option ? option.toString() : this.state[CESVMContestId]
                        var btnDisable = true;
                        if (record.Discretionary && record.MeetingVoteAction)
                            btnDisable = true;
                        else if (record.Discretionary)
                            btnDisable = false;
                        else if (record.MeetingVoteAction)
                            btnDisable = true;
                    }
                    else {
                        selectedValue = "";
                        btnDisable = true;
                    }

                    return (
                        <Fragment>
                            {!this.context.switches.zeroAsBlank && (
                                orgColumns && btnDisable ?
                                    record.MeetingVoteAction && option ? option.toString() : null
                                    : orgColumns && orgColumns.map((opt) =>
                                                <label key={opt.votingValue} className={`optLabel proxyOption ${opt.votingValue == selectedValue && "resSelectOption"}`}>
                                                    <button
                                                        className="btn btn-sm makebtnRadio"
                                                        onClick={event => this.selectedRecord(event, record, index, opt.votingValue.toString())}
                                                        disabled={btnDisable || this.state.proxyloading}>
                                                        <span className="btnRadio" />
                                                        <span className="btnRadioText">
                                                            {opt.votingOption}
                                                        </span>
                                                    </button>
                                                </label>
                                            )
                            )}
                        </Fragment>
                    );
                }
            });


        }


        const config = {
            key_column: "ID", page_size: 100, show_filter: false, show_pagination: false, show_info: false
            , show_length_menu: false, button: { excel: false, print: false, extra: false }
        }

        return (
            <div >
                <Container fluid>
                    <div className="row">
                        <div className="holder col-3" >
                            <div>
                                {
                                    // let proxyNames = this.state.todosFilter.length > 0 ? this.state.todosFilter : this.state.todos
                                    proxyNames.length > 0
                                    && <div className='files-list'>
                                        <div className="search">Search: <input type="text" id="filterName" onChange={this.searchHandler} /></div>
                                        <div className="">{(this.state.todosFilter.length > 0 ? this.state.todosFilter : this.state.todos).map((file) =>
                                            <div className='container files-list-item' key={file.CESVotersID}>
                                                <div className='row files-list-item-preview adminProxyList'>
                                                    <div id={this.state.selectedProxy === ('proxy' + file.CESVotersID) ? 'selectedProxy' : 'proxy' + file.CESVotersID} className='col-9 justify-content-center align-self-center files-list-item-content-item files-list-item-content-item-1' onClick={event => this.PopulateProxyDetails(event, file.CESVotersID, file.ProxyName)}>
                                                        <Button variant="primary">{file.ProxyName}</Button>
                                                    </div>
                                                    <div className="col-3 justify-content-center align-self-center">
                                                        <Button variant="secondary" id={file.ProxyName} onClick={() => this.handleShow(file.ProxyName, file.CESVotersID)} > <FontAwesomeIcon icon={faEdit} /> </Button>

                                                    </div>
                                                </div>
                                            </div>
                                        )}</div>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className="col-9">
                            {this.state.ProxyName.length > 0 ?
                                <div>
                                    <h2>{this.state.meetingTitle}</h2>
                                    {this.context.switches.preMeetingDataType === 2 ?
                                        <div>
                                            <p>{this.state.oBoVoterCount} {this.state.oBoVoterCount === 1 ? " person has " : " people have "} appointed {this.state.ProxyName} as their proxy to vote on their behalf if they are unable to attend the meeting.</p>
                                            <p>A breakdown of how they wish {this.state.ProxyName} to cast votes on their behalf is shown below. For any discretionary votes please indicate below how {this.state.ProxyName} would like these to be cast in the meeting.</p>
                                            <p>Once you have finished, please press 'CONFIRM' </p>
                                        </div>
                                        :
                                        <div>
                                            <p>{this.state.oBoVoterCount} {this.state.oBoVoterCount === 1 ? " person has " : " people have "} voted in advance. </p>
                                            <p> To include pre-meeting votes, please press 'CONFIRM' </p>
                                        </div>
                                    }
                                </div>
                                : null
                            }
                            {this.state.proxyPollCard != null && this.state.proxyPollCard.length > 0 &&
                                <div className="DataTableContainer">
                                    < ReactDatatable config={config} records={this.state.proxyPollCard} columns={columns} />
                                </div>
                            }
                            <div className="errorMsg">{this.state.errors.proxy}</div>
                            {this.state.proxyloading ?
                                <Fragment>
                                    <p>Please do not leave or refresh this page until the process is complete</p>
                                    <div className="loaderholder"><div className="loader" /></div>
                                    {this.state.totalCompleted > 0 && <span>Updated: {this.state.totalCompleted}</span>}                                    
                                </Fragment>
                                : this.state.proxyPollCard != null && this.state.proxyPollCard.length > 0 ?
                                <div className="proxyButtons">
                                    <button disabled={!this.props.authorize} style={{ marginLeft: "auto", display: this.state.proxyReset ? "inline-block" : "none" }} className="btn btn-primary" onClick={this.ResetProxy}  >RESET</button>
                                    <button disabled={!this.props.authorize} style={{ marginLeft: "10px", display: this.state.proxyConfirm ? "inline-block" : "none" }} className="btn btn-primary" onClick={this.ConfirmProxy}  >CONFIRM</button>
                                </div>
                                : <div><p>Select an option from the list on the left</p></div>
                            }

                        </div>
                    </div>

                </Container>

                <Modal
                    show={this.state.Show}
                    onHide={this.handleClose}
                    backdrop="static"
                    keyboard={false}
                >
                    <Container fluid>
                        <div >
                            <Modal.Body>
                                <div className="container-fluid">
                                    <div className="row">
                                        <div className="popupPad">
                                            <div className="col-12">
                                                <div name="Displaysecuritycodes" >
                                                    <div className="modalProxyName">
                                                        <h3> {this.state.ModalProxyName} </h3>
                                                    </div>
                                                    <div className="form-group">
                                                        <label id="lblVoterIDpt1" > VoterID pt1</label>
                                                        <input type="text" className="form-control" disabled={true} id="lblVoterIDpt1" defaultValue={this.state.SC1} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label id="lblVoterIDpt2" >VoterID pt2</label>
                                                        <input type="text" className="form-control" disabled={true} id="lblVoterIDpt2" defaultValue={this.state.SC2} />
                                                    </div>
                                                </div>

                                                <div id="LisofAllVoters">
                                                    Re-assign proxy instructions to new person.
                                                <label id="lblReassign">
                                                        Please select new proxy below and press 'Re-assign' <br></br>
                                                        All assigned proxy instructions will be transferred to this new person.

                                                <CreatableSelect
                                                            isClearable
                                                            onChange={this.handleChange}
                                                            options={this.state.AllVoters}
                                                        />

                                                    </label>
                                                    <button
                                                        className="btn btn-primary"
                                                        id='btnReassign'
                                                        disabled={!this.props.authorize}
                                                        onClick={this.ReassignVoter} > RE-ASSIGN </button>

                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div id="LisofOBOVoters" >
                                                    Instructions from the following will be transferred:
                                                    <ul >
                                                        {this.state.listOboVoters != null && this.state.listOboVoters.length > 0 && this.state.listOboVoters.map(voter => (
                                                            <li key={voter.value} >
                                                                {voter.label}
                                                            </li>
                                                        ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>

                                <div className="form-group emailInput">
                                    <label>Email voting codes to user</label>
                                    <input className="form-control" type="text" name="txtEmail" id="txtEmail" ref={input => this.SCsEmail = input} /><br />
                                    <button
                                        id='btnSend'
                                        className="btn btn-primary"
                                        onClick={this.SendSCCodesEmail}
                                        disabled={!this.props.authorize}
                                    > Send </button>
                                    &nbsp;
                                    <Button id="btnClose" className="btn btn-primary" onClick={this.handleClose}>
                                        Close
                                    </Button>
                                </div>
                                <div className="errorMsg">{this.state.errors.proxyAssign}</div>
                            </Modal.Footer>
                        </div>
                    </Container>
                </Modal>
            </div>
        );
    }
}

ProxyPollCard.contextType = AdminContext;