import React from 'react';
import ReactDOM from 'react-dom';
import "core-js";
import { AuthLayer } from './AuthLayer';



//var msalInstance = new PublicClientApplication(configValues);


//import registerServiceWorker from './registerServiceWorker';


const rootElement = document.getElementById('root');

ReactDOM.render(
    <AuthLayer />,
    rootElement);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();

