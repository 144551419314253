import React, { useContext, useState } from 'react';
import { Fragment } from 'react';

//import useValidator from './useValidator';

import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { CandidateCard } from './CandidateCard'
import ContestContext from '../Context/ContestContext';


const SortableContainer = sortableContainer(({ children }) => {
    return (
        <ul className="VoteOptions nodots">
            {children}
        </ul>
    )
});

export function CandidateSort(props) {
    const context = useContext(ContestContext);
    const [sortable, setSortable] = useState(false)


    const onSortEnd = ({ oldIndex, newIndex }) => {
        //Drag and drop sorting
        context.UpdateCandidateSort(oldIndex, newIndex);
    };
    if (sortable) {
        return (
            <Fragment>
                <div className="d-flex justify-content-between">
                    <h4>{props.VotingFor[0].toUpperCase() + props.VotingFor.slice(1)}s</h4>
                    <button className="btn btn-sm btn-secondary" type="button" onClick={() => setSortable(!sortable)}>Enable edit</button>
                </div>
                {props.optionReferences.length > 0 &&
                    <SortableContainer onSortEnd={onSortEnd} useDragHandle>
                        {
                            props.optionReferences.map((i) => {
                                const SortableItem = sortableElement(() =>
                                    <li key={i} className="candidateList" >
                                        <CandidateCard Index={i}
                                            ShowPhoto={props.ShowPhoto}
                                            ShowStatement={props.ShowStatement}
                                            sortable={sortable}
                                            disabled={props.disabled}
                                        />
                                    </li>
                                )
                                return (<SortableItem key={i} index={i} />)
                            })
                        }
                    </SortableContainer>
                }
            </Fragment >
        );
    } else {
        return (
            <Fragment>
                <div className="d-flex justify-content-between">
                    <h4>{props.VotingFor[0].toUpperCase() + props.VotingFor.slice(1)}s</h4>
                    {!props.disabled &&
                        <button className="btn btn-sm btn-secondary" type="button" onClick={() => setSortable(!sortable)}>Enable sort</button>
                    }
                </div>
                {
                    props.optionReferences.map((i) => {
                        return (
                            <li key={i} className="candidateList" >
                                <CandidateCard Index={i}
                                    ShowPhoto={props.ShowPhoto}
                                    ShowStatement={props.ShowStatement}
                                    sortable={sortable}
                                    disabled={props.disabled}
                                />
                            </li>
                        )
                    })
                }
            </Fragment>
        );
    }
}

